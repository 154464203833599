import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Row, Col, UncontrolledAlert} from "reactstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMenusOptions } from "../../store/menu/actions";
import { useHistory } from "react-router-dom";
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
// import { useHistory } from "react-router-dom";
// Import components
import MegaMenu from "../MegaMenu/MegaMenu";
import logoSm from "../../assets/images/logo-sm.svg";
import logoDark from "../../assets/images/logo-dark1.svg";
import logoLight from "../../assets/images/logo-dark1.svg";
// import Select from "react-select";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import io from "socket.io-client";
import { getAllNotifications } from "../../store/actions";
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  getAllModules,
} from "../../store/actions";

const Header = (props) => {
  const [search, setsearch] = useState(false);
  const [askForToken, setAskForToken] = useState('none');
  const [socialDrp, setsocialDrp] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [modules, setModules] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const dispatch = useDispatch();
  const history = useHistory();
  const { privilegeModules } = useSelector((state) => state.Privilages);
  const { notifications } = useSelector((state) => state.notifications);
  const [notificationData, setNotificationData] = useState(false);
  // const sockets = io(API_URL, {
  //   reconnect: false,
  //   transports: ["websocket"],
  //   allowUpgrades: false,
  //   pingTimeout: 180000,
  //   pingInterval: 25000,
  // });

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      checkCurrentToken();
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      dispatch(getAllModules(user_obj?.privilage));
    } 

    // dispatch(getAllNotifications());
    // sockets.on("notification", (msg) => {
    //   if(msg)
    //   {
    //      setNotificationData(msg)
    //   }
    // });
  }, []);

  useEffect(() => {
    if (privilegeModules && privilegeModules.length > 0) {
      let data = privilegeModules[0]?.alloted_modules;
      setModules(data);
    }

  }, [privilegeModules]);
  const checkCurrentToken = () => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    axios
      .post(`${API_URL}user/get_current_token`, {user_id:user_obj._id}, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var tken = res.data.data.browserToken;
        if(tken!=localStorage.getItem("browserToken"))
        {
          localStorage.setItem("mismatchBrowserToken", true);
          setAskForToken('block');
        }
        else
        {
          localStorage.setItem("mismatchBrowserToken", false);
          setAskForToken('none');
        }
      });
      
  };

  const closeNotify = () => {
    setAskForToken('none');
  };
  const enableNotify = () => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    axios
      .post(`${API_URL}user/enable_token`, {user_id:user_obj._id,browser_token:localStorage.getItem("browserToken")}, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        user_obj.browserToken=localStorage.getItem("browserToken");
        localStorage.setItem("authUser",JSON.stringify(user_obj));
        setAskForToken('none');
      });
  };




  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    if (!isMobile) {
      var body = document.body;
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }

  function toggleMegaMenu() {
    setShowMegaMenu(!showMegaMenu);
  }
  function handleModule(id, redirect_url, div_id) {
    localStorage.setItem("defaultModule", id);
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      dispatch(getMenusOptions(user_obj._id, id));
      history.push(redirect_url);
      $('.module_box').attr("style", "background-color:none");
      $("#" + div_id).attr("style", "background-color:#dddced");
    }


  }
  return (
    <React.Fragment>
      {showMegaMenu ? <MegaMenu /> : null}
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="20" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="20" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>


            {/* <Form className="app-search d-none d-lg-block">
              <div className="position-relative" style={{ paddingLeft: "8px" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="uil-search"></span>
              </div>
            </Form> */}
            <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert" style={{ width:"480px", margin:"14px", display:askForToken}}>
            <i class="uil-bell"></i> <strong style={{ cursor:"pointer"}} onClick={() => enableNotify()}>Click here </strong>to enable desktop notifications for Kial Social
            
            </UncontrolledAlert>
           
          </div>

          <div className="d-flex">
          
            <Dropdown
              className="d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search);
              }}
              type="button"
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                tag="button"
              >
                {" "}
                <i className="uil-search" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              {modules && modules.length > 0 && (
                <DropdownToggle
                  className="btn header-item noti-icon waves-effect"
                  tag="button"
                >
                  <i className="uil-apps"></i>
                </DropdownToggle>
              )}

              <DropdownMenu
                className="dropdown-menu-lg dropdown-menu-end"
                right
              >
                <div className="px-lg-2">
                  <Row className="g-0">
                    {modules &&
                      modules.map((el, index) => {
                        let color = "";
                        if (localStorage.getItem("defaultModule") == el._id) {
                          color = "#dddced";
                        }
                        return (
                          <Col md="4"
                            id={"module" + index}
                            className="module_box"
                            style={{ backgroundColor: color }}
                          >
                            <Link className="dropdown-icon-item" to="#" onClick={() => handleModule(el._id, el.redirect_url, "module" + index)}>
                              <img src={API_URL + "uploads/module_images/" + el.icon} alt="" />
                              <span>{el?.moduleName}</span>
                            </Link>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="uil-minus-path"></i>
              </button>
            </Dropdown>

            <NotificationDropdown new={notificationData} data={notifications} />


            <ProfileMenu />
            <Link to="/logout">
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i
                  className="fas fa-power-off"
                  style={{
                    fontSize: "1.2em",
                  }}
                ></i>
              </button>
            </Link>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
