import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Bar } from "react-chartjs-2";



function BrandOriginalPosts() {


    // date
    const formatDate = (date, fullMonth = false) => {
        const options = fullMonth
            ? { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
            : { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        return new Date(date).toLocaleString('en-US', options);
    };


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showFullMonth, setShowFullMonth] = useState(false);

    const toggleShowFullMonth = () => {
        setShowFullMonth(!showFullMonth);
    };


    const data = {
        labels: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
        datasets: [
            {
                label: 'Twitter',
                data: [81, 83, 190, 111, 111, 72],
                backgroundColor: '#e83e8c'
            },
            {
                label: 'Facebook',
                data: [71, 64, 172, 108, 110, 115],
                backgroundColor: '#6f42c1'
            },
            {
                label: 'Youtube',
                data: [1, 1, 1, 1, 3, 12],
                backgroundColor: '#0a3622'
            },
            {
                label: 'Instagram',
                data: [1, 1, 1, 1, 27, 56],
                backgroundColor: '#5b73e8'
            },
            {
                label: 'LinkedIn',
                data: [1, 1, 1, 59, 66, 35],
                backgroundColor: '#e91e63'
            },
        ]
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true, // Start the y-axis at -500
                        max: 200,
                        stepSize: 50, // Set the interval between y-axis ticks                    
                    },
                },
            ],
        },
    };


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div>
                        <p className="titletit">Monthly Distribution By Source Report</p>
                        <h6 className="dateText" onClick={toggleShowFullMonth}>
                            {formatDate(startDate, showFullMonth)} - {formatDate(endDate, showFullMonth)}

                        </h6>
                    </div>


                    <Row>
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Brand Posts Platform wise (Current selected brand only)</h5>
                                    <Bar width={474} height={200} data={data} options={options} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )



}

export default BrandOriginalPosts;