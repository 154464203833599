import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Collapse,
} from "reactstrap";
import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment";
import toastr from "toastr";
import axios from "axios";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./NewResponse.scss";
import { Stack } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

import { withRouter, useHistory } from "react-router-dom";
import { getDate } from "../../../helpers/globalFunctions";

const NewResponse = (props) => {
  const [newResponseObject, setnewResponseObject] = useState({
    response_date: getDate(new Date()),
  });
  const [newResponseIdTobeUpdated, setnewResponseIdToBeUpdated] =
    useState(null);
  const [newResponseIdToBeDeleted, setnewResponseIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [newResponseForTable, setnewResponseForTable] = useState([]);
  const [newResponseValue, setnewResponseValue] = useState("");
  const [col3, setcol3] = useState(false);
  const [sourseList, setSourceList] = useState([]);
  const [selectedSourceList, setSelectedSourceList] = useState(null);

  const [typeList, setTypeList] = useState([]);
  const [selectedTypeList, setSelectedTypeList] = useState(null);

  const [subType, setSubType] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState(null);

  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [branch, setBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [valueName, setValueName] = useState("");

  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [selectedPriority, setSelectedPriority] = useState(null);

  const [uploadProgress, setUploadProgress] = useState();

  const [show, setShow] = useState(false);

  const {
    districts,
    addingNewResponse,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    // handleTableData();
    fetch_all_Source();
    fetch_all_TypeList();
    fetch_all_Products();
    fetch_all_Department();
    fetch_all_Branch();
  }, []);

  const dispatch = useDispatch();

  let history = useHistory();
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  function fetch_all_Source() {
    axios
      .get(`${API_URL}response/source`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var sourceList_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.source_name,
              value: el._id,
            };
          });
        setSourceList([{ options: sourceList_data }]);
      });
  }

  function fetch_all_TypeList() {
    axios
      .get(`${API_URL}response/type`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var typeList_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.type_name,
              value: el._id,
            };
          });
        setTypeList([{ options: typeList_data }]);
      });
  }

  const fetch_all_SubType = (item) => {
    axios
      .get(`${API_URL}response/sub_type?type_id=` + item.value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var subType_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setSubType([{ options: subType_data }]);
      });
  };

  function fetch_all_Products() {
    axios
      .get(`${API_URL}product/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var product_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.product_name,
              value: el._id,
            };
          });
        setProduct([{ options: product_data }]);
      });
  }

  function fetch_all_Department() {
    axios
      .get(`${API_URL}department/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var department_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.depart_Name,
              value: el._id,
            };
          });
        setDepartment([{ options: department_data }]);
      });
  }

  function fetch_all_Branch() {
    axios
      .get(`${API_URL}bom_Branch/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.branch_name,
              value: el._id,
            };
          });
        setBranch([{ options: branch_data }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "SourceLists":
        setSelectedSourceList(selected);
        setnewResponseObject({
          ...newResponseObject,
          source: selected.value,
        });
        break;
      case "typeList":
        setSelectedTypeList(selected);
        fetch_all_SubType(selected);
        setnewResponseObject({
          ...newResponseObject,
          type: selected.value,
        });
        break;
      case "subTypename":
        setSelectedSubType(selected);
        if (selected.label.toLowerCase() === "loan related") setShow(true);
        else setShow(false);

        setnewResponseObject({
          ...newResponseObject,
          sub_type: selected.value,
        });
        break;
      case "productType":
        setSelectedProduct(selected);
        setnewResponseObject({
          ...newResponseObject,
          product: selected.value,
        });
        break;
      case "prioritys":
        setSelectedPriority(selected);
        setnewResponseObject({
          ...newResponseObject,
          priority: selected.value,
        });
        break;
      case "departments":
        setSelectedDepartment(selected);
        setnewResponseObject({
          ...newResponseObject,
          send_to: selected.value,
        });
        break;
      case "branchList":
        setSelectedBranch(selected);
        setnewResponseObject({
          ...newResponseObject,
          branch: selected.value,
        });
        break;
      default:
        break;
    }
  };

  function handleChangeNewReesponse(e) {
    let name = e.target.name;
    let value = e.target.value;
    setnewResponseValue(value);
    setnewResponseObject({ ...newResponseObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (!selectedSourceList) {
      toastr.warning("Please select source");
      return;
    }
    if (newResponseIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}response`, newResponseObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("New Response updated successfully");
            setnewResponseValue("");
            formRef.current.reset();
            // handleTableData();
            setnewResponseObject({});
            setnewResponseIdToBeUpdated(null);

            setSourceList(null);
            setTypeList(null);
            setSubType(null);
            setDepartment(null);
            setProduct(null);
            setSelectedPriority(null);
          } else {
            toastr.error("Failed to update New Response");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}response`, newResponseObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("New Response added Successfully");
            // handleTableData();
            setnewResponseValue("");
            formRef.current.reset();
            setnewResponseObject({});

            setSourceList(null);
            setTypeList(null);
            setSubType(null);
            setDepartment(null);
            setProduct(null);
            setSelectedPriority(null);
          } else {
            toastr.error("Failed to add New Response");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const uploadScreenshot = (e) => {
    const fd = new FormData();
    fd.append("screenshot", e.target.files[0]);
    axios
      .post(`${API_URL}response/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setnewResponseObject({
            ...newResponseObject,
            [`screenshot`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteScreenshot = () => {
    setnewResponseObject({
      ...newResponseObject,
      [`screenshot`]: "",
    });
  };

  function handleClick() {
    setShow(true);
  }

  const reset = () => {
    formRef.current.reset();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="New Response" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">First Name</Label>

                          <AvField
                            name="firstName"
                            placeholder="First Name"
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={newResponseObject?.firstName}
                            onChange={handleChangeNewReesponse}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Email</Label>

                          <AvField
                            name="email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter Email"
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={newResponseObject?.email}
                            onChange={handleChangeNewReesponse}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Mobile No.</Label>

                          <AvField
                            name="mobile"
                            placeholder="Mobile"
                            type="number"
                            errorMessage="Enter Mobile No."
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={newResponseObject?.mobile}
                            onChange={handleChangeNewReesponse}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Source</Label>
                          <Select
                            name="SourceLists"
                            errorMessage="Select Source"
                            //validate={{ required: { value: true } }}
                            value={selectedSourceList}
                            onChange={(selected) => {
                              handleSelectChange(selected, "SourceLists");
                            }}
                            options={sourseList}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              Response Date
                            </Label>
                            <input
                              name="response_date"
                              className="form-control"
                              type="date"
                              id="income_expense_date"
                              value={newResponseObject?.response_date}
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Send To</Label>
                          <Select
                            name="departments"
                            errorMessage="Select Send To"
                            validate={{ required: { value: true } }}
                            value={selectedDepartment}
                            onChange={(selected) => {
                              handleSelectChange(selected, "departments");
                            }}
                            options={department}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Select Priority
                          </Label>
                          <Select
                            name="prioritys"
                            errorMessage="Select Priority"
                            validate={{ required: { value: true } }}
                            value={selectedPriority}
                            onChange={(selected) => {
                              handleSelectChange(selected, "prioritys");
                            }}
                            options={[
                              {
                                label: "Low",
                                value: 0,
                              },
                              {
                                label: "Medium",
                                value: 1,
                              },
                              {
                                label: "High",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Select Type
                          </Label>
                          <Select
                            name="typeList"
                            errorMessage="Select Select Type"
                            validate={{ required: { value: true } }}
                            value={selectedTypeList}
                            onChange={(selected) => {
                              handleSelectChange(selected, "typeList");
                            }}
                            options={typeList}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Select Sub Type
                          </Label>
                          <Select
                            name="subTypename"
                            errorMessage="Select Sub Select Type"
                            validate={{ required: { value: true } }}
                            value={selectedSubType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "subTypename");
                            }}
                            options={subType}
                            classNamePrefix="select2-selection"
                            onClick={() => handleClick()}
                          />
                        </div>
                      </Col>

                      {/* after click */}
                      {show ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Select Loan
                            </Label>
                            <Select
                              name="productType"
                              errorMessage="Select Loan"
                              validate={{ required: { value: true } }}
                              value={selectedProduct}
                              onChange={(selected) => {
                                handleSelectChange(selected, "productType");
                              }}
                              options={product}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}

                      {/* after click */}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Location</Label>

                          <AvField
                            name="location"
                            placeholder="Location"
                            type="text"
                            errorMessage="Enter Location"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={newResponseObject?.location}
                            onChange={handleChangeNewReesponse}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-12">
                          <Label htmlFor="validationCustom02">Comments</Label>

                          <textarea
                            style={{
                              height: "auto",
                              borderColor: "#ced4da",
                            }}
                            name="comments"
                            placeholder="comments"
                            rows={1}
                            type="text"
                            errorMessage="Enter comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={newResponseObject?.comments}
                            onChange={handleChangeNewReesponse}
                          />
                        </div>
                      </Col>
                      {/* --------------********---------------  */}
                      {/* <div className="accordion" id="accordionExample" style={{ marginTop: "10px" }}>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button"
                              onClick={() => {
                                setcol3(!col3)
                              }}
                              style={{ cursor: "pointer" }}>
                              <b>Additional Information</b></button>
                          </h2>
                          <Collapse id="collapseThree" className="accordion-collapse" isOpen={col3}>
                            <div className="accordion-body">
                              <Row>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">
                                      Customer ID
                                    </Label>

                                    <AvField
                                      name="customerId"
                                      placeholder="Customer ID"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.customerId}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">
                                      Account No.
                                    </Label>

                                    <AvField
                                      name="accountNo"
                                      placeholder="Account No."
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.accountNo}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>

                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">Card No.</Label>

                                    <AvField
                                      name="cardNo"
                                      placeholder="card No"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.cardNo}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">Loan No.</Label>

                                    <AvField
                                      name="loanNo"
                                      placeholder="loan No"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.loanNo}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">
                                      Facebook ID
                                    </Label>

                                    <AvField
                                      name="facebook_id"
                                      placeholder="facebook Id"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.facebook_id}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">Twitter ID</Label>

                                    <AvField
                                      name="twitter_id"
                                      placeholder="twitter Id"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.twitter_id}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">
                                      Instagram ID
                                    </Label>

                                    <AvField
                                      name="instagram_id"
                                      placeholder="instagramId"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.instagram_id}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">
                                      Reference URL
                                    </Label>

                                    <AvField
                                      name="reference_url"
                                      placeholder="Reference url"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.reference_url}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">
                                      Reference ID (if any)
                                    </Label>

                                    <AvField
                                      name="reference_ID"
                                      placeholder="Reference ID(if any)"
                                      type="text"
                                      className="form-control"
                                      id="validationCustom02"
                                      value={newResponseObject?.reference_ID}
                                      onChange={handleChangeNewReesponse}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom02">Branch</Label>

                                    <Select
                                      name="branchList"
                                      errorMessage="Select Source"
                                      validate={{ required: { value: true } }}
                                      value={selectedBranch}
                                      onChange={(selected) => {
                                        handleSelectChange(selected, "branchList");
                                      }}
                                      options={branch}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom03">
                                      Attach Screenshot(.jpeg, .png,.bmp only)
                                    </Label>
                                    {newResponseObject?.screenshot ? (
                                      <div div className="img-wraps">
                                        {newResponseObject.screenshot &&
                                          newResponseObject.screenshot.startsWith(
                                            "http"
                                          ) ? (
                                          <img
                                            alt=""
                                            width="150"
                                            height="150"
                                            src={`${newResponseObject.screenshot}`}
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            width="150"
                                            height="150"
                                            src={`${API_URL}/uploads/twitter/${newResponseObject?.screenshot}`}
                                          />
                                        )}
                                        <button
                                          className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                          onClick={deleteScreenshot}
                                          style={{ width: "150px" }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    ) : (
                                      <AvField
                                        name="image"
                                        type="file"
                                        className="form-control"
                                        id="validationCustom03"
                                        onChange={uploadScreenshot}
                                        rows="1"
                                      />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Collapse>
                        </div>
                      </div> */}
                      {/* --------------********---------------  */}
                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          {newResponseIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingNewResponse ? true : false}
                            >
                              {addingNewResponse ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingNewResponse ? true : false}
                            >
                              {addingNewResponse ? "Adding" : "Submit"}
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="addUserTableId"
                    responsive
                    striped
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewResponse;
