import {
  GET_POPUP,
  GET_POPUP_SUCCESS,
  GET_POPUP_FAIL,
  ADD_POPUP,
  ADD_POPUP_SUCCESS,
  ADD_POPUP_FAIL,
  UPDATE_POPUP,
  UPDATE_POPUP_SUCCESS,
  UPDATE_POPUP_FAIL,
  DELETE_POPUP,
  DELETE_POPUP_SUCCESS,
  DELETE_POPUP_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  popups: [],
  addingPopup: false,
  deletingPopup: false,
  addPopupResponse: {},
  updatePopupResponse: {},
  deletePopupResponse: {},
  popupIdToBeDeleted: "",
  popup: {},
  error: {},
};

const popups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POPUP:
      return {
        ...state,
        params: action.payload,
      };

    case GET_POPUP_SUCCESS:
      return {
        ...state,
        popups: action.payload.data,
      };

    case GET_POPUP_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_POPUP:
      return {
        ...state,
        addingPopup: true,
        popup: action.payload,
      };

    case ADD_POPUP_SUCCESS:
      let newPopups = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newPopups.push(...state.popups);
      return {
        ...state,
        addingPopup: false,
        addPopupResponse: {
          type: "success",
          message: "Popup created successfully",
        },
        popups: newPopups,
        error: {},
      };

    case ADD_POPUP_FAIL:
      return {
        ...state,
        addingPopup: false,
        addPopupResponse: { type: "failure", message: "Creating popup failed" },
        error: action.payload,
      };
    case DELETE_POPUP:
      return {
        ...state,
        deletingPopup: true,
        popupIdToBeDeleted: action.payload,
      };

    case DELETE_POPUP_SUCCESS:
      let newData = state.popups.filter((e) => {
        return e.acc_chart_id != state.popupIdToBeDeleted;
      });
      return {
        ...state,
        deletingPopup: false,
        popupIdToBeDeleted: "",
        deletePopupResponse: {
          type: "success",
          message: "Popup deleted successfully",
        },
        popups: newData,
      };

    case DELETE_POPUP_FAIL:
      return {
        ...state,
        deletingPopup: false,
        deletePopupResponse: {
          type: "failure",
          message: "Deleting popup failed",
        },
        error: action.payload,
      };

    case UPDATE_POPUP:
      return {
        ...state,
        updatingChartOfAccount: true,
        popup: action.payload,
      };

    case UPDATE_POPUP_SUCCESS:
      let newPopuparr = [];
      let newPopup = state.popups.filter((popup) => {
        if (popup.acctype_id == state.popup.acctype_id) {
          newPopuparr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
        } else {
          newPopuparr.push(popup);
        }
      });

      return {
        ...state,
        updatingChartOfAccount: false,
        popup: "",
        updatingChartOfAccount: false,
        updatePopupResponse: {
          type: "success",
          message: "Popup updated successfully",
        },
        popups: newPopuparr,
      };

    case UPDATE_POPUP_FAIL:
      return {
        ...state,
        updatingPopup: false,
        updatePopupResponse: {
          type: "failure",
          message: "Updating popup failed",
        },
        error: action.payload,
      };

    default:
      return state;
  }
};

export default popups;
