import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {  MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody } from "reactstrap";
import io from "socket.io-client";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import twitterMention from "../../assets/images/twitterMention.svg";
import TwitterDirectMessege from "../../assets/images/TwitterDirectMessege.svg";
//Import Breadcrumb
import axios from "axios";
import moment from "moment";
import "./index.scss";
//Import Breadcrumb

import "./index.scss";
import {
  apiError,
} from "../../store/actions";
import accessToken from "../../helpers/jwt-token-access/accessToken";
const SrvSocial = (props) => {
  //const {token} = props.match.params;
  
  const [srvsocialForTable, setsrvsocialForTable] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
  }, []);
  useEffect(() => {
    // const sockets = io(API_URL, { transports: ["websocket"] });
    // sockets.on("receipt", (msg) => {
    //   dispatch(getAllMenstions());
    // });
    // return () => sockets.disconnect();

    const sockets = io(API_URL, {
      reconnect: false,
      transports: ["websocket"],
      allowUpgrades: false,
      pingTimeout: 180000,
      pingInterval: 25000,
    });
    sockets.on("mention", (msg) => {
      handleTableData();
    });
    return () => sockets.disconnect();

    // dispatch(getAllMenstions());
  }, []);

  function handleTableData() {
    var url = `${API_URL}twitter/getmention_web`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let srvsocialData = [];

        result.map((item, index) => {
          item.id2 = index + 1;
    
          if (item?.social_media === "Twitter" && item?.category === "Mention") {
            item.socialMedia = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={twitterMention} height="23" width="23" />
              </div>
            );
          } else if (
            item?.social_media === "Twitter" &&
            item?.category === "DM Received"
          ) {
            item.socialMedia = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={TwitterDirectMessege} height="23" width="23" />
              </div>
            );
          }
    
          item.time = moment(item.time, "HHmmss").format("hh:mm a"); // 24hours
    
          srvsocialData.push(item);
        });
        setsrvsocialForTable(srvsocialData);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Source",
        field: "socialMedia",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Ref. No.",
      //   field: "refNo",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Name",
        field: "author_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Message",
        field: "text",
        sort: "asc",
        width: 100,
      },

      {
        label: "Status",
        field: "social_status",
        sort: "asc",
        width: 100,
      },
    ],
    rows: srvsocialForTable,
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">   
        <Breadcrumbs title="Home" breadcrumbItem="Auto Listening" />     
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="srvSocialTableId"
                    responsive
                    bordered
                    data={data}
                    searching={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={20}
                    paging={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(SrvSocial));

SrvSocial.propTypes = {
  error: PropTypes.any,
  categories: PropTypes.array,
};
