import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import $ from "jquery";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./CronJobActivity.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import Select from "react-select";
import moment from "moment";

const DatatableTables = () => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [details, setDetails] = useState([]);
  const [activitytypeOptions, setActivityTypeOptions] = useState([]);
  const [selectedActivityType, setSelectedActivityType] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    fetch_all_title();
  }, []);

  const handleTableData = (from_date = "", to_date = "", cron_unq_id = "") => {
    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
    }
    var url = `${API_URL}security/cronlog?user_id=` + userid;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
      }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&title=" +
      cron_unq_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.time = moment(item.cron_time,"HHmmss").format("hh:mm a");
            if(item.cron_active_status === 0)
              item.status = "Start";
            else item.status = "Stop";  
          });
        setDetails(result);
      });
  };

  const fetch_all_title = () => {
    axios
      .get(`${API_URL}security/crontitle`, {
        headers: {
          "x-access-token": accessToken,
        },
      })

      .then((res) => {
        var title_data = res.data.data;
        let data =
          title_data &&
          title_data.map((el) => {
            return {
              label: el._id,
              value: el._id,
            };
          });
        setActivityTypeOptions([
          {
            options: data,
          },
        ]);
      });
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let cron_unq_id = searchData?.cron_unq_id ? searchData.cron_unq_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2, cron_unq_id);
  };
  const handleSelectChange = (selected) => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    setSelectedActivityType(selected);
    setSearchData({
      ...searchData,
      ["cron_unq_id"]: selected.value,
    });
    handleTableData(date1, date2, selected.value);
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedActivityType(null);
    handleTableData();
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "cron_date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },

      {
        label: "ID",
        field: "cron_unq_id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Title",
        field: "cron_title",
        sort: "asc",
        width: 100,
      },
      {
        label: "Result",
        field: "cron_result",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Cron Job Activity" />

          {/*Text field 1*/}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            min={searchData?.from_date}
                            onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Activity Type</Label>
                          <Select
                            name="cron_unq_id"
                            value={selectedActivityType}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            options={activitytypeOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4 mb-3">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="cronjobactivityId"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
