import {
  GET_ACCTYPES,
  GET_ACCTYPES_FAIL,
  GET_ACCTYPES_SUCCESS,
  ADD_ACCTYPE,
  ADD_ACCTYPE_FAIL,
  ADD_ACCTYPE_SUCCESS,
  DELETE_ACCTYPE,
  DELETE_ACCTYPE_SUCCESS,
  DELETE_ACCTYPE_FAIL,
  UPDATE_ACCTYPE,
  UPDATE_ACCTYPE_SUCCESS,
  UPDATE_ACCTYPE_FAIL,
  GET_ACCTYPE_OPTIONS_SUCCESS,
  GET_ACCTYPE_OPTIONS_FAIL
} from './actionTypes';

const INIT_STATE = {
  acctypes: [],
  addingAcctype: false,
  deletingAcctype: false,
  addAcctypeResponse: {},
  updateAcctypeResponse: {},
  deleteAcctypeResponse: {},
  acctypeIdToBeDeleted: '',
  acctype: {},
  error: {},
 acctypeOptions: [],
};

const acctypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCTYPES:
      return {
        ...state,
        params: action.payload,
      };

    case GET_ACCTYPES_SUCCESS:
      return {
        ...state,
        acctypes: action.payload.data,
      };

    case GET_ACCTYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_ACCTYPE:
      return {
        ...state,
        addingAcctype: true,
        acctype: action.payload,
      };

    case ADD_ACCTYPE_SUCCESS:
      /*let newAcctypes = state.acctypes.filter((acctype) => {
          return acctype;
         });*/
      let newAcctypes = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newAcctypes.push(...state.acctypes);
      return {
        ...state,
        addingAcctype: false,
        addAcctypeResponse: {
          type: 'success',
          message: 'AccountType added successfully',
        },
        acctypes: newAcctypes,
        error: {},
      };

    case ADD_ACCTYPE_FAIL:
      return {
        ...state,
        addingAcctype: false,
        addAcctypeResponse: { type: 'failure', message: 'Adding acctype failed' },
        error: action.payload,
      };
    case DELETE_ACCTYPE:
      return {
        ...state,
        deletingAcctype: true,
        acctypeIdToBeDeleted: action.payload,
      };

    case DELETE_ACCTYPE_SUCCESS:
      let newAcctypes2 = state.acctypes.filter((acctype) => {
        return acctype.acctype_id != state.acctypeIdToBeDeleted;
      });
      return {
        ...state,
        deletingAcctype: false,
        acctypeIdToBeDeleted: '',
        deletingAcctype: false,
        deleteAcctypeResponse: {
          type: 'success',
          message: 'AccountType deleted successfully',
        },
        acctypes: newAcctypes2,
      };

    case DELETE_ACCTYPE_FAIL:
      return {
        ...state,
        deletingAcctype: false,
        deleteAcctypeResponse: {
          type: 'failure',
          message: 'Deleting AccountType failed',
        },
        error: action.payload,
      };

    case UPDATE_ACCTYPE:
      return {
        ...state,
        updatingAcctype: true,
        acctype: action.payload,
      };

    case UPDATE_ACCTYPE_SUCCESS:
      let newacctypearr = [];
      let newAcctypes1 = state.acctypes.filter((acctype) => {
        if (acctype.acctype_id == state.acctype.acctype_id) {
          newacctypearr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
        } else {
          newacctypearr.push(acctype);
        }
      });

      return {
        ...state,
        updatingAcctype: false,
        acctype: '',
        updatingAcctype: false,
        updateAcctypeResponse: {
          type: 'success',
          message: 'AccountType updated successfully',
        },
        acctypes: newacctypearr,
      };

    case UPDATE_ACCTYPE_FAIL:
      return {
        ...state,
        updatingAcctype: false,
        updateAcctypeResponse: {
          type: 'failure',
          message: 'Updating acctype failed',
        },
        error: action.payload,
      };
      case GET_ACCTYPE_OPTIONS_SUCCESS:
        return {
          ...state,
          acctypeOptions: action.payload.data,
        };
  
      case GET_ACCTYPE_OPTIONS_FAIL:
        return {
          ...state,
          error: action.payload,
        };

    default:
      return state;
  }
};

export default acctypes;
