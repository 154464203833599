import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import "./ormReport.scss";
import { MDBDataTable } from "mdbreact";
import { Bar } from "react-chartjs-2";



function CasesReport() {


    // date
    const formatDate = (date, fullMonth = false) => {
        const options = fullMonth
            ? { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
            : { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        return new Date(date).toLocaleString('en-US', options);
    };


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showFullMonth, setShowFullMonth] = useState(false);

    const toggleShowFullMonth = () => {
        setShowFullMonth(!showFullMonth);
    };

    // bar chart
    const data1 = {
        labels: ["inprogress", "open", "closed", "pending"],
        datasets: [
            {
                label: "7 days",
                data: [0, 0, 2, 0],
                backgroundColor: "#fd3995"
            },
            {
                label: "7-14 days",
                data: [3, 0, 0, 0],
                backgroundColor: "#886ab5",
            },
            {
                label: "14-21 days",
                data: [0, 4, 0, 1],
                backgroundColor: "#6610f2"
            }
        ]
    }

    const option1 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Chart.js Bar Chart",
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 40,
                        stepSize: 10,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Days"
                    }
                }
            ]
        }
    }


    // mdbdatatable
    const data = {
        columns: [
            {
                label: "Case Id",
                field: "id",
                width: 150,
            },
            {
                label: "Status",
                field: "status",
                width: 150,
            },
            {
                label: "Post Created Date",
                field: "created_date",
                width: 150,
            },
            {
                label: "Ticket Opened Date",
                field: "opened_date",
                width: 150,
            },
            {
                label: "Closed Date",
                field: "closed_date",
                width: 150,
            },
            {
                label: "Resolution Time",
                field: "resolution_time",
                width: 150,
            },
            {
                label: "Resolution Time(In mins)",
                field: "resolution_time(in mins)",
                width: 150,
            },
            {
                label: "Open Since",
                field: "open_since",
                width: 150,
            },
            {
                label: "Open Since(In mins)",
                field: "open_since(mins)",
                width: 150,
            },
            {
                label: "Assigned To",
                field: "assigned_to",
                width: 150,
            },
            {
                label: "Source",
                field: "source",
                width: 150,
            },
            {
                label: "Screenname",
                field: "screen_name",
                width: 150,
            },
            {
                label: "Sentiment",
                field: "sentiment",
                width: 150,
            },
            {
                label: 'Chatter',
                field: 'chatter',
                width: 150,
            },
            {
                label: "Created->Open",
                field: "created_open",
                width: 150,
            },
            {
                label: "Created->Open(In mins)",
                field: "createdOpen",
                width: 150,
            },
            {
                label: "Open->Inprogress",
                field: "openInprogress",
                width: 150,
            },
            {
                label: "Open->Inprogress(In mins)",
                field: 'inProgress(in mins)',
                width: 150,
            },
            {
                label: "Inprogress->Pending",
                field: "inprogressPending",
                width: 150,
            },
            {
                label: "Inprogress->Pending(In mins)",
                field: "inProgressPending(in mins)",
                width: 150,
            },
            {
                label: "Pending->Closed",
                field: "pendingClosed",
                width: 150,
            },
            {
                label: "Pending->Closed(In mins)",
                field: "pendingClosed(in mins)",
                width: 150,
            },
            {
                label: "General",
                field: "general",
                width: 150,
            },
            {
                label: "Note 1",
                field: "note1",
                width: 150,
            },
            {
                label: "Note 2",
                field: "note2",
                width: 150,
            },
            {
                label: "Note 3",
                field: "note3",
                width: 150,
            },
        ],
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div>
                        <p className="titletit">Cases Report</p>
                        <h6 className="dateText" onClick={toggleShowFullMonth}>
                            {formatDate(startDate, showFullMonth)} - {formatDate(endDate, showFullMonth)}

                        </h6>
                    </div>

                    <Row style={{ rowGap: "20px" }}>

                        <Col md="4">
                            <Card>
                                <CardBody>
                                    <table className="casesTable">
                                        <tbody>
                                            <tr>
                                                <td className="casetd">Total Open Case</td>
                                                <td style={{ textAlign: "left" }}>0</td>
                                            </tr>
                                            <tr>
                                                <td className="casestd">Total Inprogress Case</td>
                                                <td style={{ textAlign: "left" }}>0</td>
                                            </tr>
                                            <tr>
                                                <td className="casestd">Total Pending Case</td>
                                                <td style={{ textAlign: "left" }}>0</td>
                                            </tr>
                                            <tr>
                                                <td className="casestd">Total Closed</td>
                                                <td style={{ textAlign: "left" }}>0</td>
                                            </tr>
                                            <tr>

                                            </tr>
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="8">
                            <Card>
                                <CardBody>
                                    <table className="casesTable">
                                        <tr>
                                            <td className="casetd">Avg Turn Around Time (TAT)</td>
                                            <td style={{ textAlign: "left" }}>0 mins</td>
                                        </tr>
                                        <tr>
                                            <td className="casestd">Created - Open (Avg Time)</td>
                                            <td style={{ textAlign: "left" }}>-</td>
                                        </tr>
                                        <tr>
                                            <td className="casestd">Open - Inprogress (Avg Time)</td>
                                            <td style={{ textAlign: "left" }}>-</td>
                                        </tr>
                                        <tr>
                                            <td className="casestd">Inprogress - Pending(Avg Time)</td>
                                            <td style={{ textAlign: "left" }}>-</td>
                                        </tr>
                                        <tr>
                                            <td className="casestd">Pending - Closed(Avg Time)</td>
                                            <td style={{ textAlign: "left" }}>-</td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="4">
                            <Card>
                                <CardBody>
                                    <h5>Aging Analysis</h5>
                                    <Bar width={474} height={600} data={data1} options={option1} />
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="8">
                            <Card>
                                <CardBody>
                                    <h5>No of Tickets assigned</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <h5>Actionable Chatter Report</h5>
                                    <MDBDataTable
                                        id="casesTableId"
                                        responsive
                                        bordered
                                        info={true}
                                        searching={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                        data={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )

}

export default CasesReport;