import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Paper, Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
// import Table from "react-bootstrap/Table";
// import Select from "react-select";
import { apiError } from "../../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import "./StaffDetails.scss";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
// import TurnRightIcon from "@mui/icons-material/TurnRight";
import axios from "axios";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import defaultProfile from "../../../../assets/images/person-icon.png";
import moment from "moment";

const StaffDetails = (props) => {
  const { staffid } = props.match.params;
  const [details, setDetails] = useState({});

  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => setShow(false);

  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [userActivityLog, setUserActivityLog] = useState([]);
  const [userResponseData, setUserResponseData] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (staffid) {
      handleTableData(staffid);
    }
  }, []);

  const handleTableData = (id) => {
    axios
      .get(`${API_URL}user/single?user_Reg=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        if (result?.lastName)
          result.empName = result?.firstName + " " + result.lastName;
        else result.empName = result?.firstName;

        let companyData = result?.company || {};
        let privilegeData = result?.privilage || {};
        let moduleData = result?.module || {};
        let departmentData = result?.department || {};
        let productData = result?.product || {};
        let designationData = result?.user_desig || {};

        if (privilegeData?.privilege_name)
          result.privilegeName = privilegeData?.privilege_name;
        else result.privilegeName = "";

        if (companyData?.company_name)
          result.companyName = companyData?.company_name;
        else result.companyName = "";

        if (moduleData?.moduleName) result.moduleName = moduleData?.moduleName;
        else result.moduleName = "";

        if (departmentData?.depart_Name)
          result.departmentName = departmentData?.depart_Name;
        else result.departmentName = "";

        if (productData?.product_name)
          result.productName = productData?.product_name;
        else result.productName = "";

        if (designationData?.desig_name)
          result.designationName = designationData?.desig_name;
        else result.designationName = "";

        setDetails(result);
        fetch_activity_log(result?._id);
        fetch_response();
      });
  };
  const fetch_activity_log = (id) => {
    axios
      .get(`${API_URL}security/userlog?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((el, index) => {
            el.id = index + 1;
            el.time = moment(el?.time, "hhmmss").format("hh:mm a");
          });
        setUserActivityLog(result);
      });
  };
  const fetch_response = (id) => {
    axios
      .get(`${API_URL}response/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((el, index) => {
            el.id = index + 1;
            el.time = moment(el?.time, "hhmmss").format("hh:mm a");
            el.date = moment(el?.date).format("DD-MM-YYYY");
          });
        setUserResponseData(result);
      });
  };
  const responseData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Source",
        field: "source_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reference",
        field: "Response_ID",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "firstName",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Department",
      //   field: "department",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Added",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: userResponseData,
  };
  const userActivityData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "IP",
        field: "activity_ip",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Staff",
      //   field: "activity_user",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Activity",
        field: "activity_desc",
        sort: "asc",
        width: 200,
      },
    ],
    rows: userActivityLog,
  };

  const [tab3, setTab3] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
  };

  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
    setPasswordObject({
      password: "",
      confirmPassword: "",
    });
  };
  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: userIdTobeUpdated,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}user/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            setUserIdToBeUpdated(null);
            closeModal();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };
  return (
    <>
      <React.Fragment>
        <Modal
          show={showModal}
          toggle={() => {
            closeModal();
          }}
          centered={true}
          size="md"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Confirmation</h5>
            <button
              type="button"
              onClick={() => {
                closeModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm
            className="needs-validation"
            onValidSubmit={() => {
              handleValidSubmitPassword();
            }}
          >
            <div className="modal-body">
              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">Password</Label>
                    <AvField
                      name="password"
                      placeholder="Password"
                      type="password"
                      errorMessage=" Please provide a valid password"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom05"
                      value={passwordObject.password}
                      onChange={handleChangePassword}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">Confirm Password</Label>
                    <AvField
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                      errorMessage="Please confirm the password"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom05"
                      value={passwordObject.confirmPassword}
                      onChange={handleChangePassword}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-dark"
                style={{ marginRight: "1rem" }}
                onClick={closeModal}
              >
                Close
              </button>
              <button className="btn btn-primary" type="submit">
                Confirm
              </button>
            </div>
          </AvForm>
        </Modal>

        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Home"
              breadcrumbItem={"User -" + " " + staffid}
            />
            <Row>
              <Col xl="12">
                {/* First Dialogue */}

                <div>
                  <div className="row">
                    <div className="col-xl-3">
                      <div
                        className="card"
                        style={{
                          // width: "fit-content",
                          // width: "351px",
                          height: "max-content",
                          boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        }}
                      >
                        <div className="card-body">
                          <div className="float-end dropdown">
                            <a
                              aria-haspopup="true"
                              className="text-body font-size-16 ddropdown-toggle"
                              aria-expanded="false"
                            >
                              {/* </OverlayTrigger> */}
                            </a>
                            <div
                              tabindex="-1"
                              role="menu"
                              aria-hidden="true"
                              className="dropdown-menu-end dropdown-menu"
                            >
                              <a
                                to="/"
                                tabindex="0"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                Edit
                              </a>
                              <a
                                to="/"
                                tabindex="1"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                Action
                              </a>
                              <a
                                to="/"
                                tabindex="2"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                          {/* Customer Details Tab start */}

                          {details?.user_image ? (
                            <Stack
                              direction="row"
                              spacing={2}
                              style={{ justifyContent: "center" }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={`${API_URL}uploads/user_images/${details?.user_image}`}
                                sx={{ width: 56, height: 56 }}
                                style={{
                                  width: "6rem",
                                  height: "6rem",
                                  marginBottom: "auto",
                                }}
                              />
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              spacing={2}
                              style={{ justifyContent: "center" }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={defaultProfile}
                                sx={{ width: 56, height: 56 }}
                                style={{
                                  width: "6rem",
                                  height: "6rem",
                                  marginBottom: "auto",
                                }}
                              />
                            </Stack>
                          )}
                          <i
                            className="fas fa-key"
                            style={{
                              fontSize: "1em",
                              position: "relative",
                              float: "right",
                              bottom: "83px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowModal(true);
                              setUserIdToBeUpdated(details?._id);
                            }}
                          ></i>
                          <h5
                            className="mt-3 mb-1"
                            style={{
                              textAlign: "center",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            {details?.empName}
                          </h5>
                          <div
                            className="text-muted"
                            style={{
                              textAlign: "center",
                              color: "green !important",
                              fontFamily: "IBM Plex Sans,sans-serif",
                              fontSize: "14px",
                              marginBottom: "3px",
                            }}
                          >
                            {details?.mobile}
                          </div>

                          <p
                            className="text-muted"
                            style={{
                              textAlign: "center",
                              color: "green !important",
                              fontFamily: "IBM Plex Sans,sans-serif",
                              fontSize: "15px",
                              marginBottom: "0",
                            }}
                          >
                            {details?.privilegeName}
                          </p>

                          <div
                            className="mt-1 mb-2"
                            style={{
                              textAlign: "center",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          ></div>
                          <Divider />

                          <div className="mt-2" hidden={!details?.companyName}>
                            <p className="mb-1">Company:</p>
                            <h5 className="font-size-14">
                              {details?.companyName}
                            </h5>
                          </div>
                          <div className="mt-2" hidden={!details?.email}>
                            <p className="mb-1">Email:</p>
                            <h5 className="font-size-14">{details?.email}</h5>
                          </div>
                          <div className="mt-2" hidden={!details?.moduleName}>
                            <p className="mb-1">Module:</p>
                            <h5 className="font-size-14">
                              {details?.moduleName}
                            </h5>
                          </div>

                          <div
                            className="mt-2"
                            hidden={!details?.designationName}
                          >
                            <p className="mb-1">Designation:</p>
                            <h5 className="font-size-14">
                              {details?.designationName}
                            </h5>
                          </div>
                          <div
                            className="mt-2"
                            hidden={!details?.departmentName}
                          >
                            <p className="mb-1">Department:</p>
                            <h5 className="font-size-14">
                              {details?.departmentName}
                            </h5>
                          </div>
                          <div className="mt-2" hidden={!details?.productName}>
                            <p className="mb-1">Product:</p>
                            <h5 className="font-size-14">
                              {details?.productName}
                            </h5>
                          </div>

                          <div
                            className="mt-2"
                            hidden={!details?.land_line_extension}
                          >
                            <p className="mb-1">Landline Extension:</p>
                            <h5 className="font-size-14">
                              {details?.land_line_extension}
                            </h5>
                          </div>
                          <div className="mt-2">
                            <p className="mb-1">User Type:</p>
                            {details?.app_user === 1 ? (
                              <h5 className="font-size-14">{"App"}</h5>
                            ) : (
                              <h5 className="font-size-14">{"CRM"}</h5>
                            )}
                          </div>
                          <div
                            className="mt-2"
                            hidden={details?.app_user !== 1}
                          >
                            <p className="mb-1">Language:</p>
                            <h5 className="font-size-14">
                              {details?.app_language === 1
                                ? "English"
                                : "Malayalam"}
                            </h5>
                          </div>
                          <div
                            className="mt-2"
                            hidden={
                              details?.app_user !== 1 || !details?.app_version
                            }
                          >
                            <p className="mb-1">Version:</p>
                            <h5 className="font-size-14">
                              {details?.app_version}
                            </h5>
                          </div>
                          {/* second paper */}

                          {/* Customer Details Tab end */}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                      <div
                        className="mb-0 card"
                        style={{
                          boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <TabContext value={tab3}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleChangeTab}
                                aria-label="lab API tabs example"
                              >
                                <Tab
                                  icon={
                                    <LocationCityIcon
                                      style={{ fontSize: "20px" }}
                                    />
                                  }
                                  label="Response"
                                  value="1"
                                  size="small"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                                <Tab
                                  icon={
                                    <ReceiptLongIcon
                                      style={{ fontSize: "20px" }}
                                    />
                                  }
                                  label="User Activities"
                                  value="2"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                              </TabList>
                            </Box>
                            <TabPanel value="1">
                              <Row>
                                <Col className="col-12">
                                  {/* <Card>
                                    <CardBody> */}
                                      <MDBDataTable
                                        id="responseTableID"
                                        responsive
                                        bordered
                                        data={responseData}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                      />
                                    {/* </CardBody>
                                  </Card> */}
                                </Col>
                              </Row>
                            </TabPanel>
                            <TabPanel value="2">
                              <Row>
                                <Col className="col-12">
                                  {/* <Card>
                                    <CardBody> */}
                                      <MDBDataTable
                                        id="userActivityTableID"
                                        responsive
                                       
                                        bordered
                                        data={userActivityData}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                      />
                                    {/* </CardBody>
                                  </Card> */}
                                </Col>
                              </Row>
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>

                {/* End */}
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
      {/* View Response */}

      {/* <Modal size="xl" show={show} onHide={handleClose} centered={true}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">Details</h4>
          <button
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
           
            <h5 className="modal-title mt-0">Enquiry Details</h5>
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ paddingLeft: "25px" }}>Reference :</td>
                <td style={{ textAlign: "left" }}>BM00002541</td>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Date & Time :
                </td>
                <td style={{ textAlign: "left" }}>15-05-2022 10:30</td>
                <td style={{ padding: "10px", textAlign: "left" }}>Type :</td>
                <td style={{ textAlign: "left" }}>Grievances</td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "25px" }}>Source :</td>
                <td style={{ textAlign: "left" }}>Messages</td>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Priority :
                </td>
                <td style={{ textAlign: "left" }}>High</td>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  AddedBy :
                </td>
                <td style={{ textAlign: "left" }}>
                  <i className="uil-facebook"></i>
                  Aishwarya
                </td>
              </tr>
            </table>
          
            <h5 className="modal-title mt-0">Contact Details</h5>
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ paddingLeft: "25px" }}>Name :</td>
                <td style={{ textAlign: "left" }}>Sandeep Thakur</td>
                <td style={{ padding: "10px", textAlign: "left" }}>Email :</td>
                <td style={{ textAlign: "left" }}>sandeep@gmail.com</td>
                <td style={{ padding: "10px", textAlign: "left" }}>Mobile :</td>
                <td style={{ textAlign: "left" }}>8855447799</td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "25px" }}>Facebook :</td>
                <td style={{ textAlign: "left" }}>NA</td>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Twitter :
                </td>
                <td style={{ textAlign: "left" }}>NA</td>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Instagram :
                </td>
                <td style={{ textAlign: "left" }}>NA</td>
              </tr>
            </table>
          
            <h5 className="modal-title mt-0">Bank Details</h5>
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ paddingLeft: "25px" }}>Acc. No. :</td>
                <td style={{ textAlign: "left" }}>201354879587458</td>
                <td style={{ paddingLeft: "25px" }}>Card No. :</td>
                <td style={{ textAlign: "left" }}>MAHA25417585854545</td>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Customer ID :
                </td>
                <td style={{ textAlign: "left" }}>CUSTMAHA587453454</td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "25px" }}>Loan No. :</td>
                <td style={{ textAlign: "left" }}>MAHA444484856564</td>
                <td style={{ paddingLeft: "25px" }}>Branch :</td>
                <td style={{ textAlign: "left" }}>MAHARASHTRA4645</td>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Locality :
                </td>
                <td style={{ textAlign: "left" }}>Kerala6688844</td>
              </tr>
            </table>
           
            <h5 className="modal-title mt-0">Comments</h5>
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ textAlign: "center" }}>
                  Hi 41523698745896 my debit card not connecting in phone pe My
                  number is 7485698547 Tell me my account number
                </td>
              </tr>
            </table>

           
            <h5 className="modal-title mt-0">Manage Follow-Up</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Date Time</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Source</th>
                  <th>Priority</th>
                  <th>AddedBy</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>BM5552459665</td>
                  <td>13-05-2022 11:36am</td>
                  <td>Sandeep Thakur</td>
                  <td>Grievances</td>
                  <td>
                    <i className="uil-facebook"></i>
                    Facebook
                  </td>
                  <td>High</td>
                  <td>Aishwarya</td>
                </tr>
              </tbody>
            </Table>
            <Table>
              <thead>
                <tr>
                  <th>Department</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Custom Services-</td>
                </tr>
              </tbody>
            </Table>
            
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Comments</th>
                  <th>Status</th>
                  <th>Source</th>
                  <th>Screenshot</th>
                  <th>Added By</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <TurnRightIcon />
                  </td>
                  <td>26-07-2022</td>
                  <td>02:30pm</td>
                  <td>
                    {" "}
                    Hi 41523698745896 my debit card not connecting in phone pe
                    My number is 7485698547 Tell me my account number
                  </td>
                  <td>New</td>
                  <td>
                    <i className="uil-facebook"></i>Messages
                  </td>
                  <td>-</td>
                  <td>Aishwarya</td>
                  <td>
                    <i
                      className=" uil-edit-alt"
                      style={{
                        fontSize: "1em",
                        cursor: "pointer",
                        marginLeft: "0.2em",
                        marginRight: "0.5em",
                      }}
                    ></i>
                  </td>
                </tr>
              </tbody>
            </Table>
         
            <Row>
              <Col xl="12">
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Pending</Label>
                          <Select
                            name="Pending"
                            errorMessage="Select Pending"
                            validate={{ required: { value: true } }}
                            options={[
                              {
                                label: "Services",
                                value: 0,
                              },
                              {
                                label: "Product",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Source</Label>
                          <Select
                            name="Source"
                            errorMessage="Select Source"
                            validate={{ required: { value: true } }}
                            options={[
                              {
                                label: "Services",
                                value: 0,
                              },
                              {
                                label: "Product",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Internal</Label>
                          <Select
                            name="Internal"
                            errorMessage="Select Internal"
                            validate={{ required: { value: true } }}
                            options={[
                              {
                                label: "Services",
                                value: 0,
                              },
                              {
                                label: "Product",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Screenshot</Label>

                          <AvField
                            name="image"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            rows="1"
                          />
                        </div>
                      </Col>
                      <Col md="8">
                        <div className="mb-8">
                          <Label htmlFor="validationCustom01">Comments</Label>
                          <textarea
                            style={{
                              height: "auto",
                              borderColor: "#c0c0c045",
                            }}
                            name="Comments"
                            placeholder="Comments"
                            type="number"
                            errorMessage="Enter Comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col style={{ marginTop: "auto" }}>
                        <div className="mb-3">
                          <Button color="primary" type="submit">
                            Add
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal> */}

      {/* View Response End */}
    </>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(StaffDetails));
