import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_MENTIONS,

} from './actionTypes';

import {
  getAllMenstionsSuccess,
  getAllMenstionsFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  getAllMenstions,
} from '../../helpers/backend_helper';

function* fetchMenstion(data) {
  try {
    const response = yield call(getAllMenstions, data);
    yield put(getAllMenstionsSuccess(response));
  } catch (error) {
    yield put(getAllMenstionsFail(error));
  }
}

function* mentionSaga() {
  yield takeEvery(GET_MENTIONS, fetchMenstion);
}

export default mentionSaga;
