import React, {useState} from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Bar } from "react-chartjs-2";





function FansFollowersCount() {


    // date
    const formatDate = (date, fullMonth = false) => {
        const options = fullMonth
            ? { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
            : { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        return new Date(date).toLocaleString('en-US', options);
    };

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showFullMonth, setShowFullMonth] = useState(false);

    const toggleShowFullMonth = () => {
        setShowFullMonth(!showFullMonth);
    };


    // bar chart1
    const data1 = {
        labels: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
        datasets: [
            {
                label: "Kial",
                data: [114113, 119564, 119610, 119691, 119444, 119359],
                backgroundColor: "#e83e8c"
            }
        ]
    }

    const option1 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Chart.js Bar Chart",
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 120000,
                        stepSize: 20000
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Followers Count',
                    },
                }
            ]
        }
    }

    // bar chart2
    const data2 = {
        labels: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
        datasets: [
            {
                label: 'Kial',
                data: [1000, 1000, 1000, 1000, 1000, 143736],
                backgroundColor: "#e83e8c"
            }
        ]
    }

    const option2 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Chart.js Bar Chart",
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 150000,
                        stepSize: 50000,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Followers Count',
                    },
                }
            ]
        }
    }

    // bar chart3
    const data3 = {
        labels: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
        datasets: [
            {
                label: "Kial",
                data: [10, 10, 10, 10, 10, 10],
                backgroundColor: "#e83e8c"
            }
        ]
    }

    const option3 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Chart.js Bar Chart",
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 1000,
                        stepSize: 200
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Followers Count',
                    },
                }
            ]
        }
    }

    // bar chart4
    const data4 = {
        labels: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
        datasets: [
            {
                label: "Kial",
                data: [2977641, 3160580, 3241067, 3341527, 3343144, 3340175],
                backgroundColor: "#e83e8c"
            }
        ]
    }

    const option4 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Chart.js Bar Chart",
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 3500000,
                        stepSize: 500000
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Fans Count',
                    },
                }
            ]
        }
    }

    // pareto diagram1
    const data5 = {
        labels: ['Sep', 'Nov', 'Jan 2024'],
        datasets: [{
            type: 'bar',
            label: 'Followers Count Increment',
            data: [114113, 5451],
            backgroundColor: '#e83e8c',
            borderColor: '#e83e8c',
            yAxisID: 'left-axis',
        }, {
            type: 'line',
            label: 'Total Followers Count',
            data: [114113, 119564, 119610, 119691, 119444, 119356], // Modify this data with your cumulative percentage values
            fill: false,
            borderColor: 'rgb(54, 162, 235)',
            yAxisID: 'right-axis',
        }]
    };

    const option5 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Pareto Chart - Twitter Followers Count (Main brand only)",
            },
        },
        scales: {
            xAxes: [{
                barPercentage: 0.2,
            }],
            yAxes: [{
                id: 'left-axis',
                position: 'left',
                ticks: {
                    beginAtZero: false,
                    min: -20000,
                    max: 120000,
                    stepSize: 20000,
                },
            }, {
                id: 'right-axis',
                position: 'right',
                ticks: {
                    beginAtZero: false,
                    min: 114000,
                    max: 120000,
                    stepSize: 1000,
                },
                // scaleLabel: {
                //     display: true,
                //     labelString: 'Cumulative Percentage',
                // },
                gridLines: {
                    drawOnChartArea: false, // This hides the grid lines for the right y-axis
                },
                offset: true,
            }],
        },
    };

    // pareto diagram2
    const data6 = {
        labels: ['Sep', 'Nov', 'Jan 2024'],
        datasets: [{
            type: 'bar',
            label: 'Followers Count Increment',
            data: [143754],
            backgroundColor: '#e83e8c',
            borderColor: '#e83e8c',
            yAxisID: 'left-axis',
        }, {
            type: 'line',
            label: 'Total Followers Count',
            data: [0, 50000, 143754], // Modify this data with your cumulative percentage values
            fill: false,
            borderColor: 'rgb(54, 162, 235)',
            yAxisID: 'right-axis',
        }]
    };

    const option6 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Pareto Chart - Instagram Followers Count (Main brand only)",
            },
        },
        scales: {
            xAxes: [{
                barPercentage: 0.2,
            }],
            yAxes: [{
                id: 'left-axis',
                position: 'left',
                ticks: {
                    beginAtZero: true,
                    max: 150000,
                    stepSize: 50000,
                },
            }, {
                id: 'right-axis',
                position: 'right',
                ticks: {
                    beginAtZero: true,
                    max: 150000,
                    stepSize: 50000,
                },
                // scaleLabel: {
                //     display: true,
                //     labelString: 'Cumulative Percentage',
                // },
                // gridLines: {
                //     drawOnChartArea: false, // This hides the grid lines for the right y-axis
                // },
                // offset: true,
            }],
        },
    };

    // pareto diagram3
    const data7 = {
        labels: ['Sep', 'Nov', 'Jan 2024'],
        datasets: [{
            type: 'bar',
            label: 'Followers Count Increment',
            data: [143754],
            backgroundColor: '#e83e8c',
            borderColor: '#e83e8c',
            yAxisID: 'left-axis',
        }, {
            type: 'line',
            label: 'Total Followers Count',
            data: [0, 50000, 143754], // Modify this data with your cumulative percentage values
            fill: false,
            borderColor: 'rgb(54, 162, 235)',
            yAxisID: 'right-axis',
        }]
    };

    const option7 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Pareto Chart - Instagram Followers Count (Main brand only)",
            },
        },
        scales: {
            xAxes: [{
                barPercentage: 0.2,
            }],
            yAxes: [{
                id: 'left-axis',
                position: 'left',
                ticks: {
                    beginAtZero: true,
                    max: 150000,
                    stepSize: 50000,
                },
            }, {
                id: 'right-axis',
                position: 'right',
                ticks: {
                    beginAtZero: true,
                    max: 150000,
                    stepSize: 50000,
                },
            }],
        },
    };

    // pareto diagram4
    const data8 = {
        labels: ['Sep', 'Nov', 'Jan 2024'],
        datasets: [{
            type: 'bar',
            label: 'Fans Count Increment',
            data: [278798, 198939, 80487],
            backgroundColor: '#e83e8c',
            borderColor: '#e83e8c',
            yAxisID: 'left-axis',
        }, {
            type: 'line',
            label: 'Total Fans Count',
            data: [2977641, 3241067, 3341527], // Modify this data with your cumulative percentage values
            fill: false,
            borderColor: 'rgb(54, 162, 235)',
            yAxisID: 'right-axis',
        }]
    };

    const option8 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Pareto Chart - Facebook Fans Count (Main brand only)",
            },
        },
        scales: {
            xAxes: [{
                barPercentage: 0.2,
            }],
            yAxes: [{
                id: 'left-axis',
                position: 'left',
                ticks: {
                    beginAtZero: false,
                    min: -50000,
                    max: 300000,
                    stepSize: 50000,
                },
            }, {
                id: 'right-axis',
                position: 'right',
                ticks: {
                    beginAtZero: false,
                    min: 2900000,
                    max: 3400000,
                    stepSize: 100000,
                },
                // scaleLabel: {
                //     display: true,
                //     labelString: 'Cumulative Percentage',
                // },
                // gridLines: {
                //     drawOnChartArea: false, // This hides the grid lines for the right y-axis
                // },
                // offset: true,
            }],
        },
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div>
                        <p className="titletit">Monthly Followers Report</p>
                        <h6 className="dateText" onClick={toggleShowFullMonth}>
                            {formatDate(startDate, showFullMonth)} - {formatDate(endDate, showFullMonth)}

                        </h6>
                    </div>


                    <Row style={{ rowGap: "20px" }}>

                        {/* bar chart1 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Twitter Followers Count</h5>
                                    <Bar width={474} height={200} data={data1} options={option1} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart2 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Instagram Followers Count</h5>
                                    <Bar width={474} height={200} data={data2} options={option2} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart3 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Pinterest Followers Count</h5>
                                    <Bar width={474} height={200} data={data3} options={option3} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart4 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Facebook Fans Count</h5>
                                    <Bar width={474} height={200} data={data4} options={option4} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* pareto chart1 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Twitter Followers Count(Main brand only)</h5>
                                    <Bar width={474} height={200} data={data5} options={option5} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* pareto chart2 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Instagram Followers Count(Main brand only)</h5>
                                    <Bar width={474} height={200} data={data6} options={option6} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* parteo chart3 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Pinterest Followers Count(Main brand only)</h5>
                                    <Bar width={474} height={200} data={data7} options={option7} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* pareto chart4 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Facebook Fans Count(Main brand only)</h5>
                                    <Bar width={474} height={200} data={data8} options={option8} />
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </>
    )

}

export default FansFollowersCount;