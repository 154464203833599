import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, CardTitle } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getAllModules } from "../../../store/actions";
import './previlage.scss';
const Privileges = (props) => {
  const [privilegeObject, setPrivilegeObject] = useState({});
  const [privilegeTemp, setprivilegeTemp] = useState([]);

  const [privilegeIdTobeUpdated, setPrivilegedToBeUpdated] = useState(null);
  const [privilegeIdToBeDeleted, setPrivilegeIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [userId, setUserId] = useState(1);
  const [allocationStatus, setAllocationStatus] = useState({});
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [details, setDetails] = useState([]);

  const { companiesOptions } = useSelector((state) => state.companies);
  const dispatch = useDispatch();

  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  function handleTableData() {
    var url = `${API_URL}privilage/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;

          item.modules = (
            <div>
              <ul
                style={{
                  listStyleType: "none",
                }}
              >
                {item.module &&
                  item.module.map((el, index) => {
                    return (
                      <li style={{ textAlign: "left" }} className="mb-2">
                        <input
                          type="checkbox"
                          className="mb-2"
                          name="allocation_status"
                          id="allocation_status"
                          checked={el.allocation_status === 1 ? true : false}
                          onChange={(e) => handleCheckboxChange(e, el, item)}
                        />
                        <span
                          style={{
                            marginLeft: "5px",
                          }}
                        >
                          {el?.moduleName}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );

          item.action = (
            <div className="action">
              <i
                className="far fa-edit"
                onClick={() => {
                  toTop();
                  preupdatePrivilege(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                onClick={() => {
                  setPrivilegeIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
        });

        setDetails(result);
      });
  }

  let preupdatePrivilege = (item) => {
    item.privilage_id = item?._id;
    setPrivilegedToBeUpdated(item._id);
    setPrivilegeObject(item);
  };

  // useEffect(() => {
  //   let privilegeDuplicate = JSON.parse(JSON.stringify(privilages));
  //   let privilegeData = [];
  //   privilegeDuplicate.map((item, index) => {
  //     item.action = (
  //       <div class="action">
  //         <i
  //           className="far fa-edit"
  //           onClick={() => {
  //             preupdatePrivilege(item);
  //           }}
  //         ></i>
  //         <i
  //           className="far fa-trash-alt"
  //           onClick={() => {
  //             setPrivilegeIdToBeDeleted(item._id);
  //             setConfirmDeleteAlert(true);
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     item.id = index + 1;

  //     privilegeData.push(item);
  //   });
  //   setprivilegeTemp(privilegeData);
  // }, [privilages]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "privilege_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Shortcode",
        field: "privilege_code",
        sort: "asc",
        width: 270,
      },
      {
        label: "Modules",
        field: "modules",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPrivilegeObject({
      ...privilegeObject,
      [name]: value,
    });
  };
  const handleValidSubmitPrivilege = (event, values) => {
    if (privilegeIdTobeUpdated) {
      axios
        .put(`${API_URL}privilage/update`, privilegeObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Privilege updated successfully");
            formRef.current.reset();
            setPrivilegeObject({ ["auth_userid"]: userId });
            setPrivilegedToBeUpdated(null);
            handleTableData();
          } else {
            toastr.error("Failed to update privilege");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}privilage`, privilegeObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Privilege created successfully");
            formRef.current.reset();
            setPrivilegeObject({ ["auth_userid"]: userId });
            handleTableData();
          } else {
            toastr.error("Failed to create privilege");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
    // privilegeIdTobeUpdated
    //   ? dispatch(updatePrivilege(privilegeObject))
    //   : dispatch(addPrivilege(privilegeObject));

    // formRef.current.reset();
    // setPrivilegeObject({ ["auth_userid"]: userId });
  };
  const reset = () => {
    formRef.current.reset();
    setPrivilegeObject({});
    setPrivilegeIdToBeDeleted(null);
    setPrivilegedToBeUpdated(null);
  };
  const handleCheckboxChange = (e, el, item) => {
    let data = {
      privilage_id: item?._id,
      alloted_modules: el?._id,
      status: e.target.checked === true ? 1 : 0,
    };
    axios
      .post(`${API_URL}privilage/rule`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            var user_obj = JSON.parse(data);

            if(item?._id === user_obj?.privilage)
              dispatch(getAllModules(user_obj?.privilage));
          }
        }
        handleTableData();
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}privilage` + "/" + privilegeIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Privilege deleted successfully");
                  handleTableData();
                } else {
                  toastr.error("Failed to delete privilege");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            // dispatch(deletePrivilege(privilegeIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Privilege" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitPrivilege(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Privilege</Label>

                          <AvField
                            name="privilege_name"
                            value={privilegeObject.privilege_name}
                            placeholder="Privilege"
                            type="text"
                            errorMessage="Enter Privilege"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Za-z ]+$",
                                errorMessage:
                                  "Privilege must be only with letter and spaces",
                              },
                            }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Shortcode</Label>

                          <AvField
                            name="privilege_code"
                            value={privilegeObject.privilege_code}
                            placeholder="Shortcode"
                            type="text"
                            errorMessage="Enter Shortcode"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Za-z ]+$",
                                errorMessage:
                                  "Shortcode must be only with letter and spaces",
                              },
                            }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {privilegeIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="privilegeTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Privileges;
