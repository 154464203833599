import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./productCategory.scss";

const ProductCategory = (props) => {
  const [productCatObj, setproductCatObj] = useState({});
  const [categoryIdTobeUpdated, setCategoryIdToBeUpdated] = useState(null);
  const [categoryIdToBeDeleted, setCategoryIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [productCatValue, setProductcatValue] = useState([]);
  const [details, setDetails] = useState([]);

  const {
    districts,
    addingProductCategory,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  //   const dispatch = useDispatch();

  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}product_category/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let subjectData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateProductCategory(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setCategoryIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.subject_date).format("DD-MM-YYYY");
          //  item.time = moment(item.subject_time, "HHmmss").format("hh:mm a");
          if (item?.date) item.date = moment(item.date).format("DD-MM-YYYY");
          else item.date = moment(item.date).format("DD-MM-YYYY");

          if (item?.time)
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          else item.time = moment(item.time, "HHmmss").format("hh:mm a");

          if (item?.updatedby) item.staff = item.updatedby.firstName;
          else item.staff = item?.addedby.firstName;
          subjectData.push(item);
        });

        setDetails(subjectData);
      });
  }

  let preUpdateProductCategory = (item) => {
    setProductcatValue(item?.name);
    setCategoryIdToBeUpdated(item._id);
    setproductCatObj(item);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const reset = () => {
    formRef.current.reset();
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },

      {
        label: "Category",
        field: "name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setProductcatValue(value);

    setproductCatObj({ ...productCatObj, [name]: value });
  };
  const handleValidSubmit = () => {
    if (categoryIdTobeUpdated) {
      axios
        .put(`${API_URL}product_category/update`, productCatObj, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Product category updated successfully");
            setProductcatValue("");
            formRef.current.reset();
            handleTableData();
            setproductCatObj({});
            setCategoryIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update product category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}product_category`, productCatObj, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Product category added succesfully");
            handleTableData();
            setProductcatValue("");
            formRef.current.reset();
            setproductCatObj({});
            formRef.current.reset();
          } else {
            toastr.error("Failed to add product category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}product_category` + "/" + categoryIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Product category deleted successfully");
                  if (
                    productCatObj &&
                    productCatObj._id === categoryIdToBeDeleted
                  ) {
                    setProductcatValue("");
                    formRef.current.reset();
                    setproductCatObj({});
                    setCategoryIdToBeDeleted(null);
                  }
                  handleTableData();
                } else {
                  toastr.error(
                    res.data.message,
                    "Failed to delete product category"
                  );
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Product Category" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Category</Label>
                          <AvField
                            name="name"
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Enter Subject Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={productCatObj?.name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          {categoryIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingProductCategory ? true : false}
                            >
                              {addingProductCategory ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingProductCategory ? true : false}
                            >
                              {addingProductCategory ? "Adding" : "Submit"}
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="submit"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="productCatTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductCategory;
