import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  //   getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import "./productSummary.scss";
const ResponseSummary = (props) => {
  const [details, setDetails] = useState([]);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData(searchData.from_date,searchData.to_date);
  }, []);
  function handleTableData(
    from_date = "",
    to_date = ""
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }
    var url =
      `${API_URL}responseReport/product_wise?from_date=`+from_date+"&to_date="+to_date;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let tot_total=0;
        let tot_closed=0;
        let tot_pending=0;
        let tot_pending_more=0;
        let tot_responded=0;
        let tot_info=0;
        let tot_avg=0.00;
        let total_rows=0;
        let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            total_rows++;
            let exportItem = {};
            item.id = index + 1;
            item.avg = item.avg.toFixed(2);
            tot_total=tot_total+item.total_count;
            tot_closed=tot_closed+item.closed_count;
            tot_pending=tot_pending+item.pending_count;
            tot_pending_more=tot_pending_more+item.pending_more;
            tot_responded=tot_responded+item.responded;
            tot_info=tot_info+item.cust_info;
            tot_avg=tot_avg+parseFloat(item.avg);
            //export data
            //exportItem.id = item.id;
            exportItem.Product = item.product;
            exportItem.Total = item.total_count;
            exportItem.Closed = item.closed_count;
            exportItem.Pending = item.pending_count;
            exportItem.Pending_More_One_Month=item.pending_more;
            exportItem.Initial_Response_Given = item.responded;
            exportItem.InfoFrom_Customer_Received = item.cust_info;
            exportItem.Avg_Resolution_Days = item.avg;
            dataToBeExported.push(exportItem);
          });
        // let test = getArraySumByKey(result,"total_amount")
        result.push({
        product:"Total",
        total_count:tot_total,
        closed_count:tot_closed,
        pending_count:tot_pending,
        pending_more:tot_pending_more,
        responded:tot_responded,
        cust_info:tot_info,
        avg:(tot_avg/total_rows).toFixed(2)
        })
        dataToBeExported.push({
          Product:"Total",
          Total:tot_total,
          Closed:tot_closed,
          Pending:tot_pending,
          Pending_More_One_Month:tot_pending_more,
          Initial_Response_Given:tot_responded,
          InfoFrom_Customer_Received:tot_info,
          Avg_Resolution_Days:(tot_avg/total_rows).toFixed(2)
        })
        setDetails(result);
        //let total = getArraySumByKey(result, "total_amount");
       // setTotal(total);
        setDataToBeExported(dataToBeExported);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Product",
        field: "product",
        sort: "asc",
        width: 400,
      },
      {
        label: "Total",
        field: "total_count",
        sort: "asc",
        width: 200,
      },
      {
        label: "Closed",
        field: "closed_count",
        sort: "asc",
        width: 200,
      },
      {
        label: "Pending Closure",
        field: "pending_count",
        sort: "asc",
        width: 200,
      },
      {
        label: "Pending (+1 month)",
        field: "pending_more",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ini. Response",
        field: "responded",
        sort: "asc",
        width: 200,
      },
      {
        label: "Customer info",
        field: "cust_info",
        sort: "asc",
        width: 200,
      },
      {
        label: "Avg Resolution",
        field: "avg",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    //handleTableData(date1,date2)
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    handleTableData(getFirstday(new Date()),getDate(new Date()));
  };
  const handleSearch = () => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableData(date1, date2);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Response Summary - Product Wise" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">From
                      <div className="mb-3">
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">To
                      <div className="mb-3">
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "12px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "12px" }}>
                      <div className="mt-2 mb-3">
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Response_report_product_wise" + getDate(new Date()) + ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "12px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="productSummaryTableId"
                    responsive
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={100}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ResponseSummary;
