import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import io from "socket.io-client";
// import socketIOClient from "socket.io-client";
// import Select from "react-select";
import { getAllMenstions_test } from "../../store/actions";
import { apiError } from "../../store/actions";
// import { styled } from "@mui/material/styles";
// import Badge from "@mui/material/Badge";
// import Avatar from "@mui/material/Avatar";
// import Stack from "@mui/material/Stack";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

// import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

// import TwitterIcon from "@mui/icons-material/Twitter";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
// import SendRoundedIcon from "@mui/icons-material/SendRounded";

// import Tooltip from "@mui/material/Tooltip";

import "./index.scss";

// import Select from "react-select";

//Import Breadcrumb

import "./index.scss";
// import twitterMention from "../../assets/images/twitterMention.svg";
// import TwitterDirectMessege from "../../assets/images/TwitterDirectMessege.svg";
import Select from "react-select";
const SrvSocial = (props) => {
  const [srvsocialObject, setsrvsocialObject] = useState({});

  const [srvsocialIdToBeIgnored, setsrvsocialIdToBeIgnored] = useState(null);
  // const [srvsocialIdToBeDeleted, setsrvsocialIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const [srvsocialForTable, setsrvsocialForTable] = useState([]);
  const [userMentionData, setUserMentionData] = useState({});
  const [selectedSearchDepartment, setSelectedSearchDepartment] =
    useState(null);
  // const [showReplyReceipt, setShowReplyReceipt] = useState(false);
  const { mentions } = useSelector((state) => state.mentions_test);
  const dispatch = useDispatch();
  const API_URL = "https://crmtestapi.srvinfotech.com/";
  const [show, setShow] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [popupFollowUpData, setpopupFollowUpData] = useState([]);

  // const [selectedSource, setSelectedSource] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // const [sourceOptions, setSourceOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [addFollowUpData, setAddFollowUpData] = useState({});
  // const [selectedStatus, setSelectedStatus] = useState(null);
  // const [selectedCommunication, setSelectedCommunication] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [templateLanguage, setTemplateLanguage] = useState({});
  // const [mediaDetails, setMediaDetails] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [imageArray, setImageArray] = useState([]);
  const formRef = useRef();
  // const formRefFollowUp = useRef();

  useEffect(() => {
    dispatch(getAllMenstions_test());
    // fetch_source();
    fetch_template();
    fetch_all_Department();
  }, []);

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
    }),
  };

  function fetch_all_Department() {
    axios
      .get(`${API_URL}department/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var departmentdata =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.depart_Name,
              value: el._id,
            };
          });
        setDepartmentOptions([{ options: departmentdata }]);
      });
  }
  // const showReplyForm = (item) => {
  //   // var url = `${API_URL}twitter/name?id=` + item?._id;

  //   axios
  //     .get(`${API_URL}twitter/name?id=` + item.id, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         let result = res.data.data;
  //         setUserMentionData({
  //           id: result[0]?.id,
  //           name: result[0]?.name,
  //           mentionname: "@" + result[0]?.name,
  //         });
  //         setShowReplyReceipt(true);
  //       } else {
  //         toastr.error(res.data.data);
  //         return;
  //       }
  //     })
  //     .catch((err) => {
  //       toastr.error(err.response.data.message);
  //       return;
  //     });
  // };
  // const fetch_source = () => {
  //   axios
  //     .get(`${API_URL}response/source`, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       let source_Data =
  //         res.data.data &&
  //         res.data.data.map((el) => {
  //           return {
  //             label: el?.source_name,
  //             value: el?._id,
  //           };
  //         });
  //       setSourceOptions([
  //         {
  //           options: source_Data,
  //         },
  //       ]);
  //     });
  // };
  function timeSince(date, now_date) {
    var seconds = Math.floor((now_date - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " yrs";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hrs";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " min";
    }
    return Math.floor(seconds) + " sec";
  }
  const fetch_template = () => {
    axios
      .get(`${API_URL}FAQ/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let template_Data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.temp_name,
              value: el?._id,
              temp_english: el?.temp_english,
              temp_hindi: el?.temp_hindi,
              temp_marathi: el?.temp_marathi,
            };
          });
        setTemplateOptions([
          {
            options: template_Data,
          },
        ]);
      });
  };
  function handleTableData() {
    let acctypeDuplicate = JSON.parse(JSON.stringify(mentions));
    let acctypeData = [];
    acctypeDuplicate.map((item, index) => {
      item.action = (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <Box sx={{ "& button": { m: 1 } }}> */}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Reply
                </Tooltip>
              }
            >
              <i
                className="fas fa-comment-medical"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  // showReplyForm(item);
                  handleClickopenForm(item);
                }}
              ></i>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Hide
                </Tooltip>
              }
            >
              <i
                className="fas fa-eye-slash"
                style={{
                  fontSize: "1em",
                  cursor: "not-allowed",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Ignore
                </Tooltip>
              }
            >
              <i
                className="fas fa-ban"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  setsrvsocialIdToBeIgnored(item.id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Delete
                </Tooltip>
              }
            >
              <i
                className="fas fa-trash-alt"
                style={{
                  fontSize: "1em",
                  cursor: "not-allowed",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   setsrvsocialIdToBeDeleted(item.id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </OverlayTrigger>
          </div>
        </>
      );
      item.id2 = index + 1;

      // if (item?.social_media === "Twitter" && item?.category === "Mention") {
      //   item.socialMedia = (
      //     <div style={{ display: "flex", justifyContent: "center" }}>
      //       <img src={twitterMention} height="28" width="28" />
      //     </div>
      //   );
      // } else if (
      //   item?.social_media === "Twitter" &&
      //   item?.category === "DM Received"
      // ) {
      //   item.socialMedia = (
      //     <div style={{ display: "flex", justifyContent: "center" }}>
      //       <img src={TwitterDirectMessege} height="28" width="28" />
      //     </div>
      //   );
      // }
      let imageType = item?.source_type || {};
      if (imageType[0]?.icon) {
        item.socialMedia = (
          // <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={`${API_URL}uploads/twitter/${imageType[0]?.icon}`}
            height="30"
            width="50"
          />
          // </div>
        );
      } else item.socialMedia = "";

      item.time = moment(item.time, "HHmmss").format("hh:mm a"); // 24hours
      item.date = (
        <span>
          {item.date}<br></br>{item.time}</span>
      );
      let strLength = item?.text.length;
      var message = item?.text;
      if (strLength && strLength > 90) {
        var length = 90;
        message = message.substring(0, length) + "...";
      }
      // item.textMessage = item?.text

      item.textMessage = (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip
                id="button-tooltip-2"
                style={{ position: "absolute", top: "-30px" }}
              >
                {item?.text}
              </Tooltip>
            }
          >
            <div>{message}</div>
          </OverlayTrigger>
        </>
      );
      item.department = (
        <Select
          styles={style}
          placeholder="Select"
          menuPosition="fixed"
          name="department"
          value={selectedSearchDepartment}
          onChange={(selected) => {
            setSelectedSearchDepartment(selected);
          }}
          options={[
            {
              label: "ABC Government",
              value: "1",
            },
            {
              label: "ABC Third Party",
              value: "2",
            },
            {
              label: "Agriculture",
              value: "3",
            },
          ]}
          classNamePrefix="select2-selection"
        />
      );
      item.tnt = timeSince(
        new Date(moment(item.createAt).format("YYYY-MM-DD HH:mm:ss")),
        new Date()
      );
      // item.textMessage = (
      //   <div>
      {
        /* <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip
                id="button-tooltip-2"
                style={{ position: "absolute", top: "-30px" }}
              >
                {item?.text}
              </Tooltip>
            }
          > */
      }
      // {message}
      {
        /* </OverlayTrigger> */
      }
      //   </div>
      // );
      acctypeData.push(item);
    });

    setsrvsocialForTable(acctypeData);
  }
  const handleClickopenForm = (data) => {
    let sourceName = data?.source_type || {};

    let sourceType;
    if (sourceName[0]?.source_name) sourceType = sourceName[0]?.source_name;
    else sourceType = "";

    if (sourceType === "Twitter Mention") {
      axios
        .get(`${API_URL}twitter/reply/view?id=` + data?.id, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let result = res.data.data[0];

          let arrayFirstIndex = [
            {
              date: result?.date,
              time: result?.time,
              src_icon: result?.src_icon,
              text: result?.text,
              social_status: "New",
              addedby: result?.addedby,
              type: result?.type ? result.type : "",
              storedmedia: result?.storedmedia ? result.storedmedia : [],
              createAt: result?.createAt,
            },
          ];
          result.tnt = timeSince(
            new Date(moment(result.createAt).format("YYYY-MM-DD HH:mm:ss")),
            new Date()
          );

          let followUp = result.followup || [];

          let newArray = followUp.concat(arrayFirstIndex);
          newArray &&
            newArray.map((el, index) => {
              el.id = index + 1;
              el.time = moment(el?.time, "hhmmss").format("hh:mm a");
              el.date = (
                <span>
                  {el.date}<br></br>{el.time}</span>
              );
              //if (index === 0) {
                if (el?.type === "photo") {
                  el.screenshot = (
                    <div
                      className="mb-3 mt-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Row>
                        {el.storedmedia &&
                          el.storedmedia.map((image) => {
                            return (
                              <Col>
                                <img
                                  alt=""
                                  width="50"
                                  height="50"
                                  src={image}
                                />
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  );
                } else if (
                  el?.type === "video" ||
                  el?.type === "animated_gif"
                ) {
                  el.screenshot = (
                    <div className="mb-3 mt-2">
                      <Row>
                        {el.storedmedia &&
                          el.storedmedia.map((video) => {
                            return (
                              <Col>
                                <video
                                  className="VideoInput_video"
                                  width="110"
                                  height="100"
                                  controls
                                  src={video}
                                />
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  );
                } else el.screenshot = "";
              //}
              el.social_media = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/twitter/${el?.src_icon}`}
                    height="30"
                    width="50"
                  />
                </div>
              );
            });

          let mention = result.mention || [];

          let mentionName = result?.author_username
            ? "@" + result?.author_username
            : "";

          mention &&
            mention.map((el) => {
              if (mentionName === "") mentionName = "@" + el;
              else mentionName = mentionName + " " + "@" + el;
            });

          setPopupData(result);
          setSelectedLanguage({
            label: "English",
            value: 1,
          });
          setAddFollowUpData({
            id: data?.id,
            mentionname: mentionName,
            authName: result?.author_name,
            status: 3,
            source: result?.source_type,
            communication_type: 2,
            author_id: result?.author_id,
          });
          setImageArray([]);
          setpopupFollowUpData(newArray);
          setShow(true);
        });
    } else if (sourceType === "Twitter Direct Message") {
      axios
        .get(`${API_URL}twitter/dm/view?id=` + data?.id, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let result = res.data.data[0];

          // let arrayFirstIndex = [
          //   {
          //     date: result?.date,
          //     time: result?.time,
          //     src_icon: result?.src_icon,
          //     text: result?.text,
          //     social_status: "New",
          //     addedby: result?.addedby,
          //     type: result?.type ? result.type : "",
          //     storedmedia: result?.storedmedia ? result.storedmedia : [],
          //     createAt: result?.createAt,
          //   },
          // ];
          let arrayFirstIndex=[];
          result.tnt = timeSince(
            new Date(moment(result.createAt).format("YYYY-MM-DD HH:mm:ss")),
            new Date()
          );

          let followUp = result.followup || [];

          let newArray = followUp.concat(arrayFirstIndex);
          newArray &&
            newArray.map((el, index) => {
              el.id = index + 1;
              el.time = moment(el?.time, "hhmmss").format("hh:mm a");
              el.date = (
                <span>
                  {el.date}<br></br>{el.time}</span>
              );
              el.social_status = el?.category;
              if (index === 0) {
                if (el?.type === "photo") {
                  el.screenshot = (
                    <div
                      className="mb-3 mt-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Row>
                        {el.storedmedia &&
                          el.storedmedia.map((image) => {
                            return (
                              <Col>
                                <img
                                  alt=""
                                  width="50"
                                  height="50"
                                  src={image}
                                />
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  );
                } else if (
                  el?.type === "video" ||
                  el?.type === "animated_gif"
                ) {
                  el.screenshot = (
                    <div className="mb-3 mt-2">
                      <Row>
                        {el.storedmedia &&
                          el.storedmedia.map((video) => {
                            return (
                              <Col>
                                <video
                                  className="VideoInput_video"
                                  width="110"
                                  height="100"
                                  controls
                                  src={video}
                                />
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  );
                } else el.screenshot = "";
              }
              el.social_media = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/twitter/${el?.src_icon}`}
                    height="30"
                    width="50"
                  />
                </div>
              );
            });

          let mention = result.mention || [];

          let mentionName = result?.author_username
            ? "@" + result?.author_username
            : "";

          mention &&
            mention.map((el) => {
              if (mentionName === "") mentionName = "@" + el;
              else mentionName = mentionName + " " + "@" + el;
            });

          setPopupData(result);
          setSelectedLanguage({
            label: "English",
            value: 1,
          });
          setAddFollowUpData({
            id: data?.id,
            mentionname: mentionName,
            authName: result?.author_name,
            status: 3,
            source: result?.source_type,
            communication_type: 2,
            author_id: result?.author_id,
          });
          setImageArray([]);
          setpopupFollowUpData(newArray);
          setShow(true);
        });
    }
  };
  const handleCloseModal = () => {
    // setSelectedCommunication(null);
    setSelectedLanguage(null);
    setSelectedDepartment(null);
    // setSelectedSource(null);
    // setSelectedStatus(null);
    setSelectedTemplate(null);
    setAddFollowUpData({});
    setImageArray([]);
    setShow(false);
  };
  useEffect(() => {
    // const sockets = io(API_URL, { transports: ["websocket"] });
    // sockets.on("receipt", (msg) => {
    //   dispatch(getAllMenstions());
    // });
    // return () => sockets.disconnect();

    const sockets = io(API_URL, {
      reconnect: false,
      transports: ["websocket"],
      allowUpgrades: false,
      pingTimeout: 180000,
      pingInterval: 25000,
    });
    sockets.on("mention", (msg) => {
      dispatch(getAllMenstions_test());
    });
    return () => sockets.disconnect();
  }, []);
  useEffect(() => {
    handleTableData();
  }, [mentions]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "TAT",
        field: "tnt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Source",
        field: "socialMedia",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "author_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Message",
        field: "textMessage",
        sort: "asc",
        width: 100,
      },
      {
        label: "Department",
        field: "department",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Category",
      //   field: "category",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Status",
        field: "social_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: srvsocialForTable,
  };

  const popupFolloupData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "TAT",
      //   field: "tnt",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Source",
        field: "social_media",
        sort: "asc",
        width: 100,
      },
      {
        label: "Comments",
        field: "text",
        sort: "asc",
        width: 100,
      },

      {
        label: "Attachments",
        field: "screenshot",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "social_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "addedby",
        sort: "asc",
        width: 150,
      },
    ],
    rows: popupFollowUpData,
  };
  // const handleValidSubmit = () => {
  //   axios
  //     .post(`${API_URL}twitter/reply`, userMentionData, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         toastr.success("Reply Sent successfully");
  //         // handleTableData();
  //         dispatch(getAllMenstions());
  //         setUserMentionData({});
  //         setShowReplyReceipt(false);
  //         formRef.current.reset();
  //       } else {
  //         toastr.error("Failed to Send Reply");
  //         return;
  //       }
  //     })
  //     .catch((err) => {
  //       toastr.error(err.response.data.message);
  //       return;
  //     });
  // };
  // const handlePopupInputChange = (e) => {
  //   setUserMentionData({
  //     ...userMentionData,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      // case "status":
      //   setSelectedStatus(selected);
      //   setAddFollowUpData({
      //     ...addFollowUpData,
      //     status: selected.value,
      //   });
      //   break;
      // case "source":
      //   setSelectedSource(selected);
      //   setAddFollowUpData({
      //     ...addFollowUpData,
      //     source: selected.value,
      //   });
      //   break;
      // case "communication":
      //   setSelectedCommunication(selected);
      //   setAddFollowUpData({
      //     ...addFollowUpData,
      //     communication_type: selected.value,
      //   });
      //   break;
      case "template":
        setSelectedTemplate(selected);
        setTemplateLanguage({
          temp_english: selected?.temp_english,
          temp_hindi: selected?.temp_hindi,
          temp_marathi: selected?.temp_marathi,
          value: selected?.value,
        });
        fetch_comments(selected, selectedLanguage);
        break;
      case "language":
        setSelectedLanguage(selected);
        fetch_comments(templateLanguage, selected);
        break;
      case "department":
        setSelectedDepartment(selected);
        break;
      default:
        break;
    }
  };
  const fetch_comments = (templateLanguage, language) => {
    var comment = "";
    if (language?.label === "English") {
      comment = templateLanguage?.temp_english
        ? templateLanguage.temp_english
        : "";
    } else if (language?.label === "Hindi") {
      comment = templateLanguage?.temp_hindi ? templateLanguage.temp_hindi : "";
    } else if (language?.label === "Marathi") {
      comment = templateLanguage?.temp_marathi
        ? templateLanguage.temp_marathi
        : "";
    } else comment = "";

    if (comment !== "")
      comment = comment.replace("###name###", addFollowUpData?.authName);

    setAddFollowUpData({
      ...addFollowUpData,
      faq_type: language?.value,
      text: comment,
      faq: templateLanguage?.value,
    });
  };
  const handleChangePopupInput = (e) => {
    setAddFollowUpData({
      ...addFollowUpData,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmitFollowup = () => {
    axios
      .post(`${API_URL}twitter/reply`, addFollowUpData, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Follow up added successfully");
          setShow(false);
          // setSelectedCommunication(null);
          setSelectedLanguage(null);
          // setSelectedSource(null);
          // setSelectedStatus(null);
          setSelectedTemplate(null);
          setSelectedDepartment(null);
          setAddFollowUpData({});
          setImageArray([]);
          dispatch(getAllMenstions_test());
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}twitter/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          var filename = response.data.file[0]?.filename;
          if (imageArray && imageArray.length > 3) {
            toastr.error("Image upload limit exeeded");
            return;
          } else {
            let arr = imageArray;
            arr.push(filename);
            setImageArray(arr);
            setAddFollowUpData({
              ...addFollowUpData,
              [`screenshot`]: arr,
            });
          }
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteTwitterImage = (index) => {
    const image = [...imageArray];
    image.splice(index, 1);
    setImageArray(image);
    setAddFollowUpData({
      ...addFollowUpData,
      [`screenshot`]: image,
    });
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          // onConfirm={() => {
          //   dispatch(deleteCategory(categoryIdToBeDeleted));
          //   setConfirmDeleteAlert(false);
          // }}
          onConfirm={() => {
            axios
              .delete(`${API_URL}twitter` + "/" + srvsocialIdToBeIgnored, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Mention Ignored Successfully");

                  if (
                    srvsocialObject &&
                    srvsocialObject.id === userMentionData
                  ) {
                    formRef.current.reset();

                    setsrvsocialObject({});
                  }
                  dispatch(getAllMenstions_test());
                  setsrvsocialIdToBeIgnored(null);
                } else {
                  toastr.error(res.data.message, "Failed to Ignore Mention");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to Ignore it?
        </SweetAlert>
      ) : null}
      <Modal size="xl" show={show} centered={true} className="modal-fullscreen">
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {/* {popupData?.contact_id}
            {"-"} */}
            {popupData?.contact_name}
          </h5>
          <button
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={() => {
            handleValidSubmitFollowup();
          }}
        >
          <div className="row modal-body">
            <div className="col-xl-3">
              <div
                className="card"
                style={{
                  // width: "fit-content",
                  // width: "351px",
                  height: "max-content",
                  boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                }}
              >
                <div className="card-body" style={{ paddingTop: "0" }}>
                  <Row>
                    <Col xl="12">
                      <Col md="12">
                        <div className="mb-10">
                          <p className="mb-1">
                            TAT :{" "}
                            <span
                              className="font-size-14"
                              style={{ fontWeight: "bold" }}
                            >
                              {popupData?.tnt}
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-3">
                          <Select
                            name="department"
                            placeholder="Assign Department"
                            value={selectedDepartment}
                            options={departmentOptions}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "department");
                            }}
                          />
                        </div>
                      </Col>
                    </Col>
                  </Row>

                  <h4 className="modal-title mt-0">Contact Details</h4>
                  <div className="mt-2" hidden={!popupData?.custId}>
                    <p className="mb-1">ID:</p>
                    <h5 className="font-size-14">{popupData?.custId}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.acctNo}>
                    <p className="mb-1">Acc No:</p>
                    <h5 className="font-size-14">{popupData?.acctNo}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.cardNo}>
                    <p className="mb-1">Card No:</p>
                    <h5 className="font-size-14">{popupData?.cardNo}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.loanNo}>
                    <p className="mb-1">Loan No:</p>
                    <h5 className="font-size-14">{popupData?.loanNo}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.email}>
                    <p className="mb-1">Email:</p>
                    <h5 className="font-size-14">{popupData?.email}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.mobile}>
                    <p className="mb-1">Mobile:</p>
                    <h5 className="font-size-14">{popupData?.mobile}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.twitterId}>
                    <p className="mb-1">Twitter ID:</p>
                    <h5 className="font-size-14">{popupData?.twitterId}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.fbId}>
                    <p className="mb-1">Facebook ID:</p>
                    <h5 className="font-size-14">{popupData?.fbId}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.instaId}>
                    <p className="mb-1">Instagram ID:</p>
                    <h5 className="font-size-14">{popupData?.instaId}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <div
                className="card"
                style={{
                  height: "max-content",
                  boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                }}
              >
                <div className="card-body" style={{ paddingTop: "0" }}>
                  <Row>
                    <Col xl="12">
                      <CardBody>
                        <Row>
                          {/* <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">Status</Label>
                              <Select
                                name="status"
                                value={selectedStatus}
                                options={[
                                  {
                                    label: "New",
                                    value: 0,
                                  },
                                  {
                                    label: "Pending",
                                    value: 1,
                                  },
                                  {
                                    label: "Closed",
                                    value: 2,
                                  },
                                  {
                                    label: "Responded",
                                    value: 3,
                                  },
                                ]}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "status");
                                }}
                              />
                            </div>
                          </Col> */}
                          {/* <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">Source</Label>
                              <Select
                                name="Source"
                                value={selectedSource}
                                options={sourceOptions}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "source");
                                }}
                              />
                            </div>
                          </Col> */}
                          {/* <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Communication
                              </Label>
                              <Select
                                name="Internal"
                                value={selectedCommunication}
                                options={[
                                  {
                                    label: "In",
                                    value: 1,
                                  },
                                  {
                                    label: "Out",
                                    value: 2,
                                  },
                                  {
                                    label: "Internal",
                                    value: 3,
                                  },
                                ]}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "communication");
                                }}
                              />
                            </div>
                          </Col> */}

                          <Col md="2">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Template
                              </Label>
                              <Select
                                name="faq_type"
                                value={selectedTemplate}
                                options={templateOptions}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "template");
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Language
                              </Label>
                              <Select
                                name="faq_type"
                                value={selectedLanguage}
                                options={[
                                  {
                                    label: "English",
                                    value: 1,
                                  },
                                  {
                                    label: "Hindi",
                                    value: 2,
                                  },
                                  {
                                    label: "Marathi",
                                    value: 3,
                                  },
                                ]}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "language");
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="8">
                            <div className="mb-10">
                              <Label htmlFor="validationCustom01">
                                Comments
                              </Label>
                              <AvField
                                name="text"
                                placeholder="Comments"
                                type="textarea"
                                errorMessage="Enter Comments"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom02"
                                value={addFollowUpData?.text}
                                onChange={handleChangePopupInput}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Attachments
                              </Label>
                              <AvField
                                name="screenshot"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadImage}
                                rows="1"
                              />
                            </div>
                          </Col>
                          <Col style={{ marginTop: "25px" }}>
                            <Button color="primary" type="submit">
                              Send
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          {imageArray &&
                            imageArray.map((image, index) => {
                              return (
                                <>
                                  <Col md="3" style={{ paddingRight: "0" }}>
                                    <div className="mb-3">
                                      <img
                                        alt=""
                                        width="150"
                                        height="150"
                                        src={`${API_URL}uploads/twitter/${image}`}
                                      />
                                      <button
                                        type="button"
                                        //className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                        className="upload_image_close"
                                        onClick={() =>
                                          deleteTwitterImage(index)
                                        }
                                      ></button>
                                    </div>
                                  </Col>
                                </>
                              );
                            })}
                        </Row>
                      </CardBody>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="popupFollowupTableId"
                    responsive
                    bordered
                    data={popupFolloupData}
                    searching={false}
                    info={false}
                    paging={false}
                    entries={20}
                  />
                </div>
              </div>
            </div>

            {/* Bank Details */}
            {/* <h5 className="modal-title mt-0">Bank Details</h5>
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tbody>
                <tr>
                  <td style={{ paddingLeft: "25px" }}>Acc. No. :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.AccountNo}</td>
                  <td style={{ paddingLeft: "25px" }}>Card No. :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.CardNo}</td>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Customer ID :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.CustID}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ paddingLeft: "25px" }}>Loan No. :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.LoanNo}</td>
                  <td style={{ paddingLeft: "25px" }}>Branch :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.Branch}</td>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Locality :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.location}</td>
                </tr>
              </tbody>
            </table> */}

            {/* Comments Table */}

            {/* <Row>
            <Col className="col-12">
              <Card>
                <CardBody> */}
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Social Listening" />
          <Row>
            <Col xl="12">
              {/* <Modal
                show={showReplyReceipt}
                toggle={() => setShowReplyReceipt(!showReplyReceipt)}
                size="md"
                centered={true}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Reply To Mention</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setShowReplyReceipt(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <AvForm
                  className="needs-validation"
                  ref={formRef}
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}
                >
                  <div className="text-center mt-2">
                    <p>
                      Reply To{"  "}
                      {userMentionData?.name}
                    </p>
                  </div>

                  <div className="modal-body">
                    <div className="mb-3">
                      <textarea
                        name="text"
                        className="form-control"
                        placeholder="Type your reply here"
                        type="textarea"
                        errorMessage="Please Enter Your Reply"
                        validate={{ required: { value: true } }}
                        id="validationCustom04"
                        value={userMentionData?.text}
                        onChange={handlePopupInputChange}
                        style={{ borderColor: "#b6b6b68a" }}
                      />
                    </div>
                    <Row className="row mb-0">
                      <Col className="col-12 text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Reply{"  "}
                          <SendRoundedIcon />
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </Modal> */}
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="srvSocialTableID"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(SrvSocial));

SrvSocial.propTypes = {
  error: PropTypes.any,
  categories: PropTypes.array,
};
// export default SrvSocial;
