export const GET_PRIVILAGES = "GET_PRIVILAGES";
export const GET_PRIVILAGES_SUCCESS = "GET_PRIVILAGES_SUCCESS";
export const GET_PRIVILAGES_FAIL = "GET_PRIVILAGES_FAIL";

export const ADD_PRIVILAGE = "ADD_PRIVILAGE";
export const ADD_PRIVILAGE_SUCCESS = "ADD_PRIVILAGE_SUCCESS";
export const ADD_PRIVILAGE_FAIL = "ADD_PRIVILAGE_FAIL";

export const UPDATE_PRIVILAGE = "UPDATE_PRIVILAGE";
export const UPDATE_PRIVILAGE_SUCCESS = "UPDATE_PRIVILAGE_SUCCESS";
export const UPDATE_PRIVILAGE_FAIL = "UPDATE_PRIVILAGE_FAIL";

export const DELETE_PRIVILAGE = "DELETE_PRIVILAGE";
export const DELETE_PRIVILAGE_SUCCESS = "DELETE_PRIVILAGE_SUCCESS";
export const DELETE_PRIVILAGE_FAIL = "DELETE_PRIVILAGE_FAIL";
/* USERS */
export const GET_PRIVILAGES_OPTIONS = "GET_PRIVILAGES_OPTIONS";
export const GET_PRIVILAGES_OPTIONS_SUCCESS = "GET_PRIVILAGES_OPTIONS_SUCCESS";
export const GET_PRIVILAGES_OPTIONS_FAIL = "GET_PRIVILAGES_OPTIONS_FAIL";

//modules
export const GET_PRIVILAGES_MODULES = "GET_PRIVILAGES_MODULES";
export const GET_PRIVILAGES_MODULES_SUCCESS = "GET_PRIVILAGES_MODULES_SUCCESS";
export const GET_PRIVILAGES_MODULES_FAIL = "GET_PRIVILAGES_MODULES_FAIL";
