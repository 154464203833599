import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  //   getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import "./sourceSummary.scss";
const ResponseSummary = (props) => {
  const [details, setDetails] = useState([]);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData(searchData.from_date,searchData.to_date);
  }, []);
  function handleTableData(
    from_date = "",
    to_date = ""
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }
    var url =
      `${API_URL}responseReport/source_wise?from_date=`+from_date+"&to_date="+to_date;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;
            let types = item.data;
            item.types = (
              <div>
                <ul >
                  {types.map((element, index) => {
                     exportItem.id = element.index+1;
                     exportItem.Source = item.source;
                     exportItem.Type = element.type;
                     exportItem.Total = element.total_count;
                     exportItem.Closed = element.closed_count;
                     exportItem.Avg_Resolution_Days = element.avg.toFixed(2);
                     dataToBeExported.push(exportItem);
                    return (
                      <li className="mb-2">
                        <span
                          style={{
                            marginLeft: "5px",
                          }}
                        >
                          {element.type}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
            item.total = (
              <div>
                <ul >
                  {types.map((element, index) => {
                    return (
                      <li className="mb-2">
                        <span
                          style={{
                            marginLeft: "5px",
                          }}
                        >
                          {element.total_count}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
            item.closed = (
              <div>
                <ul >
                  {types.map((element, index) => {
                    return (
                      <li className="mb-2">
                        <span>
                          {element.closed_count}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
            item.avg_count = (
              <div>
                <ul >
                  {types.map((element, index) => {
                    return (
                      <li className="mb-2">
                        <span>
                          {element.avg.toFixed(2)}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );

            
          });
        
        setDetails(result);
        //let total = getArraySumByKey(result, "total_amount");
       // setTotal(total);
        setDataToBeExported(dataToBeExported);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
        width: 400,
      },
      {
        label: "Type",
        field: "types",
        sort: "asc",
        width: 400,
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
        width: 200,
      },
      {
        label: "Closed",
        field: "closed",
        sort: "asc",
        width: 200,
      },
      {
        label: "Avg Resolution",
        field: "avg_count",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    //handleTableData(date1,date2)
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    handleTableData(getFirstday(new Date()),getDate(new Date()));
  };
  const handleSearch = () => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableData(date1, date2);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Response Summary - Source Wise" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">From
                      <div className="mb-3">
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">To
                      <div className="mb-3">
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "12px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "12px" }}>
                      <div className="mt-2 mb-3">
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Response_report_source_wise" + getDate(new Date()) + ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "12px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="sourceSummaryTableId"
                    responsive
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={100}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ResponseSummary;
