import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";

import saveAs from "file-saver";
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv";

import IconButton from "@mui/material/IconButton";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PrintIcon from "@mui/icons-material/Print";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { useReactToPrint } from "react-to-print";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./followUp.scss";
import { Stack, Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getDate, getFirstday } from "../../../helpers/globalFunctions";
import $ from "jquery";

const FollowUp = () => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const [allResponseObject, setallResponseObject] = useState({});
  const [allResponseIdTobeUpdated, setallResponseIdToBeUpdated] =
    useState(null);
  const [allResponseIdToBeDeleted, setallResponseIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [allResponseForTable, setallResponseForTable] = useState([]);

  const [allResponseValue, setallResponseValue] = useState("");

  const [popupData, setPopupData] = useState([]);

  const [sourseList, setSourceList] = useState([]);
  const [selectedSourceList, setSelectedSourceList] = useState(null);

  const [typeList, setTypeList] = useState([]);
  const [selectedTypeList, setSelectedTypeList] = useState(null);

  const [subType, setSubType] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState(null);

  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [searchDepartment, setSearchDepartment] = useState([]);
  const [selectedSearchDepartment, setSelectedSearchDepartment] =
    useState(null);

  const [template, settemplate] = useState([]);
  const [selectedtemplate, setSelectedtemplate] = useState(null);

  const [selectedStatus, setSelectedstatus] = useState(null);

  const [selectedInternal, setSelectedInternal] = useState(null);

  const [dataToBeExported, setDataToBeExported] = useState([]);

  const [uploadProgress, setUploadProgress] = useState();

  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [popupTableData, setPopupTableData] = useState([]);

  const {
    districts,
    addingnewResponse,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    fetch_all_Source();

    fetch_all_TypeList();
    fetch_all_SearchDepartment();
    fetch_all_Template();
  }, []);

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  const updateDepartment = (selected, item) => {
    let data = {
      response_id: item?._id,
      department: selected?.value,
    };
    axios
      .post(`${API_URL}response/department`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          // toastr.success("Status updated successfully");
          handleTableListData();
        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };

  function handleTableListData(
    type_id = "",
    sub_type = "",
    status = "",
    department = "",
    from_date = "",
    to_date = "",
    searchdepartmentdata = []
  ) {
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    var url =
      `${API_URL}response/list?type_id=` +
      type_id +
      "&sub_type=" +
      sub_type +
      "&status=" +
      status +
      "&department=" +
      department +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        let responseData = [];

        // var departmentData = [];

        // axios
        //   .get(`${API_URL}department/options`, {
        //     headers: {
        //       "x-access-token": accessToken,
        //     },
        //   })
        //   .then((res) => {
        //     departmentData =
        //       res.data.data &&
        //       res.data.data.map((el) => {
        //         return {
        //           label: el.depart_Name,
        //           value: el._id,
        //         };
        //       });
        //   });

        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;

            item.date = moment(item?.date).format("DD-MM-YYYY");
            item.time = moment(item.time, "HHmmss").format("hh:mm a");

            //for exporting as csv file
            exportItem.Date = item.date;
            exportItem.source_name = item.source_name;
            console.log(item.source_icon);
            if (item.source_icon) {
              item.socialMedia = (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        {item?.source_name}
                      </Tooltip>
                    }
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={`${API_URL}uploads/twitter/${item?.source_icon}`}
                      height="30"
                      width="50"
                    />
                  </OverlayTrigger>
                </>
              );
            } else item.socialMedia = "";
            exportItem.Response_ID = item.Response_ID;
            exportItem.firstName = item.firstName;

            if (item.priority === 0) {
              item.Priority = "Low";
            } else if (item.priority === 1) {
              item.Priority = "Medium";
            } else if (item.priority === 2) {
              item.Priority = "High";
            } else {
              item.Priority = "";
            }

            // exportItem.priority = item.priority;

            //work on department exist or not
            let selectedDepartments = null;
            // if (item.depart_Name === 0) {
            //   selectedDepartments = {
            //     label: "Area Manager",
            //     value: 0,
            //   };
            // } else if (item.depart_Name === 1) {
            //   selectedDepartments = {
            //     label: "Sale",
            //     value: 1,
            //   };
            // } else if (item.depart_Name === 2) {
            //   selectedDepartments = {
            //     label: "XYZGoverment",
            //     value: 2,
            //   };
            // } else if (item.depart_Name === 3) {
            //   selectedDepartments = {
            //     label: "abdcgoverment",
            //     value: 3,
            //   };
            // } else {
            //   selectedDepartments = {
            //     label: "testing",
            //     value: 4,
            //   };
            // }

            if (item?.department_name) {
              item.Department = (
                <>{(exportItem.department_name = item.department_name)}</>
              );
            } else {
              item.Department = (
                <div className="mb-3">
                  <Select
                    styles={style}
                    name="department"
                    value={selectedDepartments}
                    onChange={(selected) => {
                      updateDepartment(selected, item);
                    }}
                    options={searchdepartmentdata}
                    classNamePrefix="select2-selection"
                  />
                </div>
              );
            }

            // exportItem.department_name = item.department_name;

            exportItem.products = item.products;
            exportItem.staff = item.staff;

            if (item.status === 0) {
              item.Status = "New";
            } else if (item.status === 1) {
              item.Status = "Pending";
            } else if (item.status === 2) {
              item.Status = "Closed";
            } else if (item.status === 3) {
              item.Status = "Responded";
            } else {
              item.Status = "";
            }

            // exportItem.status = item.status;

            dataToBeExported.push(exportItem);

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
                {/* <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateFollowUp(item);
                }}
              ></i> */}
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setallResponseIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );

            // item.date = moment(item.designation_date).format("DD-MM-YYYY");
            //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
            if (item?.up_date) {
              item.date = moment(item.up_date).format("DD-MM-YYYY");
            } else {
              item.date = moment(item.date).format("DD-MM-YYYY");
            }

            if (item?.up_time) {
              item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
            } else {
              item.time = moment(item.time, "HHmmss").format("hh:mm a");
            }

            // let values = item?.addedby || {};
            // if (values?.lastName)
            //   item.staff = values.firstName + " " + values.lastName;
            // else item.staff = values?.firstName;

            // if (item?.updatedby) {
            //   let values = item?.updatedby || {};
            //   if (values?.lastName)
            //     item.staff = values.firstName + " " + values.lastName;
            //   else item.staff = values?.firstName;
            // } else {
            //   let values = item?.addedby || {};
            //   if (values?.lastName)
            //     item.staff = values.firstName + " " + values.lastName;
            //   else item.staff = values?.firstName;
            // }

            responseData.push(exportItem);
          });
        setallResponseForTable(result);

        setDataToBeExported(dataToBeExported);
      });
  }

  function fetch_all_SearchDepartment() {
    axios
      .get(`${API_URL}department/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var searchdepartmentdata =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.depart_Name,
              value: el._id,
            };
          });
        setSearchDepartment([{ options: searchdepartmentdata }]);
        let type = "";
        let sub_type = "";
        let status = "";
        let department = "";
        let date1 = "";
        let date2 = "";
        handleTableListData(
          type,
          sub_type,
          status,
          department,
          date1,
          date2,
          searchdepartmentdata
        );
      });
  }

  function fetch_all_Source() {
    axios
      .get(`${API_URL}response/source`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var sourceList_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.source_name,
              value: el._id,
            };
          });
        setSourceList([{ options: sourceList_data }]);
      });
  }

  function fetch_all_TypeList() {
    axios
      .get(`${API_URL}response/type`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var typeList_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.type_name,
              value: el._id,
            };
          });
        setTypeList([{ options: typeList_data }]);
      });
  }

  const fetch_all_SubType = (item) => {
    axios
      .get(`${API_URL}response/sub_type?type_id=` + item.value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var subType_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setSubType([{ options: subType_data }]);
      });
  };

  function fetch_all_Department() {
    axios
      .get(`${API_URL}department/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var department_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.depart_Name,
              value: el._id,
            };
          });
        setDepartment([{ options: department_data }]);
      });
  }

  function fetch_all_Template() {
    axios
      .get(`${API_URL}FAQ/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var template_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.temp_name,
              value: el._id,
            };
          });
        settemplate([{ options: template_data }]);
      });
  }

  let preUpdatePopupData = (followup, responseId) => {
    setallResponseIdToBeUpdated(followup._id);
    setallResponseValue(followup?.name);

    axios
      .get(
        `${API_URL}response/followupsinglelist?response_id=` +
          responseId +
          "&followup_id=" +
          followup?._id,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;

        let followUp = result?.follow_up[0] || {};

        let values = null;
        if (followup?.status === 0) {
          values = {
            label: "Pending",
            value: 0,
          };
        } else if (followup?.status === 1) {
          values = {
            label: "Dept. Change",
            value: 1,
          };
        } else if (followup?.status === 2) {
          values = {
            label: "Closed",
            value: 2,
          };
        } else if (followup?.status === 3) {
          values = {
            label: "Reopen",
            value: 3,
          };
        } else if (followup?.status === 4) {
          values = {
            label: "Responded",
            value: 4,
          };
        } else values = null;

        let Source = followup?.source ? followup.source : {};
        let SourceValue = {
          label: Source?.source_name,
          value: Source?._id,
        };

        let Template = followup?.template ? followup.template : {};
        let TemplateValue = {
          label: Template?.temp_name,
          value: Template?._id,
        };

        let Internal = null;
        if (followUp?.communication_type === 0) {
          Internal = {
            label: "In",
            value: 0,
          };
        } else if (followUp?.communication_type === 1) {
          Internal = {
            label: "Out",
            value: 1,
          };
        } else if (followUp?.communication_type === 2) {
          Internal = {
            label: "Internal",
            value: 2,
          };
        } else Internal = null;

        let language = null;
        if (followUp?.template_lang == 0) {
          language = {
            label: "English",
            value: 0,
          };
        } else if (followup?.template_lang == 1) {
          language = {
            label: "Hindi",
            value: 1,
          };
        } else if (followup?.template_lang == 2) {
          language = {
            label: "Marathi",
            value: 2,
          };
        } else language = null;

        followUp.response_id = responseId;
        followUp.followup_id = followup?._id;
        setSelectedLanguage(language);

        setSelectedtemplate(TemplateValue);
        setSelectedInternal(Internal);

        setSelectedSourceList(SourceValue);
        setSelectedstatus(values);
        setallResponseObject(followUp);
      });
  };

  // ? Toolttip component
  function MyToolTip({ text, children }) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip
            id="tooltip-top"
            style={{
              position: "absolute",
              top: "-25px",
            }}
          >
            {text}
          </Tooltip>
        }
      >
        <>{children}</>
      </OverlayTrigger>
    );
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}response/view?response_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result.date = moment(result.date).format("DD-MM-YYYY");
        result.time = moment(result.time, "HHmmss").format("hh:mm a");

        let Type = result.type || {};
        result.Type = Type?.type_name;

        let Source = result.source || {};
        result.Source = Source?.source_name;

        let AddedBy = result.addedby || {};
        result.AddedBy = AddedBy?.firstName + AddedBy?.lastName;

        let twitter = result.contact_id?.socialDetails || {};
        let dataValue = twitter?.twitter || {};
        result.socialMedia = dataValue?.username ? dataValue.username : "";

        let facebook = result.contact_id?.socialDetails || {};
        let facebookData = facebook?.facebook || {};
        result.socialFacebook = facebookData?.username
          ? facebookData?.username
          : "";

        let instagram = result.contact_id?.socialDetails || {};
        let instagramData = instagram?.instagram || {};
        result.socialInstagram = instagramData?.username
          ? instagramData?.username
          : "";

        let Accountno = result.contact_id?.bankDetails || {};
        result.AccountNo = Accountno?.acctNo ? Accountno?.acctNo : "";

        let Cardno = result.contact_id?.bankDetails || {};
        result.CardNo = Cardno?.cardNo ? Cardno?.cardNo : "";

        let CustId = result.contact_id?.bankDetails || {};
        result.CustID = CustId?.customerId ? CustId?.customerId : "";

        let Loanno = result.contact_id?.bankDetails || {};
        result.LoanNo = Loanno?.loanNo ? Loanno?.loanNo : "";

        let branchData = result.contact_id?.branch || {};
        result.Branch = branchData?.branch_name ? branchData?.branch_name : "";

        let followupData = result.follow_up[0];
        result.comment = followupData?.comments ? followupData.comments : "";

        if (followupData.status === 0) {
          followupData.Status = "New";
        } else if (followupData.status === 1) {
          followupData.Status = "Pending";
        } else if (followupData.status === 2) {
          followupData.Status = "Closed";
        } else if (followupData.status === 3) {
          followupData.Status = "Responded";
        } else {
          followupData.Status = "";
        }
        // result.statuss = followupData?.status ? followupData.status : "";

        result.screenShot = followupData?.screenshot
          ? followupData.screenShot
          : "";

        if (result?.updatedby) {
          let values = result?.updatedby || {};
          if (values?.lastName)
            result.staff = values.firstName + " " + values.lastName;
          else result.staff = values?.firstName;
        } else {
          let values = result?.addedby || {};
          if (values?.lastName)
            result.staff = values.firstName + " " + values.lastName;
          else result.staff = values?.firstName;
        }

        let arrayFirstIndex = [
          {
            date: result?.date,
            time: moment(result?.time, "hhmmss").format("hh:mm a"),
            comment: result?.comment,
            statuss: result?.status,
            Source: result?.Source,
            ScreenShot: result?.screenshot,
            staff: result?.staff,
            action: result?.action,
          },
        ];

        let followupTableData = result?.follow_up;
        let newArray = followupTableData.concat(arrayFirstIndex);

        newArray &&
          newArray.map((el, index) => {
            el.id = index + 1;
            el.date = moment(el?.date).format("DD-MM-YYYY");
            el.time = moment(el?.time, "HHmmss").format("hh:mm a");
            el.comment = el?.comments;

            // el.statuss = el?.status;
            let Source = el.source || {};
            el.Source = Source?.source_name;
            if (el?.updatedby) {
              let values = result?.updatedby || {};
              if (values?.lastName)
                el.staff = values.firstName + " " + values.lastName;
              else el.staff = values?.firstName;
            } else {
              let values = el?.addedby || {};
              if (values?.lastName)
                el.staff = values.firstName + " " + values.lastName;
              else el.staff = values?.firstName;
            }

            if (el?.screenshot) {
              el.ScreenShot = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/response_image/${el.screenshot}`}
                    height="50"
                    width="50"
                  />
                </div>
              );
            } else {
              el.ScreenShot = <></>;
            }

            el.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdatePopupData(el, item?._id);
                  }}
                ></i>
              </div>
            );
          });

        setPopupTableData(newArray);
        setPopupData(result);
        setallResponseObject({
          response_id: item?._id,
        });
        setShow(true);
      });
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "SourceLists":
        setSelectedSourceList(selected);
        setallResponseObject({
          ...allResponseObject,
          source: selected.value,
        });
        break;
      case "typeList":
        setSelectedTypeList(selected);
        setSelectedSubType(null);
        fetch_all_SubType(selected);
        setSearchData({
          ...searchData,
          type_id: selected.value,
          sub_type: "",
        });
        break;
      case "subTypename":
        setSelectedSubType(selected);
        setSearchData({
          ...searchData,
          sub_type: selected.value,
        });
        break;

      case "departments":
        setSelectedDepartment(selected);
        setallResponseObject({
          ...allResponseObject,
          send_to: selected.value,
        });
        break;

      case "templates":
        setSelectedtemplate(selected);
        setallResponseObject({
          ...allResponseObject,
          faq: selected.value,
        });
        break;

      case "language":
        setSelectedLanguage(selected);

        setallResponseObject({
          ...allResponseObject,
          faq_type: selected.value,
        });
        break;

      case "statuss":
        setSelectedstatus(selected);
        setallResponseObject({
          ...allResponseObject,
          status: selected.value,
        });
        break;
      case "internal":
        setSelectedInternal(selected);
        setallResponseObject({
          ...allResponseObject,
          communication_type: selected.value,
        });
        break;

      case "searchdepartments":
        setSelectedSearchDepartment(selected);
        setallResponseObject({
          ...allResponseObject,
          send_to: selected.value,
        });
        break;

      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Source",
        field: "socialMedia",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reference",
        field: "Response_ID",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "firstName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Priority",
        field: "Priority",
        sort: "asc",
        width: 200,
      },

      {
        label: "Department",
        field: "Department",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Products",
      //   field: "Product",
      //   sort: "asc",
      //   width: 200,
      // },

      // {
      //   label: "Staff",
      //   field: "staff",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: allResponseForTable,
  };

  console.log(allResponseForTable);

  const popUpData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 100,
      },
      {
        label: "Comment",
        field: "comment",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Source",
        field: "Source",
        sort: "asc",
        width: 100,
      },
      {
        label: "Screenshot",
        field: "ScreenShot",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: popupTableData,
  };

  const reset = () => {
    formRef.current.reset();
  };
  const handleSearch = () => {
    let type = searchData?.type_id ? searchData.type_id : "";
    let sub_type = searchData?.sub_type ? searchData.sub_type : "";
    let status = searchData?.status ? searchData.status : "";
    let department = searchData?.department ? searchData.department : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableListData(type, sub_type, status, department, date1, date2);
  };

  function handleChangeAllResponse(e) {
    let name = e.target.name;
    let value = e.target.value;
    setallResponseValue(value);
    setallResponseObject({ ...allResponseObject, [name]: value });
  }

  // const downloadOfferletter = (item) => {
  //   saveAs(`${API_URL}` + item?.offerltr_pdf_link, "all_Response.pdf");
  // };

  const handleValidSubmit = () => {
    if (allResponseIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .post(`${API_URL}response/follow_up/update`, allResponseObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Follow Up updated successfully");
            setallResponseValue("");
            formRef.current.reset();
            // handleTableData();
            setallResponseObject({});
            setallResponseIdToBeUpdated(null);

            setTypeList(null);
            setSubType(null);
            setDepartment(null);
            setSourceList(null);
          } else {
            toastr.error("Failed to update Follow Up");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}response/follow_up/add`, allResponseObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Follow Up added Successfully");
            // handleTableData();
            setallResponseValue("");
            formRef.current.reset();
            setallResponseObject({});
            setShow(false);
            setTypeList(null);
            setSubType(null);
            setDepartment(null);
            setSourceList(null);
          } else {
            toastr.error("Failed to add Follow Up");
            return;
          }
        })
        .catch((err) => {
          // toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const uploadScreenshot = (e) => {
    const fd = new FormData();
    fd.append("screenshot", e.target.files[0]);
    axios
      .post(`${API_URL}response/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setallResponseObject({
            ...allResponseObject,
            [`screenshot`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteScreenshot = () => {
    setallResponseObject({
      ...allResponseObject,
      [`screenshot`]: "",
    });
  };

  const customStyles = {
    lineHeight: "2.8",
  };
  const customStyles1 = {
    height: "50px",
    border: "1px solid #ced4da",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}response` + "/" + allResponseIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Response deleted successfully");
                    if (
                      allResponseObject &&
                      allResponseObject._id === allResponseIdToBeDeleted
                    ) {
                      setallResponseValue("");
                      formRef.current.reset();
                      setallResponseObject({});
                      setallResponseIdToBeUpdated(null);
                      // setSelectedSubCat(null);
                    }
                    handleTableListData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Response");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Follow Up" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Stack direction="row" spacing={2}>
                              <Label
                                htmlFor="validationCustom05"
                                style={{ marginTop: "auto" }}
                              >
                                From
                              </Label>
                              <input
                                className="form-control"
                                type="date"
                                id="from_date"
                                name="from_date"
                                value={searchData?.from_date}
                                onChange={handleDate}
                              />
                            </Stack>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Stack direction="row" spacing={2}>
                              <Label
                                htmlFor="validationCustom05"
                                style={{ marginTop: "auto" }}
                              >
                                To
                              </Label>
                              <input
                                className="form-control"
                                type="date"
                                id="to_date"
                                name="to_date"
                                value={searchData?.to_date}
                                min={searchData?.from_date}
                                onChange={handleDate}
                              />
                            </Stack>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Type</Label>
                            <Select
                              name="type"
                              value={selectedTypeList}
                              onChange={(selected) => {
                                handleSelectChange(selected, "typeList");
                              }}
                              options={typeList}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Sub Type</Label>
                            <Select
                              name="Subtype"
                              value={selectedSubType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "subTypename");
                              }}
                              options={subType}
                              classNamePrefix="select2-selection"
                              // onClick={() => handleClick()}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Status</Label>
                            <Select
                              name="status"
                              value={selectedStatus}
                              onChange={(selected) => {
                                handleSelectChange(selected, "statuss");
                              }}
                              options={[
                                {
                                  label: "Pending",
                                  value: 0,
                                },
                                {
                                  label: "Dept. Change",
                                  value: 1,
                                },
                                {
                                  label: "Closed",
                                  value: 2,
                                },
                                {
                                  label: "Reopen",
                                  value: 3,
                                },
                                {
                                  label: "Responded",
                                  value: 4,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Department
                            </Label>
                            <Select
                              name="Department"
                              value={selectedSearchDepartment}
                              onChange={(selected) => {
                                handleSelectChange(
                                  selected,
                                  "searchdepartments"
                                );
                              }}
                              options={searchDepartment}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="1" style={{ paddingTop: "18px" }}>
                          <div className="mt-2 mb-3">
                            <Button
                              color="primary"
                              type="submit"
                              onClick={() => handleSearch()}
                            >
                              Search
                            </Button>
                          </div>
                        </Col>

                        <Col md="1" style={{ paddingTop: "18px" }}>
                          <div className="mt-2 mb-3">
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Col md="12">
                      <div className="mb-12" style={{ textAlign: "right" }}>
                        <IconButton type="submit" size="small">
                          <CSVLink
                            style={{ color: "green" }}
                            data={dataToBeExported}
                            filename={
                              "all_response" + getDate(new Date()) + ".xls"
                            }
                          >
                            <ExitToAppIcon />
                          </CSVLink>
                        </IconButton>

                        <PrintIcon onClick={handlePrint} />

                        {/* <MDBDataTable ref={componentRef} /> */}
                      </div>
                    </Col>
                    <MDBDataTable
                      id="followupTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                      ref={componentRef}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div></div>
      </React.Fragment>
      {/* View Response */}

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        centered={true}
        className="modal-fullscreen"
      >
        <div className="modal-header">
          <h4 className="modal-title mt-0">Details</h4>
          <button
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        {/* <AvForm
          ref={formRef}
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        > */}

        <div className="row modal-body">
          <div className="col-xl-3">
            <div
              className="card"
              style={{
                // width: "fit-content",
                // width: "351px",
                height: "max-content",
                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
              }}
            >
              <div className="card-body" style={{ paddingTop: "0" }}>
                <h4 className="modal-title mt-0">Contact Details</h4>
                <div className="mt-2" hidden={!popupData?.firstName}>
                  <p className="mb-1">Name:</p>
                  <h5 className="font-size-14">{popupData?.firstName}</h5>
                </div>
                <div className="mt-2" hidden={!popupData?.email}>
                  <p className="mb-1">Email:</p>
                  <h5 className="font-size-14">{popupData?.email}</h5>
                </div>
                <div className="mt-2" hidden={!popupData?.mobile}>
                  <p className="mb-1">Mobile:</p>
                  <h5 className="font-size-14">{popupData?.mobile}</h5>
                </div>

                <div className="mt-2" hidden={!popupData?.socialFacebook}>
                  <p className="mb-1">Facebook:</p>
                  <h5 className="font-size-14">{popupData?.socialFacebook}</h5>
                </div>
                <div className="mt-2" hidden={!popupData?.socialMedia}>
                  <p className="mb-1">Twitter:</p>
                  <h5 className="font-size-14">{popupData?.socialMedia}</h5>
                </div>

                <div className="mt-2" hidden={!popupData?.socialInstagram}>
                  <p className="mb-1">Instagram:</p>
                  <h5 className="font-size-14">{popupData?.socialInstagram}</h5>
                </div>

                <div className="mt-2" hidden={!popupData?.AccountNo}>
                  <p className="mb-1">Acc. No:</p>
                  <h5 className="font-size-14">{popupData?.AccountNo}</h5>
                </div>
                <div className="mt-2" hidden={!popupData?.CardNo}>
                  <p className="mb-1">Card No:</p>
                  <h5 className="font-size-14">{popupData?.CardNo}</h5>
                </div>
                <div className="mt-2" hidden={!popupData?.CustID}>
                  <p className="mb-1">Customer ID:</p>
                  <h5 className="font-size-14">{popupData?.CustID}</h5>
                </div>
                <div className="mt-2" hidden={!popupData?.LoanNo}>
                  <p className="mb-1">Loan No:</p>
                  <h5 className="font-size-14">{popupData?.LoanNo}</h5>
                </div>
                <div className="mt-2" hidden={!popupData?.Branch}>
                  <p className="mb-1">Branch:</p>
                  <h5 className="font-size-14">{popupData?.Branch}</h5>
                </div>
                <div className="mt-2" hidden={!popupData?.location}>
                  <p className="mb-1">Locality:</p>
                  <h5 className="font-size-14">{popupData?.location}</h5>
                </div>
              </div>
            </div>
          </div>

          {/* Fields Position */}

          <div className="col-xl-9">
            <div
              className="card"
              style={{
                height: "max-content",
                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
              }}
            >
              <div className="card-body" style={{ paddingTop: "0" }}>
                <Row>
                  <Col xl="12">
                    <CardBody>
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={() => {
                          handleValidSubmit();
                        }}
                      >
                        <Row>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Template Name
                              </Label>
                              <Select
                                name="templates"
                                value={selectedtemplate}
                                onChange={(selected) => {
                                  handleSelectChange(selected, "templates");
                                }}
                                options={template}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Language
                              </Label>
                              <Select
                                name="language"
                                value={selectedLanguage}
                                onChange={(selected) => {
                                  handleSelectChange(selected, "language");
                                }}
                                options={[
                                  {
                                    label: "English",
                                    value: 0,
                                  },
                                  {
                                    label: "Hindi",
                                    value: 1,
                                  },
                                  {
                                    label: "Marathi",
                                    value: 1,
                                  },
                                ]}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Attachments
                              </Label>
                              {allResponseObject?.screenshot ? (
                                <div div className="img-wraps">
                                  {allResponseObject.screenshot &&
                                  allResponseObject.screenshot.startsWith(
                                    "http"
                                  ) ? (
                                    <img
                                      alt=""
                                      width="150"
                                      height="150"
                                      src={`${allResponseObject.screenshot}`}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      width="150"
                                      height="150"
                                      src={`${API_URL}uploads/response_image/${allResponseObject?.screenshot}`}
                                    />
                                  )}
                                  <button
                                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                    onClick={deleteScreenshot}
                                    style={{ width: "150px" }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              ) : (
                                <AvField
                                  name="image"
                                  type="file"
                                  className="form-control"
                                  id="validationCustom03"
                                  onChange={uploadScreenshot}
                                  rows="1"
                                />
                              )}
                            </div>
                          </Col>

                          <Col md="3">
                            <div className="mb-10">
                              <Label htmlFor="validationCustom01">
                                Comments
                              </Label>
                              <AvField
                                name="comments"
                                placeholder="Comments"
                                type="textarea"
                                errorMessage="Enter Comments"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom02"
                                value={allResponseObject?.comments}
                                onChange={handleChangeAllResponse}
                              />
                            </div>
                          </Col>

                          {/* <Col md="3">
                            <div className="mb-10">
                              <Label htmlFor="validationCustom01">
                                Comments
                              </Label>
                              <textarea
                                style={{
                                  height: "auto",
                                  borderColor: "#c0c0c045",
                                }}
                                name="comments"
                                placeholder="Comments"
                                type="number"
                                errorMessage="Enter Comments"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom01"
                                value={allResponseObject?.comments}
                                onChange={handleChangeAllResponse}
                              />
                            </div>
                          </Col> */}
                          <Col>
                            <div
                              className="mb-3 mt-1"
                              style={{ paddingTop: "25px" }}
                            >
                              {allResponseIdTobeUpdated ? (
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={addingnewResponse ? true : false}
                                >
                                  {addingnewResponse ? "Updating" : "Update"}
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={addingnewResponse ? true : false}
                                >
                                  {addingnewResponse ? "Adding" : "Submit"}
                                </Button>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Col>
                </Row>
                <MDBDataTable
                  id="commentTableId"
                  responsive
                  bordered
                  data={popUpData}
                  paging={false}
                  searching={false}
                />
              </div>
            </div>
          </div>

          {/* fields position */}

          {/* Fields */}
        </div>
        {/* </AvForm> */}
      </Modal>

      {/* View Response End */}
    </>
  );
};

export default FollowUp;
