import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

import "./zone.scss";

const Zone = () => {
  const [zoneObject, setZoneObject] = useState({});
  const [zoneIdTobeUpdated, setZoneIdToBeUpdated] = useState(null);
  const [zoneIdToBeDeleted, setZoneIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [zoneForTable, setZoneForTable] = useState([]);
  const [zoneValue, setZoneValue] = useState("");

  const {
    districts,
    addingZone,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function handleTableData() {
    var url = `${API_URL}zone/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let departmentData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateZone(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setZoneIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.itemsup_date)
            item.date = moment(item.itemsup_date).format("DD-MM-YYYY");
          else item.date = moment(item.items_date).format("DD-MM-YYYY");

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }
          let desi_value = item?.desig_Head || {};
          item.head = desi_value.desig_name;
          // if (item?.time)
          //   item.time = moment(item.time, "HHmmss").format("hh:mm a");
          // else item.time = "";

          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          departmentData.push(item);
        });
        setZoneForTable(departmentData);
      });
  }

  let preUpdateZone = (item) => {
    setZoneIdToBeUpdated(item._id);
    setZoneValue(item?.name);

    setZoneObject(item);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "zone_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "ZM",
        field: "Z_M_email",
        sort: "asc",
        width: 150,
      },
      {
        label: "DZM",
        field: "D_Z_M_email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Planning",
        field: "planning_email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Marketing",
        field: "marketing_email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "zone_code",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: zoneForTable,
  };

  function handleChangeZone(e) {
    let name = e.target.name;
    let value = e.target.value;
    setZoneValue(value);
    setZoneObject({ ...zoneObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (zoneIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}zone`, zoneObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Zone updated successfully");
            setZoneValue("");
            formRef.current.reset();
            handleTableData();
            setZoneObject({});
            setZoneIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update Zone");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}zone`, zoneObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Zone added Successfully");
            handleTableData();
            setZoneValue("");
            formRef.current.reset();
            setZoneObject({});
          } else {
            toastr.error("Failed to add Zone");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const reset = () => {
    formRef.current.reset();
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}zone` + "/" + zoneIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Zone deleted successfully");
                  if (zoneObject && zoneObject._id === zoneIdToBeDeleted) {
                    setZoneValue("");
                    formRef.current.reset();
                    setZoneObject({});
                    setZoneIdToBeUpdated(null);
                  }
                  setZoneIdToBeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Zone");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Zone" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Name</Label>

                          <AvField
                            style={customStyles}
                            name="zone_name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={zoneObject?.zone_name}
                            onChange={handleChangeZone}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Z.M. Email</Label>

                          <AvField
                            style={customStyles}
                            name="Z_M_email"
                            placeholder="Z.M. Email"
                            type="email"
                            errorMessage="Enter Z.M. Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={zoneObject?.Z_M_email}
                            onChange={handleChangeZone}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            D.Z.M. Email
                          </Label>

                          <AvField
                            style={customStyles}
                            name="D_Z_M_email"
                            placeholder="D.Z.M. Email"
                            type="email"
                            errorMessage="Enter D.Z.M. Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={zoneObject?.D_Z_M_email}
                            onChange={handleChangeZone}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Planning Email
                          </Label>

                          <AvField
                            style={customStyles}
                            name="planning_email"
                            placeholder="Planning Email"
                            type="email"
                            errorMessage="Enter Planning Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={zoneObject?.planning_email}
                            onChange={handleChangeZone}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Marketing Email
                          </Label>

                          <AvField
                            style={customStyles}
                            name="marketing_email"
                            placeholder="Marketing Email"
                            type="email"
                            errorMessage="Enter Marketing Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={zoneObject?.marketing_email}
                            onChange={handleChangeZone}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Zone Code</Label>

                          <AvField
                            style={customStyles}
                            name="zone_code"
                            placeholder="Zone Code"
                            type="number"
                            errorMessage="Enter Zone Code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={zoneObject?.zone_code}
                            onChange={handleChangeZone}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          {zoneIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingZone ? true : false}
                            >
                              {addingZone ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingZone ? true : false}
                            >
                              {addingZone ? "Adding" : "Submit"}
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="zoneTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Zone;
