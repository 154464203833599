import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
// import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
// import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import "./Social_Config.scss";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";

const SocialConfiguration = (props) => {
  const [twitterObject, setTwitterObject] = useState({});
  const [facebookObject, setFacebookObject] = useState({});
  const [instagramObject, setInstagramObject] = useState({});
  const [linkedInObject, setLinkedInObject] = useState({});
  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});
  const [twitterTable, setTwitterTable] = useState([]);
  const [facebookTable, setFacebookTable] = useState([]);
  const [instaTable, setInstaTable] = useState([]);
  const [linkedTable, setLinkedTable] = useState([]);
  const [googleTable, setGoogleTable] = useState([]);
  const formTwitterRef = useRef();
  const formFacebookRef = useRef();
  const formInstagramRef = useRef();
  const formLinkedInRef = useRef();
  const formGoogleAnalyticsRef = useRef();

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    handleTwitterData();
    handleFacebookData();
    handleInstaData();
    handleLinkedData();
    handleGoogleData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}accountConfig/social/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((element, index) => {
          if (element?.settingName === "Twitter") {
            if (element?._id) {
              let data = element?.setting ? element.setting : {};
              data._id = element?._id ? element._id : "";
              //setTwitterObject(data);
            } else setTwitterObject({});
          }
          if (element?.settingName === "Facebook") {
            if (element?._id) {
              let data = element?.setting ? element.setting : {};
              data._id = element?._id ? element._id : "";
              //setFacebookObject(data);
            } else setFacebookObject({});
          }
          if (element?.settingName === "Instagram") {
            if (element?._id) {
              let data = element?.setting ? element.setting : {};
              data._id = element?._id ? element._id : "";
              //setInstagramObject(data);
            } else setInstagramObject({});
          }
          if (element?.settingName === "Linkedin") {
            if (element?._id) {
              let data = element?.setting ? element.setting : {};
              data._id = element?._id ? element._id : "";
              //setLinkedInObject(data);
            } else setLinkedInObject({});
          }
          if (element?.settingName === "googleAnalytics") {
            if (element?._id) {
              let data = element?.setting ? element.setting : {};
              data._id = element?._id ? element._id : "";
              //setGoogleAnalyticsObject(data);
            } else setGoogleAnalyticsObject({});
          }
        });
      });
  }
  function  handleTwitterData(){
    var url = `${API_URL}accountConfig/twitter/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let twitterData = [];

        result.map((item, index) => {
          
           item.id = index + 1;
             if(item.status==1)
             {
              var badge_color="bg-danger";
              var label="Inactive";
             }
             else
             {
              var badge_color="bg-success";
              var label="Active";
             }
             item.status=(
              <span class={"badge "+badge_color+" font-size-12 ms-2"}>{label}</span>
             )
             if(typeof item.addedby!="undefined")
             {
              item.name = item.addedby.firstName+" "+item.addedby.lastName;
             }
           twitterData.push(item);
        });
        setTwitterTable(twitterData);
      });
  }
  const twitterData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Added By",
        field: "name",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
    ],
    rows: twitterTable,
  };
  function  handleFacebookData(){
    var url = `${API_URL}accountConfig/facebook/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let facbookData = [];

        result.map((item, index) => {
          
           item.id = index + 1;
             if(item.status==1)
             {
              var badge_color="bg-danger";
              var label="Inactive";
             }
             else
             {
              var badge_color="bg-success";
              var label="Active";
             }
             item.status=(
              <span class={"badge "+badge_color+" font-size-12 ms-2"}>{label}</span>
             )
             if(typeof item.addedby!="undefined")
             {
              item.name = item.addedby.firstName+" "+item.addedby.lastName;
             }
             facbookData.push(item);
        });
        setFacebookTable(facbookData);
      });
  }
  const facbookData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Added By",
        field: "name",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
    ],
    rows: facebookTable,
  };
  function  handleInstaData(){
    var url = `${API_URL}accountConfig/Instagram/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let instaData = [];

        result.map((item, index) => {
          
           item.id = index + 1;
             if(item.status==1)
             {
              var badge_color="bg-danger";
              var label="Inactive";
             }
             else
             {
              var badge_color="bg-success";
              var label="Active";
             }
             item.status=(
              <span class={"badge "+badge_color+" font-size-12 ms-2"}>{label}</span>
             )
             if(typeof item.addedby!="undefined")
             {
              item.name = item.addedby.firstName+" "+item.addedby.lastName;
             }
             instaData.push(item);
        });
        setInstaTable(instaData);
      });
  }
  const instaData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Added By",
        field: "name",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
    ],
    rows: instaTable,
  };
  function  handleLinkedData(){
    var url = `${API_URL}accountConfig/Linkedin/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let linkedData = [];

        result.map((item, index) => {
          
           item.id = index + 1;
             if(item.status==1)
             {
              var badge_color="bg-danger";
              var label="Inactive";
             }
             else
             {
              var badge_color="bg-success";
              var label="Active";
             }
             item.status=(
              <span class={"badge "+badge_color+" font-size-12 ms-2"}>{label}</span>
             )
             if(typeof item.addedby!="undefined")
             {
              item.name = item.addedby.firstName+" "+item.addedby.lastName;
             }
             linkedData.push(item);
        });
        setLinkedTable(linkedData);
      });
  }
  const linkedData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Added By",
        field: "name",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
    ],
    rows: linkedTable,
  };
  function  handleGoogleData(){
    var url = `${API_URL}accountConfig/googleAnalytics/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let googleData = [];

        result.map((item, index) => {
          
           item.id = index + 1;
             if(item.status==1)
             {
              var badge_color="bg-danger";
              var label="Inactive";
             }
             else
             {
              var badge_color="bg-success";
              var label="Active";
             }
             item.status=(
              <span class={"badge "+badge_color+" font-size-12 ms-2"}>{label}</span>
             )
             if(typeof item.addedby!="undefined")
             {
              item.name = item.addedby.firstName+" "+item.addedby.lastName;
             }
             googleData.push(item);
        });
        setGoogleTable(googleData);
      });
  }
  const googleData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Added By",
        field: "name",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
    ],
    rows: googleTable,
  };



  const handleTwitterInput = (e) => {
    setTwitterObject({
      ...twitterObject,
      [e.target.name]: e.target.value,
    });
  };
  const handleFacebookInput = (e) => {
    setFacebookObject({
      ...facebookObject,
      [e.target.name]: e.target.value,
    });
  };
  const handleInstagramInput = (e) => {
    setInstagramObject({
      ...instagramObject,
      [e.target.name]: e.target.value,
    });
  };
  const handleLinkedInInput = (e) => {
    setLinkedInObject({
      ...linkedInObject,
      [e.target.name]: e.target.value,
    });
  };
  const handleGoogleAnalyticsInput = (e) => {
    setGoogleAnalyticsObject({
      ...googleAnalyticsObject,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitTwitterConfig = () => {
    if (twitterObject?._id) {
      axios
        .put(`${API_URL}accountConfig/twitter`, twitterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Twitter settings updated successfully");
            setTwitterObject({});
            formTwitterRef.current.reset();
            handleTableData();
            handleTwitterData();
          } else {
            toastr.error("Failed to update twitter settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}accountConfig/twitter`, twitterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Twitter settings created successfully");
            setTwitterObject({});
            formTwitterRef.current.reset();
            handleTableData();
            handleTwitterData();
          } else {
            toastr.error("Failed to create twitter settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const handleSubmitFacebookConfig = () => {
    if (facebookObject?._id) {
      axios
        .put(`${API_URL}accountConfig/facebook`, facebookObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Facebook settings updated successfully");
            setFacebookObject({});
            formFacebookRef.current.reset();
            handleTableData();
            handleFacebookData();
          } else {
            toastr.error("Failed to update facebook settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}accountConfig/facebook`, facebookObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Facebook settings created successfully");
            setFacebookObject({});
            formFacebookRef.current.reset();
            handleTableData();
            handleFacebookData();
          } else {
            toastr.error("Failed to create facebook settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const handleSubmitInstagramConfig = () => {
    if (instagramObject?._id) {
      axios
        .put(`${API_URL}accountConfig/Instagram`, instagramObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Instagram settings updated successfully");
            setInstagramObject({});
            formInstagramRef.current.reset();
            handleTableData();
            handleInstaData();
          } else {
            toastr.error("Failed to update instagram settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}accountConfig/Instagram`, instagramObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Instagram settings created successfully");
            setInstagramObject({});
            formInstagramRef.current.reset();
            handleTableData();
            handleInstaData();
          } else {
            toastr.error("Failed to create instagram settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const handleSubmitLinkedInConfig = () => {
    if (linkedInObject?._id) {
      axios
        .put(`${API_URL}accountConfig/Linkedin`, linkedInObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("LinkedIn settings updated successfully");
            setLinkedInObject({});
            formLinkedInRef.current.reset();
            handleTableData();
            handleLinkedData();
          } else {
            toastr.error("Failed to update linkedin settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}accountConfig/Linkedin`, linkedInObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("LinkedIn settings created successfully");
            setLinkedInObject({});
            formLinkedInRef.current.reset();
            handleTableData();
            handleLinkedData();
          } else {
            toastr.error("Failed to create linkedin settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const handleSubmitGoogleAnalyticsConfig = () => {
    if (googleAnalyticsObject?._id) {
      axios
        .put(`${API_URL}accountConfig/googleAnalytics`, googleAnalyticsObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Google analytics settings updated successfully");
            setGoogleAnalyticsObject({});
            formGoogleAnalyticsRef.current.reset();
            handleTableData();
            handleGoogleData();
          } else {
            toastr.error("Failed to update google analytics settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(
          `${API_URL}accountConfig/googleAnalytics`,
          googleAnalyticsObject,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Google analytics settings created successfully");
            setGoogleAnalyticsObject({});
            formGoogleAnalyticsRef.current.reset();
            handleTableData();
            handleGoogleData();
          } else {
            toastr.error("Failed to create google analytics settings");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Social Configuration" />
          {/* Twitter Screen */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formTwitterRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmitTwitterConfig();
                    }}
                  >
                    <Row>
                      <b style={{ padding: "5px" }}>
                        <i class="uil-twitter" style={{ padding:"5px",fontSize:"16px"}}></i> 
                        Twitter Settings
                      </b>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Consumer Key
                          </Label>

                          <AvField
                            name="consumerKey"
                            value={twitterObject?.consumerKey}
                            placeholder="Consumer Key"
                            type="text"
                            errorMessage="Enter Consumer Key"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom01"
                            onChange={handleTwitterInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Consumer Secret
                          </Label>

                          <AvField
                            name="consumerSecret"
                            value={twitterObject?.consumerSecret}
                            placeholder="Consumer Secret"
                            type="text"
                            errorMessage="Enter Consumer Secret"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleTwitterInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Token</Label>

                          <AvField
                            name="token"
                            value={twitterObject?.token}
                            placeholder="Token"
                            type="text"
                            errorMessage="Enter Token"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleTwitterInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Token Secret
                          </Label>

                          <AvField
                            name="tokenSecret"
                            value={twitterObject?.tokenSecret}
                            placeholder="Token Secret"
                            type="text"
                            errorMessage="Enter Token Secret"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleTwitterInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Username</Label>

                          <AvField
                            name="userName"
                            value={twitterObject?.userName}
                            placeholder="Username"
                            type="text"
                            errorMessage="Enter Username"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleTwitterInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">App</Label>

                          <AvField
                            name="app"
                            value={twitterObject?.app}
                            placeholder="App"
                            type="text"
                            errorMessage="Enter App"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleTwitterInput}
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {twitterObject?._id ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                      {/* <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col> */}
                      <MDBDataTable
                    id="twitterConfigTb"
                    responsive
                    bordered
                    data={twitterData}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                    </Row>
                   
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Facebook screen */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formFacebookRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmitFacebookConfig();
                    }}
                  >
                    <Row>
                      {/* <Label style={{ padding: "5px" }}>
                        <FacebookIcon />
                        Facebook Settings
                      </Label> */}
                      <b style={{ padding: "5px" }}>
                        <i class="uil-facebook" style={{ padding:"5px",fontSize:"16px"}}></i> 
                        Facebook Settings
                      </b>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">App ID</Label>

                          <AvField
                            name="appId"
                            value={facebookObject?.appId}
                            placeholder="App ID"
                            type="text"
                            errorMessage="Enter App ID"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom01"
                            onChange={handleFacebookInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">App Secret</Label>

                          <AvField
                            name="appSecret"
                            value={facebookObject?.appSecret}
                            placeholder="App Secret"
                            type="text"
                            errorMessage="Enter App Secret"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleFacebookInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Token</Label>

                          <AvField
                            name="token"
                            value={facebookObject?.token}
                            placeholder="Token"
                            type="text"
                            errorMessage="Enter Token"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleFacebookInput}
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {facebookObject?._id ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                      {/* <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="fbConfigTb"
                    responsive
                    bordered
                    data={facbookData}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
              
            </Col>
            
          </Row>
          {/* Instagram Settings */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formInstagramRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmitInstagramConfig();
                    }}
                  >
                    <Row>
                      {/* <Label style={{ padding: "5px" }}>
                        <InstagramIcon />
                        Instagram Settings
                      </Label> */}
                      <b style={{ padding: "5px" }}>
                        <i class="uil-instagram" style={{ padding:"5px",fontSize:"16px"}}></i> 
                        Instagram Settings
                      </b>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Username</Label>

                          <AvField
                            name="userName"
                            value={instagramObject?.userName}
                            placeholder="Username"
                            type="text"
                            errorMessage="Enter Username"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom01"
                            onChange={handleInstagramInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Password</Label>

                          <AvField
                            name="password"
                            value={instagramObject?.password}
                            placeholder="Password"
                            type="text"
                            errorMessage="Enter Password"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleInstagramInput}
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {instagramObject?._id ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="fbConfigTb"
                    responsive
                    bordered
                    data={instaData}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* LinkedIn Screen */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formLinkedInRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmitLinkedInConfig();
                    }}
                  >
                    <Row>
                      {/* <Label style={{ padding: "5px" }}>
                        <LinkedInIcon />
                        LinkedIn Settings
                      </Label> */}
                       <b style={{ padding: "5px" }}>
                        <i class="uil-linkedin" style={{ padding:"5px",fontSize:"16px"}}></i> 
                        LinkedIn Settings
                      </b>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Client ID</Label>

                          <AvField
                            name="clientId"
                            value={linkedInObject?.clientId}
                            placeholder="Client ID"
                            type="text"
                            errorMessage="Enter Client ID"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom01"
                            onChange={handleLinkedInInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Client Secret
                          </Label>

                          <AvField
                            name="clientSecret"
                            value={linkedInObject?.clientSecret}
                            placeholder="Client Secret"
                            type="text"
                            errorMessage="Enter Client Secret"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleLinkedInInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Access Token
                          </Label>

                          <AvField
                            name="accessToken"
                            value={linkedInObject?.accessToken}
                            placeholder="Access Token"
                            type="text"
                            errorMessage="Enter Access Token"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleLinkedInInput}
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {linkedInObject?._id ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                      {/* <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="fbConfigTb"
                    responsive
                    bordered
                    data={linkedData}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Google Analytics */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formGoogleAnalyticsRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmitGoogleAnalyticsConfig();
                    }}
                  >
                    <Row>
                      {/* <Label style={{ padding: "5px" }}>
                        <GoogleIcon />
                        Google Analytics
                      </Label> */}
                      <b style={{ padding: "5px" }}>
                        <i class="uil-google" style={{ padding:"5px",fontSize:"16px"}}></i> 
                        Google Analytics
                      </b>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Client ID</Label>

                          <AvField
                            name="clientId"
                            value={googleAnalyticsObject?.clientId}
                            placeholder="Client ID"
                            type="text"
                            errorMessage="Enter Client ID"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom01"
                            onChange={handleGoogleAnalyticsInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">View ID</Label>

                          <AvField
                            name="viewId"
                            value={googleAnalyticsObject?.viewId}
                            placeholder="View ID"
                            type="text"
                            errorMessage="Enter View ID"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleGoogleAnalyticsInput}
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {googleAnalyticsObject?._id ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="fbConfigTb"
                    responsive
                    bordered
                    data={googleData}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SocialConfiguration;