import {
  GET_MENTIONS,
  GET_MENTIONS_FAIL,
  GET_MENTIONS_SUCCESS,
  GET_MENTIONS_SOCKET,
  GET_MENTIONS_SOCKET_SUCCESS,
  GET_MENTIONS_SOCKET_FAIL
} from './actionTypes';

const INIT_STATE = {
  mentions: [],
  mention: {},
  error: {},
};

const mentions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MENTIONS:
      return {
        ...state,
        params: action.payload,
      };

    case GET_MENTIONS_SUCCESS:
      return {
        ...state,
        mentions: action.payload.data,
      };

    case GET_MENTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default mentions;
