import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Bar } from "react-chartjs-2";


function VolumeReport() {

    // date
    const formatDate = (date, fullMonth = false) => {
        const options = fullMonth
            ? { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
            : { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        return new Date(date).toLocaleString('en-US', options);
    };

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showFullMonth, setShowFullMonth] = useState(false);

    const toggleShowFullMonth = () => {
        setShowFullMonth(!showFullMonth);
    };

    // bar chart1
    const data1 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [3300, 1925, 13478, 2151, 2096, 2388]
            }
        ]
    }

    const option1 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 14000,
                        stepSize: 2000
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }


    // bar chart2
    const data2 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [2790, 1382, 12887, 1433, 1335, 920]
            }
        ]
    }

    const option2 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 14000,
                        stepSize: 2000
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }


    // bar chart3
    const data3 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [510, 543, 591, 639, 540, 998]
            }
        ]
    }

    const option3 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 1000,
                        stepSize: 200
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    // bar chart4
    const data4 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [4, 4, 4, 4, 111, 376]
            }
        ]
    }

    const option4 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 400,
                        stepSize: 100
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Followers Count',
                    },
                },
            ],
        }
    }

    // bar chart5
    const data5 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [0.01, 0.01, 0.01, 0.01, 0.01, 0.01]
            }
        ]
    }

    const option5 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 1,
                        stepSize: 0.5
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    // bar chart6
    const data6 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [1, 1, 1, 79, 104, 67]
            }
        ]
    }

    const option6 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 120,
                        stepSize: 20
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    // bar chart7
    const data7 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [0.5, 0.5, 0.5, 0.5, 4, 27]
            }
        ]
    }

    const option7 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 30,
                        stepSize: 5
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    // bar chart8
    const data8 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [0.01, 0.01, 0.01, 0.01, 0.01, 0.01]
            }
        ]
    }

    const option8 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 0.5,
                        max: 1,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    // bar chart9
    const data9 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [0.01, 0.01, 0.01, 0.01, 0.01, 0.01]
            }
        ]
    }

    const option9 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 0.5,
                        max: 1,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    // bar chart10
    const data10 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [0.01, 0.01, 0.01, 0.01, 0.01, 0.01]
            }
        ]
    }

    const option10 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 0.5,
                        max: 1,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    // bar chart11
    const data11 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [0.01, 0.01, 0.01, 0.01, 0.01, 0.01]
            }
        ]
    }

    const option11 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 0.5,
                        max: 1,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    // bar chart12
    const data12 = {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [0.01, 0.01, 0.01, 0.01, 0.01, 0.01]
            }
        ]
    }

    const option12 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        stepSize: 0.5,
                        max: 1,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div>
                        <p className="titletit">Monthly Volume Report</p>
                        <h6 className="dateText" onClick={toggleShowFullMonth}>
                            {formatDate(startDate, showFullMonth)} - {formatDate(endDate, showFullMonth)}

                        </h6>
                    </div>

                    <Row style={{ rowGap: "20px" }}>

                        {/* bar chart1 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Total Aggregate Monthly Volume</h5>
                                    <Bar width={474} height={200} data={data1} options={option1} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart2 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Twitter Volume</h5>
                                    <Bar width={474} height={200} data={data2} options={option2} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart3 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Facebook Volume</h5>
                                    <Bar width={474} height={200} data={data3} options={option3} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart4 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Instagram Volume</h5>
                                    <Bar width={474} height={200} data={data4} options={option4} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart5 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Pinterest Volume</h5>
                                    <Bar width={474} height={200} data={data5} options={option5} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart6 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>LinkedIn Volume</h5>
                                    <Bar width={474} height={200} data={data6} options={option6} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart7 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Youtube Volume</h5>
                                    <Bar width={474} height={200} data={data7} options={option7} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart8 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Emails Volume</h5>
                                    <Bar width={474} height={200} data={data8} options={option8} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart9 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Blogs Volume</h5>
                                    <Bar width={474} height={200} data={data9} options={option9} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart10 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Facebookkeyword Volume</h5>
                                    <Bar width={474} height={200} data={data10} options={option10} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart11 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Forums Volume</h5>
                                    <Bar width={474} height={200} data={data11} options={option11} />
                                </CardBody>
                            </Card>
                        </Col>

                        {/* bar chart12 */}
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>News Volume</h5>
                                    <Bar width={474} height={200} data={data12} options={option12} />
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </>
    )

}

export default VolumeReport;