import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label, Collapse } from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
const Connections = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const myComponent = {
    width: "100%",
    height: "29rem",
    overflowX: "hidden",
    overflowY: "scroll",
    padding: "10px",
    backgroundColor: "white",
  };
  const showCardDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Connections" />

        <Row>
          <Col xl="12">
            <div>
              <div className="row">
                <div className="col-xl-3">
                  <div
                    className="card"
                    style={{
                      // width: "fit-content",
                      // width: "351px",
                      //   height: "max-content",
                      boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                    }}
                  >
                    <div className="card-body">
                      <div style={myComponent}>
                        <Card
                          sx={{ minWidth: 156 }}
                          className="cardHover"
                          onClick={() => showCardDetails()}
                        >
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {"Jamsheer K P"}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {""}
                            </Typography>

                            <Typography variant="body2">
                              {" "}
                              <button className="button_class">
                                {"1"}
                                <i
                                  className="fab fa-weixin"
                                  style={{ color: "white" }}
                                ></i>
                              </button>
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                  <div
                    className="mb-0 card"
                    style={{
                      boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      width: "100%",
                    }}
                  >
                    <div className="card-body" hidden={!showDetails}>
                      <div className="profileCard">
                        <h2 style={{ padding: "30px", marginBottom: "0" }}>
                          Jamsheer K P
                        </h2>
                        <Row className="mb-3">
                          <Col xl="12">
                            <Row>
                              <Col sm={6}>
                                <p className="mb-1">
                                  Twitter :{" "}
                                  <span
                                    className="font-size-14"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {"@JamsheerKP"}
                                  </span>
                                </p>
                              </Col>
                              <Col sm={6}>
                                <p className="mb-1">
                                  Interactions :{" "}
                                  <span
                                    className="font-size-14"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {1}
                                  </span>
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <div
                        className="card-body"
                        hidden={!showDetails}
                        style={{ paddingLeft: "0", paddingRight: "0" }}
                      >
                        <Row className="mt-4">
                          <Col xl="12">
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  Sir I am not received refund amount in my bank
                                  account.I already mail but not yet reply
                                  Please view and confirm me.
                                </Accordion.Header>
                                <Accordion.Body>
                                  No followups yet
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Connections;
