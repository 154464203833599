import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  ADD_ACCDETAIL,
  DELETE_ACCDETAIL,
  UPDATE_ACCDETAIL,
  GET_ACCDETAILS,
  GET_ACCDETAIL_OPTIONS
} from './actionTypes';

import {
  getAllAccountDetailsSuccess,
  getAllAccountDetailsFail,
  getAllAccountDetailsuccess,
  addAccountDetailFail,
  addAccountDetailSuccess,
  updateAccountDetailFail,
  updateAccountDetailSuccess,
  deleteAccountDetailFail,
  deleteAccountDetailSuccess,
  accountDetailOptionsSuccess,
  accountDetailOptionsFail
} from './actions';

//Include Both Helper File with needed methods
import {
  getAllAccountDetails,
  addAccountDetail,
  updateAccountDetail,
  deleteAccountDetail
} from '../../helpers/backend_helper';

function* fetchAccdetail({localbody_id: localbody_id}) {
  try {
    const response = yield call(getAllAccountDetails,localbody_id);
    yield put(getAllAccountDetailsSuccess(response));
  } catch (error) {
    yield put(getAllAccountDetailsFail(error));
  }
}

function* onaddAccountDetail({ payload: accdetail }) {
  try {
    const response = yield call(addAccountDetail, accdetail);
    yield put(addAccountDetailSuccess(response));
  } catch (error) {
    yield put(addAccountDetailFail(error.response));
  }
}

function* onupdateAccountDetail({ payload: accdetail }) {
  try {
    const response = yield call(updateAccountDetail, accdetail);
    yield put(updateAccountDetailSuccess(response));
  } catch (error) {
    yield put(updateAccountDetailFail(error.response));
  }
}

function* ondeleteAccountDetail({ payload: accdetailId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteAccountDetail, accdetailId, auth_user);
    yield put(deleteAccountDetailSuccess(response));
  } catch (error) {
    yield put(deleteAccountDetailFail(error.response));
  }
}

function* accdetailSaga() {
  yield takeEvery(GET_ACCDETAILS, fetchAccdetail);
  yield takeEvery(ADD_ACCDETAIL, onaddAccountDetail);
  yield takeEvery(UPDATE_ACCDETAIL, onupdateAccountDetail);
  yield takeEvery(DELETE_ACCDETAIL, ondeleteAccountDetail);
}

export default accdetailSaga;
