import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./user.scss";
import Modal from "react-bootstrap/Modal";
// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

const AddManageUser = (props) => {
  const [userObject, setUserObject] = useState({});
  const [details, setDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(null);
  const [userIdToBeUpdated, setUserIdToBeUpdated] = useState(null);
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [selectedDepartmentSearch, setSelectedDepartmentSearch] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  let history = useHistory();

  useEffect(() => {
    fetch_company();
    fetch_privilege();
    fetch_designation();
    fetch_department();
    // fetch_product();
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleTableData = (department = "") => {
    axios
      .get(`${API_URL}user/list?department_id=` + department, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((element, index) => {
            element.id = index + 1;

            if (element?.lastName)
              element.empName = element?.firstName + " " + element.lastName;
            else element.empName = element?.firstName;

            element.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-key"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    setShowModal(true);
                    setUserIdToBeUpdated(element._id);
                  }}
                ></i>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    history.push("/add_user/" + element.userId);
                  }}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    preUpdateUser(element);
                    toTop();
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setUserIdToBeDeleted(element._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const preUpdateUser = (user) => {
    setUserIdToBeUpdated(user?._id);
    axios
      .get(`${API_URL}user/single?user_id=` + user?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let companyData = result?.company || {};
        let privilegeData = result?.privilage || {};
        let moduleData = result?.module || {};
        let departmentData = result?.department || {};
        let productData = result?.product || {};
        let designationData = result?.user_desig || {};
        if (companyData?.company_name) {
          setSelectedCompany({
            label: companyData?.company_name,
            value: companyData?._id,
          });
        } else setSelectedCompany(null);

        if (privilegeData?.privilege_name) {
          setSelectedPrivilege({
            label: privilegeData?.privilege_name,
            value: privilegeData?._id,
          });
          fetch_modules(privilegeData?._id);
        } else setSelectedPrivilege(null);

        if (moduleData?.moduleName) {
          setSelectedModule({
            label: moduleData?.moduleName,
            value: moduleData?._id,
          });
        } else setSelectedModule(null);

        if (departmentData?.depart_Name) {
          setSelectedDepartment({
            label: departmentData?.depart_Name,
            value: departmentData?._id,
          });
        } else setSelectedDepartment(null);

        if (productData?.product_name) {
          setSelectedProduct({
            label: productData?.product_name,
            value: productData?._id,
          });
        } else setSelectedProduct(null);

        if (designationData?.desig_name) {
          setSelectedDesignation({
            label: designationData?.desig_name,
            value: designationData?._id,
          });
        } else setSelectedDesignation(null);

        result.user_id = result?._id;
        setUserObject(result);
      });
  };
  const fetch_company = () => {
    axios
      .get(`${API_URL}company/list/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let company_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.company_name,
              value: el?._id,
            };
          });
        setCompanyOptions([
          {
            options: company_data,
          },
        ]);
      });
  };
  const fetch_privilege = () => {
    axios
      .get(`${API_URL}privilage/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let privilege_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.privilege_name,
              value: el?._id,
            };
          });
        setPrivilegeOptions([
          {
            options: privilege_data,
          },
        ]);
      });
  };
  const fetch_modules = (id) => {
    axios
      .get(`${API_URL}privilage/module_options?privilage_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let data = res.data.data[0]?.alloted_modules;
        let module_data =
          data &&
          data.map((el) => {
            return {
              label: el?.moduleName,
              value: el?._id,
            };
          });
        setModuleOptions([
          {
            options: module_data,
          },
        ]);
      });
  };
  const fetch_designation = () => {
    axios
      .get(`${API_URL}desig/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let designation_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.desig_name,
              value: el?._id,
            };
          });
        setDesignationOptions([
          {
            options: designation_data,
          },
        ]);
      });
  };
  const fetch_department = () => {
    axios
      .get(`${API_URL}department/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let department_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.depart_Name,
              value: el?._id,
            };
          });
        setDepartmentOptions([
          {
            options: department_data,
          },
        ]);
      });
  };
  // const fetch_product = () => {
  //   axios
  //     .get(`${API_URL}product/options`, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       let product_data =
  //         res.data.data &&
  //         res.data.data.map((el) => {
  //           return {
  //             label: el?.product_name,
  //             value: el?._id,
  //           };
  //         });
  //       setProductOptions([
  //         {
  //           options: product_data,
  //         },
  //       ]);
  //     });
  // };

  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
    setPasswordObject({
      password: "",
      confirmPassword: "",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "empName",
        sort: "asc",
        width: 150,
      },

      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Privilege",
        field: "privilege_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company",
        field: "company_name",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Module",
      //   field: "module_name",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Department",
        field: "department_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: details,
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  const handleInputChange = (e) => {
    setUserObject({
      ...userObject,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "company":
        setSelectedCompany(selected);
        setUserObject({
          ...userObject,
          company: selected.value,
        });
        break;
      case "privilege":
        setSelectedPrivilege(selected);
        setSelectedModule(null);
        setUserObject({
          ...userObject,
          privilage: selected.value,
          module: "",
        });
        fetch_modules(selected?.value);
        break;
      case "module":
        setSelectedModule(selected);
        setUserObject({
          ...userObject,
          module: selected.value,
        });
        break;
      case "designation":
        setSelectedDesignation(selected);
        setUserObject({
          ...userObject,
          user_desig: selected.value,
        });
        break;
      case "department":
        setSelectedDepartment(selected);
        setUserObject({
          ...userObject,
          department: selected.value,
        });
        break;
      case "product":
        setSelectedProduct(selected);
        setUserObject({
          ...userObject,
          product: selected.value,
        });
        break;
      case "departmentSearch":
        setSelectedDepartmentSearch(selected);
        handleTableData(selected.value);
        break;
      default:
        break;
    }
  };
  const handleValidSubmit = () => {
    if (!selectedCompany) {
      toastr.warning("Please select company");
      return;
    }
    if (!selectedPrivilege) {
      toastr.warning("Please select privilege");
      return;
    }
    if (!selectedModule) {
      toastr.warning("Please select module");
      return;
    }
    if (!selectedDesignation) {
      toastr.warning("Please select designation");
      return;
    }
    if (!selectedDepartment) {
      toastr.warning("Please select department");
      return;
    }
    if (userIdToBeUpdated) {
      axios
        .put(`${API_URL}user/`, userObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("User updates successfully");
            handleTableData();
            formRef.current.reset();
            setUserIdToBeUpdated(null);
            setUserObject({
              password: "",
            });
            setSelectedCompany(null);
            setSelectedPrivilege(null);
            setSelectedModule(null);
            setModuleOptions([]);
            setSelectedDepartment(null);
            setSelectedDesignation(null);
            setSelectedProduct(null);
            setPasswordObject({});
          } else toastr.error("Failed to update user");
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}user/`, userObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("User created successfully");
            handleTableData();
            formRef.current.reset();
            setUserObject({
              password: "",
            });
            setSelectedCompany(null);
            setSelectedPrivilege(null);
            setSelectedModule(null);
            setModuleOptions([]);
            setSelectedDepartment(null);
            setSelectedDesignation(null);
            setSelectedProduct(null);
          } else toastr.error("Failed to create user");
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: userIdToBeUpdated,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}user/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            closeModal();
            formRef.current.reset();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };
  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  const reset = () => {
    setSelectedDepartmentSearch(null);
    handleTableData();
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}user` + "/" + userIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("User deleted successfully");

                  if (userObject && userObject?._id === userIdToBeDeleted) {
                    formRef.current.reset();
                    setUserIdToBeUpdated(null);
                    setUserObject({
                      password: "",
                    });
                    setSelectedCompany(null);
                    setSelectedPrivilege(null);
                    setSelectedModule(null);
                    setModuleOptions([]);
                    setSelectedDepartment(null);
                    setSelectedDesignation(null);
                    setSelectedProduct(null);
                    setPasswordObject({});
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete user");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal
        show={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Reset Password</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={() => {
            handleValidSubmitPassword();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.password}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage=" Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.confirmPassword}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              style={{ marginRight: "1rem" }}
              onClick={closeModal}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add/Manage User" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleValidSubmit();
                    }}
                  >
                    <Row>
                      {/* <h5>Create User(Basic Details)</h5> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">First Name</Label>
                          <AvField
                            style={customStyles}
                            name="firstName"
                            placeholder="First Name"
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleInputChange}
                            value={userObject?.firstName}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Last Name</Label>
                          <AvField
                            style={customStyles}
                            name="lastName"
                            placeholder="Last Name"
                            type="text"
                            errorMessage="Enter Last Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleInputChange}
                            value={userObject?.lastName}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Email</Label>
                          <AvField
                            style={customStyles}
                            name="email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleInputChange}
                            value={userObject?.email}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Mobile</Label>
                          <AvField
                            style={customStyles}
                            name="mobile"
                            placeholder="Mobile"
                            type="number"
                            errorMessage="Please provide a valid mobile."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            minLength={10}
                            maxLength={10}
                            onChange={handleInputChange}
                            value={userObject?.mobile}
                          />
                        </div>
                      </Col>
                      {/* <h5>Privilege & Login Settings</h5> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Company</Label>
                          <Select
                            name="company"
                            value={selectedCompany}
                            onChange={(selected) => {
                              handleSelectChange(selected, "company");
                            }}
                            options={companyOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Privilege</Label>
                          <Select
                            name="privilage"
                            value={selectedPrivilege}
                            onChange={(selected) => {
                              handleSelectChange(selected, "privilege");
                            }}
                            options={privilegeOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Default Module
                          </Label>
                          <Select
                            name="module"
                            value={selectedModule}
                            onChange={(selected) => {
                              handleSelectChange(selected, "module");
                            }}
                            options={moduleOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Designation
                          </Label>
                          <Select
                            name="user_desig"
                            value={selectedDesignation}
                            onChange={(selected) => {
                              handleSelectChange(selected, "designation");
                            }}
                            options={designationOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Department</Label>
                          <Select
                            name="department"
                            value={selectedDepartment}
                            onChange={(selected) => {
                              handleSelectChange(selected, "department");
                            }}
                            options={departmentOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">Product</Label>
                              <Select
                                name="product"
                                value={selectedProduct}
                                onChange={(selected) => {
                                  handleSelectChange(selected, "product");
                                }}
                                options={productOptions}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                          </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            User ID/ Employee ID
                          </Label>
                          <AvField
                            style={customStyles}
                            name="Employee_id"
                            placeholder="User ID/ Employee ID"
                            type="text"
                            errorMessage="Enter User ID/ Employee ID"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleInputChange}
                            value={userObject?.Employee_id}
                          />
                        </div>
                      </Col>
                      {userIdToBeUpdated ? null : (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Password</Label>
                            <AvField
                              style={customStyles}
                              name="password"
                              placeholder="Password"
                              type="password"
                              errorMessage="Enter Password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              onChange={handleInputChange}
                              value={userObject?.password}
                            />
                          </div>
                        </Col>
                      )}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Landline Extension
                          </Label>
                          <AvField
                            style={customStyles}
                            name="land_line_extension"
                            placeholder="Landline Extension"
                            type="number"
                            errorMessage="Enter Landline Extension"
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleInputChange}
                            value={userObject?.land_line_extension}
                          />
                        </div>
                      </Col>
                      <Col
                        md="1"
                        className="mt-4"
                        style={{ paddingTop: "4px" }}
                      >
                        <div className="mb-2">
                          {userIdToBeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                            >
                              Submit
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">Department</Label>
                        <Select
                          name="department"
                          value={selectedDepartmentSearch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "departmentSearch");
                          }}
                          options={departmentOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                      <Button
                        color="danger"
                        type="submit"
                        onClick={() => reset()}
                      >
                        {"  "}
                        Reset
                      </Button>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="addUserTableID"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddManageUser;
