import React, { useState } from "react";
import { Card, Row, Col, CardBody } from "reactstrap";
import { Bar } from "react-chartjs-2";




function ShareOfVoice() {

    // date
    const formatDate = (date, fullMonth = false) => {
        const options = fullMonth
            ? { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
            : { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        return new Date(date).toLocaleString('en-US', options);
    };

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showFullMonth, setShowFullMonth] = useState(false);

    const toggleShowFullMonth = () => {
        setShowFullMonth(!showFullMonth);
    };

    const option1 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            xAxes: [
                {
                    barPercentage: 0.2,
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 1000,
                        stepSize: 200
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Volume Count',
                    },
                },
            ],
        }
    }

    const data1 = {
        labels: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
        datasets: [
            {
                label: "Kial",
                backgroundColor: "#e83e8c",
                data: [1000, 1000, 1000, 1000, 1000, 1000]
            }
        ]
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div>
                        <p className="titletit">Monthly Share of Voice</p>
                        <h6 className="dateText" onClick={toggleShowFullMonth}>
                            {formatDate(startDate, showFullMonth)} - {formatDate(endDate, showFullMonth)}

                        </h6>
                    </div>

                    <Row style={{ rowGap: "20px" }}>
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Share of Voice</h5>
                                    <Bar width={474} height={200} options={option1} data={data1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )

}

export default ShareOfVoice;