import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

import { AvForm, AvField } from "availity-reactstrap-validation";

import axios from "axios";
import toastr from "toastr";
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./Department.scss";

const Department = () => {
  const [departmentObject, setDepartmentObject] = useState({});
  const [departmentIdTobeUpdated, setDepartmentIdToBeUpdated] = useState(null);
  const [departmentIdToBeDeleted, setDepartmentIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [departmentForTable, setDepartmentForTable] = useState([]);
  const [departmentValue, setDepartmentValue] = useState("");

  const [desiType, setDesiType] = useState([]);
  const [selectedDesi, setSelectedDesi] = useState(null);

  const {
    districts,
    addingDepartment,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_all_DesiType();
  }, []);

  function handleTableData() {
    var url = `${API_URL}department/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let departmentData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateDepartment(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setDepartmentIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.itemsup_date)
            item.date = moment(item.itemsup_date).format("DD-MM-YYYY");
          else item.date = moment(item.items_date).format("DD-MM-YYYY");

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }
          let desi_value = item?.desig_Head || {};
          item.head = desi_value.desig_name;
          // if (item?.time)
          //   item.time = moment(item.time, "HHmmss").format("hh:mm a");
          // else item.time = "";

          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          departmentData.push(item);
        });
        setDepartmentForTable(departmentData);
      });
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function fetch_all_DesiType() {
    axios
      .get(`${API_URL}desig/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var DesigType_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.desig_name,
              value: el._id,
            };
          });
        setDesiType([{ options: DesigType_data }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "desig_Head":
        setSelectedDesi(selected);
        setDepartmentObject({
          ...departmentObject,
          desig_Head: selected.value,
        });
        break;

      default:
        break;
    }
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Department",
        field: "depart_Name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 150,
      },
      {
        label: "Head",
        field: "head",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: departmentForTable,
  };

  let preUpdateDepartment = (item) => {
    setDepartmentIdToBeUpdated(item._id);
    setDepartmentValue(item?.name);

    axios
      .get(`${API_URL}department/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let desg = result?.desig_Head ? result.desig_Head : {};
        let DesignValue = {
          label: desg?.desig_name,
          value: desg?._id,
        };

        setSelectedDesi(DesignValue);
        setDepartmentObject(result);
      });
  };

  function handleChangeDepartment(e) {
    let name = e.target.name;
    let value = e.target.value;
    setDepartmentValue(value);
    setDepartmentObject({ ...departmentObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (departmentIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}department`, departmentObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Department updated successfully");
            setDepartmentValue("");
            formRef.current.reset();
            handleTableData();
            setDepartmentObject({});
            setDepartmentIdToBeUpdated(null);

            setSelectedDesi(null);
          } else {
            toastr.error("Failed to update Department");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}department`, departmentObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Department added Successfully");
            handleTableData();
            setDepartmentValue("");
            formRef.current.reset();
            setDepartmentObject({});

            setSelectedDesi(null);
          } else {
            toastr.error("Failed to add Department");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  const reset = () => {
    formRef.current.reset();
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}department` + "/" + departmentIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Department deleted successfully");
                  if (
                    departmentObject &&
                    departmentObject._id === departmentIdToBeDeleted
                  ) {
                    setDepartmentValue("");
                    formRef.current.reset();
                    setDepartmentObject({});
                    setDepartmentIdToBeUpdated(null);

                    setSelectedDesi(null);
                  }
                  setDepartmentIdToBeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Department");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Department" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Department Name
                          </Label>

                          <AvField
                            style={customStyles}
                            name="depart_Name"
                            placeholder="Department Name"
                            type="text"
                            errorMessage="Enter Department Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={departmentObject?.depart_Name}
                            onChange={handleChangeDepartment}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Designation Head
                          </Label>
                          <Select
                            name="desig_Head"
                            errorMessage="Select Designation Head"
                            value={selectedDesi}
                            onChange={(selected) => {
                              handleSelectChange(selected, "desig_Head");
                            }}
                            options={desiType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Short Code</Label>

                          <AvField
                            style={customStyles}
                            name="shortCode"
                            placeholder="Short Code"
                            //type="number"
                            errorMessage="Enter Short Code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={departmentObject?.shortCode}
                            onChange={handleChangeDepartment}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          {departmentIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingDepartment ? true : false}
                            >
                              {addingDepartment ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingDepartment ? true : false}
                            >
                              {addingDepartment ? "Adding" : "Submit"}
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="departmentTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Department;
