import React from "react"
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts"
                                              
const chartapex = (props) => {
console.log(props,"hsajdghsag");


   const graphData = props.graphData;

   console.log(graphData.series,"graphdcsadad");


  //  const series = graphData?.map((el) => {
  

  //   // console.log(el,"elllllll");

  //   return(
  //     el
  //   )
  //  })






  //  const series = graphData



  //  const data = graphData ? graphData.map((el) => el.metricData) : [];

  //  const series = data.map((itemArray) => (
  //    itemArray ? itemArray.map((item) => (
  //      item ? { name: item.metricDisplayName, data: [item.value] } : null
  //    )) : null
  //  )).flat().filter(Boolean);
   
  //  console.log(data, "data");
  //  console.log(series, "series");
   

 const series = graphData.series

 
 

  const options = {
    chart: {  id:"metricsgraph", zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ['#f46a6a','#5b73e8', '#f1b44c','#34c38f',],
    dataLabels: { 
      enabled: !1,
    },
    stroke: {
      width: [3, 3],
      curve: 'straight'
    },
    title: {

      
      align: 'left'
    },
    grid: { 
      row: {
        colors: ['transparent', 'transparent'],    // takes an array which will be repeated on columns
        opacity: 0.2
      },
      borderColor: '#f1f1f1'
    },
    markers: {
      style: 'inverted',
      size: 6
    },
    xaxis: {
      categories: graphData.labels, 
      title: {
        text: graphData.timePeriodField
      }
    },
    yaxis: {
      title: {
        text: 'Number'
      },
      // min: 40, 
      // max: ,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      // offsetY: -25, 
      offsetX: -5
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          toolbar: {
            show: false
          }
        },
        legend: {
          show: false
        },
      }
    }]
  }
  // ApexCharts.exec("metricsgraph", "hideSeries", ["Maps Impressions (Desktop)"]); 
  for (const metric of graphData.metricsToHideByDefault) {
    ApexCharts.exec("metricsgraph", "hideSeries", [metric]);
  }


  return (
    <>
  

    
    <ReactApexChart
      options={options}
      series={series} 
      type="line"
      height="380"
      className="apex-charts"
    />
    </>
  )
}

export default chartapex
