import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./product.scss";
import { Grid } from "@mui/material";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const Product = () => {
  const [productObject, setProductObject] = useState({});
  const [productIdTobeUpdated, setProductIdToBeUpdated] = useState(null);
  const [productIdToBeDeleted, setProductIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [productForTable, setProductForTable] = useState([]);
  const [productValue, setproductValue] = useState("");

  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const {
    districts,
    addingProduct,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_all_Department();
    fetch_all_Category();
  }, []);

  const dispatch = useDispatch();

  function handleTableData() {
    var url = `${API_URL}product/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let ProductData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateProduct(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setProductIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          // if (item?.subCategoryup_date)
          //   item.date = moment(item.subCategoryup_date).format("DD-MM-YYYY");
          // else item.date = moment(item.subCategory_date).format("DD-MM-YYYY");

          // if (item?.up_time) {
          //   item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          // } else {
          //   item.time = moment(item.time, "HHmmss").format("hh:mm a");
          // }

          let depart_value = item?.department || {};
          item.departments = depart_value.depart_Name;

          let category_value = item?.category || {};
          item.categorys = category_value.name;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }
          ProductData.push(item);
        });
        setProductForTable(ProductData);
      });
  }
  let preUpdateProduct = (item) => {
    setProductIdToBeUpdated(item._id);
    setproductValue(item?.name);

    axios
      .get(`${API_URL}product/data?product_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((element) => {
            let dept = element?.department ? element.department : {};
            let departmentValue = {
              label: dept?.depart_Name,
              value: dept?._id,
            };

            let cate = element?.category ? element.category : {};
            let categoryValue = {
              label: cate?.name,
              value: cate?._id,
            };
            element.product_id = element?._id;
            setSelectedCategory(categoryValue);

            setSelectedProduct(departmentValue);
            setProductObject(element);
          });
      });
  };

  function fetch_all_Department() {
    axios
      .get(`${API_URL}department/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var productType_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.depart_Name,
              value: el._id,
            };
          });
        setProduct([{ options: productType_data }]);
      });
  }

  function fetch_all_Category() {
    axios
      .get(`${API_URL}product_category/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var categoryType_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setCategory([{ options: categoryType_data }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "department":
        setSelectedProduct(selected);
        setProductObject({
          ...productObject,
          department: selected.value,
        });
        break;
      case "category":
        setSelectedCategory(selected);
        setProductObject({
          ...productObject,
          category: selected.value,
        });
        break;
      default:
        break;
    }
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "short_code",
        sort: "asc",
        width: 270,
      },
      {
        label: "Department",
        field: "departments",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "categorys",
        sort: "asc",
        width: 150,
      },

      {
        label: "Keywords",
        field: "keywords",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: productForTable,
  };

  function handleChangeProduct(e) {
    let name = e.target.name;
    let value = e.target.value;
    setproductValue(value);
    setProductObject({ ...productObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (productIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}product/update`, productObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })

        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Product updated successfully");
            setproductValue("");
            formRef.current.reset();
            handleTableData();
            setProductObject({});
            setProductIdToBeUpdated(null);

            setSelectedProduct(null);
            setSelectedCategory(null);
          } else {
            toastr.error("Failed to update Product");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}product`, productObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Product added successfully");
            handleTableData();
            setproductValue("");
            formRef.current.reset();
            setProductObject({});

            setSelectedProduct(null);
            setSelectedCategory(null);
          } else {
            toastr.error("Failed to add Product");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  const reset = () => {
    formRef.current.reset();
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}product` + "/" + productIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Sub Category deleted successfully");
                    if (
                      productObject &&
                      productObject._id === productIdToBeDeleted
                    ) {
                      setproductValue("");
                      formRef.current.reset();

                      setProductObject({});
                      setProductIdToBeUpdated(null);
                      setSelectedProduct(null);
                      setSelectedCategory(null);
                    }
                    setProductIdToBeDeleted(null);
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Sub Category"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Products" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Product Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="product_name"
                              placeholder="Product Name"
                              type="text"
                              errorMessage="Enter Product Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={productObject?.product_name}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Short Code
                            </Label>
                            <AvField
                              style={customStyles}
                              name="short_code"
                              placeholder="Short Code"
                              type="text"
                              errorMessage="Enter Short Code"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={productObject?.short_code}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Department Name</Label>
                            <Select
                              name="department"
                              errorMessage="Select Department Name"
                              value={selectedProduct}
                              onChange={(selected) => {
                                handleSelectChange(selected, "department");
                              }}
                              options={product}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Category Name</Label>
                            <Select
                              name="category"
                              errorMessage="Select Category Name"
                              value={selectedCategory}
                              onChange={(selected) => {
                                handleSelectChange(selected, "category");
                              }}
                              options={category}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="mb-6">
                            <Label htmlFor="validationCustom01">
                              Product Keywords
                            </Label>
                            <AvField
                              style={customStyles}
                              name="keywords"
                              placeholder="Product Keywords"
                              type="text"
                              errorMessage="Enter Product Keywords"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={productObject?.keywords}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {productIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingProduct ? true : false}
                              >
                                {addingProduct ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingProduct ? true : false}
                              >
                                {addingProduct ? "Adding" : "Submit"}
                              </Button>
                            )}
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="productTableId"
                        responsive
                        
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Product;
