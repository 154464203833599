
import React, { useEffect, useState } from 'react'

import google from '../../../assets/fotos/Group 8.svg'


import clock from '../../../assets/fotos/clock.svg'
import share from '../../../assets/fotos/shareicon.svg'
import draft from '../../../assets/fotos/draft.svg'



import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Table, Thead, Tbody, Tr,} from "react-super-responsive-table"
 
import { Button } from "reactstrap";

import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom'

import post1 from '../../../assets/fotos/Rectangle 32.svg'
import post2 from '../../../assets/fotos/Rectangle 34.svg'
import post3 from '../../../assets/fotos/Rectangle 35.svg'

import lincon from '../../../assets/fotos/linkedinlogo.svg'

import faceicon from '../../../assets/fotos/Vector (2).svg'
import insticon from '../../../assets/fotos/instagramlogo.svg'
import pincon from '../../../assets/fotos/Pinterest-Logo-2011-present.png'

import youicon from '../../../assets/fotos/youtubelogo.svg'
import xtwiicon from '../../../assets/fotos/twitterXlogo.svg'
import all from '../../../assets/fotos/all.svg'


import ficon from '../../../assets/fotos/🦆 icon _Facebook F_.svg'
import iicon from '../../../assets/fotos/🦆 icon _Instagram_.svg'
import picon from '../../../assets/fotos/🦆 icon _Pinterest P_.svg'
import yicon from '../../../assets/fotos/🦆 icon _YouTube_.svg'
import xicon from '../../../assets/fotos/Vector (1).svg'
function Allpost() {
    const [open, setOpen] = useState(0)

    function openClick(num) {

        setOpen(num)


    }

    useEffect(() => {
        openClick(1)
    }, [])

    return (
        <div>

            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="All Post" />
                    <div className='reviews'>
                        <ul className='platforms' >

                            <li onClick={() => openClick(1)} className={`${open === 1 ? "active" : ""}`}>
                                <div>
                                <span className='fa'><img style={{ height: "20px" }} src={all} alt="" /></span>
                                    <span className='mainfont'>All</span>

                                    <ul className='ikon'>

                                        <li>
                                            <img className='subikon' src={share} alt="" /> 4.9
                                        </li>
                                        <li className='icon3'>
                                        <img className='subikon' src={clock} alt="" /> 371

                                        </li>
                                        <li>
                                        <img className='subikon' src={draft} alt="" /> 37

                                        </li>
                                        
                                    </ul>
                                </div>
                            </li>

                            <li onClick={() => openClick(2)} className={`${open === 2 ? "active" : ""}`}>
                                <div >


                                    <span className='fa'><img style={{ height: "20px" }} src={faceicon} alt="" /></span>
                                    <span className='mainfont'>facebook</span>


                                    <ul className='ikon'>

                                    <li>
                                            <img className='subikon' src={share} alt="" /> 4.9
                                        </li>
                                        <li className='icon3'>
                                        <img className='subikon' src={clock} alt="" /> 371

                                        </li>
                                        <li>
                                        <img className='subikon' src={draft} alt="" /> 37

                                        </li>
                                    </ul>


                                </div>
                            </li>

                            <li onClick={() => openClick(3)} className={`${open === 3 ? "active" : ""}`}>
                                <div >
                                    <span className='fa'><img style={{ height: "20px" }} src={xtwiicon} alt="" /></span>
                                    <span className='mainfont'>Twitter</span>


                                    <ul className='ikon'>
                                    <li>
                                            <img className='subikon' src={share} alt="" /> 4.9
                                        </li>
                                        <li className='icon3'>
                                        <img className='subikon' src={clock} alt="" /> 371

                                        </li>
                                        <li>
                                        <img className='subikon' src={draft} alt="" /> 37

                                        </li>
                                    </ul>


                                </div>
                            </li>

                            <li onClick={() => openClick(4)} className={`${open === 4 ? "active" : ""}`}>
                                <div >
                                    <span className='fa'><img style={{ height: "20px" }} src={lincon} alt="" /></span>
                                    <span className='mainfont'>Linkedin</span>


                                    <ul className='ikon'>

                                    <li>
                                            <img className='subikon' src={share} alt="" /> 4.9
                                        </li>
                                        <li className='icon3'>
                                        <img className='subikon' src={clock} alt="" /> 371

                                        </li>
                                        <li>
                                        <img className='subikon' src={draft} alt="" /> 37

                                        </li>
                                    </ul>


                                </div>
                            </li>

                            <li onClick={() => openClick(5)} className={`${open === 5 ? "active" : ""}`}>
                                <div >
                                    <span className='fa'><img style={{ height: "20px" }} src={insticon} alt="" /></span>
                                    <span className='mainfont'>Instagram</span>


                                    <ul className='ikon'>

                                    <li>
                                            <img className='subikon' src={share} alt="" /> 4.9
                                        </li>
                                        <li className='icon3'>
                                        <img className='subikon' src={clock} alt="" /> 371

                                        </li>
                                        <li>
                                        <img className='subikon' src={draft} alt="" /> 37

                                        </li>
                                    </ul>


                                </div>
                            </li>

                            <li onClick={() => openClick(6)} className={`${open === 6 ? "active" : ""}`}>
                                <div >
                                    <span className='fa'><img style={{ height: "20px" }} src={pincon} alt="" /></span>
                                    <span className='mainfont'>Pinterest</span>


                                    <ul className='ikon'>

                                    <li>
                                            <img className='subikon' src={share} alt="" /> 4.9
                                        </li>
                                        <li className='icon3'>
                                        <img className='subikon' src={clock} alt="" /> 371

                                        </li>
                                        <li>
                                        <img className='subikon' src={draft} alt="" /> 37

                                        </li>
                                    </ul>


                                </div>
                            </li>




                            {/* <li onClick={() => openClick(7)} className={`${open === 7 ? "active" : ""}`}>
                                <a>
                                <span className='fa'><img style={{ height: "20px" }} src={youicon} alt="" /></span>
                                    <span className='mainfont'>Youtube</span>

                                    <ul className='ikon'>

                                    <li>
                                            <img className='subikon' src={star} alt="" /> 4.9
                                        </li>
                                        <li>
                                            <img className='subikon' src={comment} alt="" /> 371
                                        </li>
                                    </ul>
                                </a>
                            </li>
                            <li onClick={() => openClick(8)} className={`${open === 8 ? "active" : ""}`}>
                                <a>
                                <span className='fa'><img style={{ height: "20px" }} src={google} alt="" /></span>
                                    <span className='mainfont'>Google</span>

                                    <ul className='ikon'>

                                    <li>
                                            <img className='subikon' src={star} alt="" /> 4.9
                                        </li>
                                        <li>
                                            <img className='subikon' src={comment} alt="" /> 371
                                        </li>
                                    </ul>
                                </a>
                            </li> */}






                        </ul>







                        <div className='reviewbar '>

                            <Dropdown className='dropdown0'>
                                <Dropdown.Toggle className='Dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                    <span class="fa fa-angle-down"></span> Recent First
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <div class="input-group" style={{ width: "auto" }}>
                                <input type="text" className='form-control' placeholder='Search For Reviews.' />
                                <Button color="info" type="submit" style={{ backgroundColor: "#1a71b5" }}>
                                    Search
                                </Button>
                            </div>




                            <div className='revnextpage' >

                                <div class="pagination">
                                 <Link to="">&laquo;</Link>
                                    <Link to="" href="#">1</Link>
                                    <Link to="" class="nextpage2" href="#">2</Link>
                                    <Link to="" href="#" className='nextcontinue'>....</Link>
                                    <Link to="" href="#">20</Link>
                                    <Link to="" href="#">&raquo;</Link>
                                </div>


                            </div>



                            <div className='revpagecontent'>
                                page <div>0</div> of  20
                            </div>

                            <div className='revpagelist' >
                                <div>Show items</div>

                                <Dropdown className=''>
                                    <Dropdown.Toggle className='Dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                        <span class="fa fa-angle-down"></span> 0
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>


                        </div>






                    </div>
                    <div className='newpost '>
                        <div className='table-responsive'>

                            <Table
                                lg="12"
                                id="tech-companies-1"
                                className="table table-bordered newposttable  ">


                                <Thead>
                                    <Tr >
                                        <th>Published On</th>
                                        <th>Post Info</th>
                                        <th>Status</th>
                                        <th>Channel</th>
                                        <th>Published By</th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <td style={{ width: "150px" }}>15 Nov 2023 10:23Am</td>
                                        <td style={{ width: "380px" }}>
                                            <div className='d-flex gap-2 '>
                                                <img style={{ width: "50px" }} src={post1} alt="" />
                                                <div><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur voluptatum quae eligendi earum.... </p></div>
                                            </div>
                                        </td>
                                        <td>Published</td>
                                        <td>
                                            <div className='d-flex gap-3'>
                                                <span><img src={ficon} alt="" /></span>
                                                <span><img src={iicon} alt="" /></span>
                                                <span><img src={picon} alt="" /></span>
                                                <span><img src={xicon} alt="" /></span>
                                                <span><img src={yicon} alt="" /></span>
                                            </div>
                                        </td>
                                        <td>srvadmin</td>
                                    </Tr>


                                    <Tr>
                                        <td style={{ width: "150px" }}>15 Nov 2023 10:23Am</td>
                                        <td style={{ width: "380px" }}>
                                            <div className='d-flex gap-2 '>
                                                <img style={{ width: "50px" }} src={post2} alt="" />
                                                <div><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur voluptatum quae eligendi earum.... </p></div>
                                            </div>
                                        </td>
                                        <td>Published</td>
                                        <td>
                                            <div className='d-flex gap-3'>
                                            <span><img src={ficon} alt="" /></span>
                                                <span><img src={iicon} alt="" /></span>
                                                <span><img src={picon} alt="" /></span>
                                                <span><img src={xicon} alt="" /></span>
                                                <span><img src={yicon} alt="" /></span>
                                            </div>
                                        </td>
                                        <td>srvadmin</td>
                                    </Tr>

                                    <Tr>
                                        <td style={{ width: "150px" }}>15 Nov 2023 10:23Am</td>
                                        <td style={{ width: "380px" }}>
                                            <div className='d-flex gap-2 '>
                                                <img style={{ width: "50px" }} src={post3} alt="" />
                                                <div><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur voluptatum quae eligendi earum.... </p></div>
                                            </div>
                                        </td>
                                        <td>Published</td>
                                        <td>
                                            <div className='d-flex gap-3'>
                                                <span><img src={ficon} alt="" /></span>
                                                <span><img src={iicon} alt="" /></span>
                                                <span><img src={picon} alt="" /></span>
                                                <span><img src={xicon} alt="" /></span>
                                                <span><img src={yicon} alt="" /></span>
                                            </div>
                                        </td>
                                        <td>srvadmin</td>
                                    </Tr>
                                </Tbody>

                            </Table>

                        </div>




                    </div>

                </div>
            </div>
        </div>
    )
}

export default Allpost