import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactEcharts from "echarts-for-react";
import { Bar, Line } from "react-chartjs-2";
import "./dashboardSummary.scss";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function DashboardSummary() {
  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };

    return new Date(date).toLocaleString("en-US", options);
  };

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [showFullMonth, setShowFullMonth] = useState(false);

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth);
  };

  const [summaryCardData, setSummaryCardData] = useState();
  const [sentimentData, setSentimentData] = useState();
  const [volumeCountTotal, setVolumeCountTotal] = useState();

  const fromDate = moment(startDate).format("YYYY-MM-DD");
  const ToDate = moment(endDate).format("YYYY-MM-DD");

  function fetchSummaryCardData() {
    var url = `${API_URL}report/v2/summary/cards?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setSummaryCardData(result);
      });
  }

  function fetchSentimentData() {
    var url = `${API_URL}report/v2/sentiment/countGrouped?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setSentimentData(result);
      });
  }

  function fetchVolumeCountTotal() {
    var url = `${API_URL}report/v2/volume/countTotal?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data;
        setVolumeCountTotal(result);
      });
  }

  useEffect(() => {
    fetchSummaryCardData();
    fetchSentimentData();
    fetchVolumeCountTotal();
  }, []);

  // gauge
  const getOption = () => {
    return {
      tooltip: {
        formatter: "{a} <br/>{b} : {c}%",
      },
      toolbox: {
        feature: {
          restore: {},
          saveAsImage: {},
        },
      },
      series: [
        {
          name: "Tonality",
          type: "gauge",
          detail: { formatter: "{value}%" },
          data: [{ value: 0.47, name: "Neutral" }],
          axisLine: {
            lineStyle: {
              color: [
                [0.4, "rgba(254, 45, 45)"],
                [0.7, "rgb(254,248,76)"],
                [1, "rgb(113,206,126)"],
              ],
              width: 20,
            },
          },
          axisLabel: {
            show: true,
            formatter: function (value) {
              // Custom formatting for the axis label
              return (value / 100).toFixed(1);
            },
          },
        },
      ],
    };
  };

  // bar chart1
  const options1 = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const backgroundColors = ["#98d8a9", "#ff928b", "#ffdf97"];

  const data1 = {
    labels: sentimentData?.categories,

    datasets: sentimentData?.series.map((item, index) => ({
      label: item.name,
      data: item.data,
      backgroundColor: backgroundColors[index],
      stack: "Stack 0",
    })),
  };

  // Line chart4
  const lineData1 = {
    labels: volumeCountTotal?.labels,
    datasets: [
      {
        label: "Kial",
        data: volumeCountTotal?.data,
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const lineOption1 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 80, // Set the minimum value for the y-axis
            // max: 180,
            stepSize: 20, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Dashboard Summary</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>

          <Row style={{ rowGap: "20px" }}>
            {summaryCardData?.map((item) => (
              <Col md="3">
                <Card>
                  <CardBody>
                    <div class="contbox">
                      <span class="mainval">
                        <i
                          class={`fa ${
                            item.difference < 0
                              ? "fa-angle-down"
                              : "fa-angle-up"
                          }`}
                        ></i>
                        {Math.abs(item.difference)}{" "}
                      </span>
                      <span>({item.percentageDifference}%)</span>
                    </div>
                    <div class="hdbox">
                      <h4 class="title1">
                        <i
                          class={`fab ${item.iconClassName} icon-dashboard-2`}
                        ></i>{" "}
                        {item.metricName}
                      </h4>
                      <h4
                        class="title1"
                        style={{ textAlign: "end", marginTop: "-24px" }}
                      >
                        {item.latestValue}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row style={{ rowGap: "20px" }}>
            {/* Gauge Chart */}
            <Col md="4">
              <Card>
                <CardBody>
                  <h5>Tonality</h5>
                  <ReactEcharts height={200} option={getOption()} />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart1 */}
            <Col md="8">
              <Card>
                <CardBody>
                  <h5>Sentiment Breakup By Media</h5>
                  <Bar
                    width={474}
                    height={200}
                    options={options1}
                    data={data1}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart2 */}
            {/* <Col md="4">
              <Card>
                <CardBody>
                  <h5>News</h5>
                  <Bar
                    width={474}
                    height={420}
                  />
                </CardBody>
              </Card>
            </Col> */}

            {/* line chart1 */}
            <Col md="12">
              <Card>
                <CardBody>
                  <h5>Daily Total Volume</h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData1}
                    options={lineOption1}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default DashboardSummary;
