import {
  GET_PRIVILAGES,
  GET_PRIVILAGES_FAIL,
  GET_PRIVILAGES_SUCCESS,
  ADD_PRIVILAGE,
  ADD_PRIVILAGE_FAIL,
  ADD_PRIVILAGE_SUCCESS,
  UPDATE_PRIVILAGE,
  UPDATE_PRIVILAGE_FAIL,
  UPDATE_PRIVILAGE_SUCCESS,
  DELETE_PRIVILAGE,
  DELETE_PRIVILAGE_FAIL,
  DELETE_PRIVILAGE_SUCCESS,
  GET_PRIVILAGES_OPTIONS,
  GET_PRIVILAGES_OPTIONS_SUCCESS,
  GET_PRIVILAGES_OPTIONS_FAIL,
  GET_PRIVILAGES_MODULES,
  GET_PRIVILAGES_MODULES_SUCCESS,
  GET_PRIVILAGES_MODULES_FAIL,
} from "./actionTypes";

export const getAllPrivileges = () => ({
  type: GET_PRIVILAGES,
});

export const getAllPrivilegesSuccess = (privilege) => ({
  type: GET_PRIVILAGES_SUCCESS,
  payload: privilege,
});

export const getAllPrivilegesFail = (error) => ({
  type: GET_PRIVILAGES_FAIL,
  payload: error,
});

export const addPrivilege = (privilege) => ({
  type: ADD_PRIVILAGE,
  payload: privilege,
});

export const addPrivilegeSuccess = (privilege) => ({
  type: ADD_PRIVILAGE_SUCCESS,
  payload: privilege,
});

export const addPrivilegeFail = (error) => ({
  type: ADD_PRIVILAGE_FAIL,
  payload: error,
});

export const updatePrivilege = (privilege) => ({
  type: UPDATE_PRIVILAGE,
  payload: privilege,
});

export const updatePrivilegeSuccess = (privilege) => ({
  type: UPDATE_PRIVILAGE_SUCCESS,
  payload: privilege,
});

export const updatePrivilegeFail = (error) => ({
  type: UPDATE_PRIVILAGE_FAIL,
  payload: error,
});

export const deletePrivilege = (privilege, auth_user) => ({
  type: DELETE_PRIVILAGE,
  payload: privilege,
  auth_data: auth_user,
});

export const deletePrivilegeSuccess = (privilege) => ({
  type: DELETE_PRIVILAGE_SUCCESS,
  payload: privilege,
});

export const deletePrivilegeFail = (error) => ({
  type: DELETE_PRIVILAGE_FAIL,
  payload: error,
});

export const getPrivilagesOptions = () => ({
  type: GET_PRIVILAGES_OPTIONS,
});

export const getPrivilagesOptionsSuccess = (privilages) => ({
  type: GET_PRIVILAGES_OPTIONS_SUCCESS,
  payload: privilages,
});

export const getPrivilagesOptionsFail = (error) => ({
  type: GET_PRIVILAGES_OPTIONS_FAIL,
  payload: error,
});

export const getAllModules = (privilege_id) => ({
  type: GET_PRIVILAGES_MODULES,
  privilege_id: privilege_id,
});

export const getAllModulesSuccess = (modules) => ({
  type: GET_PRIVILAGES_MODULES_SUCCESS,
  payload: modules,
});

export const getAllModulesFail = (error) => ({
  type: GET_PRIVILAGES_MODULES_FAIL,
  payload: error,
});
