import {
    GET_CHARTOFACC,
    GET_CHARTOFACC_SUCCESS,
    GET_CHARTOFACC_FAIL,
    ADD_CHARTOFACC,
    ADD_CHARTOFACC_SUCCESS,
    ADD_CHARTOFACC_FAIL,
    UPDATE_CHARTOFACC,
    UPDATE_CHARTOFACC_SUCCESS,
    UPDATE_CHARTOFACC_FAIL,
    DELETE_CHARTOFACC,
    DELETE_CHARTOFACC_SUCCESS,
    DELETE_CHARTOFACC_FAIL
    } from './actionTypes';

    const INIT_STATE = {
        chartOfAccounts: [],
        addingChartOfAccount: false,
        deletingChartOfAccount: false,
        addChartOfAccountResponse: {},
        updateChartOfAccountResponse: {},
        deleteChartOfAccountResponse: {},
        chartOfAccIdToBeDeleted: '',
        chartOfAccount: {},
        error: {},
    //    acctypeOptions: [],
      }; 

      const chartOfAccounts = (state = INIT_STATE, action) => {
        switch (action.type) {
          case GET_CHARTOFACC:
            return {
              ...state,
              params: action.payload,
            };
      
          case GET_CHARTOFACC_SUCCESS:
            return {
              ...state,
              chartOfAccounts: action.payload.data,
            };
      
          case GET_CHARTOFACC_FAIL:
            return {
              ...state,
              error: action.payload,
            };
          case ADD_CHARTOFACC:
            return {
              ...state,
              addingChartOfAccount: true,
              chartOfAccount: action.payload,
            };
      
          case ADD_CHARTOFACC_SUCCESS:
            let newChartOfAccounts = [JSON.parse(JSON.stringify(action.payload.data[0]))];
            newChartOfAccounts.push(...state.chartOfAccounts);
            return {
              ...state,
              addingChartOfAccount: false,
              addChartOfAccountResponse: {
                type: 'success',
                message: 'Chart of account added successfully',
              },
              chartOfAccounts: newChartOfAccounts,
              error: {},
            };
      
          case ADD_CHARTOFACC_FAIL:
            return {
              ...state,
              addingChartOfAccount: false,
              addChartOfAccountResponse: { type: 'failure', message: 'Adding chart of account failed' },
              error: action.payload,
            };
          case DELETE_CHARTOFACC:
            return {
              ...state,
              deletingChartOfAccount: true,
              chartOfAccIdToBeDeleted: action.payload,
            };
      
          case DELETE_CHARTOFACC_SUCCESS:
            let newData = state.chartOfAccounts.filter((e) => {
              return e.acc_chart_id != state.chartOfAccIdToBeDeleted;
            });
            return {
              ...state,
              deletingChartOfAccount: false,
              chartOfAccIdToBeDeleted: '',
              deleteChartOfAccountResponse: {
                type: 'success',
                message: 'Account deleted successfully',
              },
              chartOfAccounts: newData,
            };
      
          case DELETE_CHARTOFACC_FAIL:
            return {
              ...state,
              deletingChartOfAccount: false,
              deleteChartOfAccountResponse: {
                type: 'failure',
                message: 'Deleting Account failed',
              },
              error: action.payload,
            };
      
          case UPDATE_CHARTOFACC:
            return {
              ...state,
              updatingChartOfAccount: true,
              chartOfAccount: action.payload,
            };
      
          case UPDATE_CHARTOFACC_SUCCESS:
            let newChartOfAccarr = [];
            let newChartOfAcc1 = state.chartOfAccounts.filter((chartOfAccount) => {
              if (chartOfAccount.acctype_id == state.chartOfAccount.acctype_id) {
                newChartOfAccarr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
              } else {
                newChartOfAccarr.push(chartOfAccount);
              }
            });
      
            return {
              ...state,
              updatingChartOfAccount: false,
              chartOfAccount: '',
              updatingChartOfAccount: false,
              updateChartOfAccountResponse: {
                type: 'success',
                message: 'Account updated successfully',
              },
              chartOfAccounts: newChartOfAccarr,
            };
      
          case UPDATE_CHARTOFACC_FAIL:
            return {
              ...state,
              updatingChartOfAccount: false,
              updateChartOfAccountResponse: {
                type: 'failure',
                message: 'Updating acc failed',
              },
              error: action.payload,
            };
        //     case GET_ACCTYPE_OPTIONS_SUCCESS:
        //       return {
        //         ...state,
        //         acctypeOptions: action.payload.data,
        //       };
        
        //     case GET_ACCTYPE_OPTIONS_FAIL:
        //       return {
        //         ...state,
        //         error: action.payload,
        //       };
      
          default:
            return state;
        }
      };
      
      export default chartOfAccounts;