import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MiniWidget from "./mini-widget"
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { Link } from 'react-router-dom';
import LineApexChart from "./chartapex"
import accessToken from "../../helpers/jwt-token-access/accessToken";

import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
} from "reactstrap"
import "./Googlemybussiness.scss"
import axios from 'axios';

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function Googlemybussiness() {


  const [selectedObject, setSelectedObject] = useState({ interval: 'weekly', intervalCount: '10' });

  const [selectedGraph, setSelectedGraph] = useState({interval: 'weekly', intervalCount: 10, }); 

  useEffect(() => {
    handleTableData();

  }, [selectedObject]);

  useEffect(()=>{
    handleGraphData(selectedGraph.interval, selectedGraph.intervalCount)
  },[])

  useEffect(() => {
    handleNameData();
    fetchData();

  }, [])



  const [masterObject, setMasterObject] = useState(null)
  const [nameObject, setNameObject] = useState(null)
  const [fetchCards, setFetchCards] = useState([])

  const [fetchGraph, setFetchGraph] = useState(null)


  function handleTableData() {
    var url = `${API_URL}google/metrics/dashboard/table?interval=${selectedObject.interval}&&intervalCount=${selectedObject.intervalCount}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {
        console.log(res, "resss");

        setMasterObject(res)

        // console.log(masterObject, "masterobject");


      }  

      )

  }


  function handleNameData() {
    var url = `${API_URL}google/metrics/names`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {
        // console.log(res, "Namessss");

        setNameObject(res)

        // console.log(nameObject, "nameobject");


      }

      )

  }

  const intervalNames = {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
  };

  function handleGraphData(interval, intervalCount) {
    var url = `${API_URL}google/metrics/dashboard/graph?interval=${interval}&&intervalCount=${intervalCount}`;

    axios.get(url, {
      headers: {
        "x-access-token": accessToken,
      },
    }).then((res) => {

     
      console.log(res.data.data,"dataaaaa");

      setFetchGraph(res?.data?.data)


      console.log(fetchGraph,"result12");


      // console.log(fetchCards, "fetchcards"); 
    }).catch((error) => {
      console.error('Error :', error);
      // Handle the error as needed
    });

  }


  const fetchData = () => {
    var url = `${API_URL}google/metrics/dashboard/cards`;

    axios.get(url, {
      headers: {
        "x-access-token": accessToken,
      },
    }).then((res) => {


      setFetchCards(res?.data?.data);

      // console.log(fetchCards, "fetchcards"); 
    });
  };









  const series1 = [50]

  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }



  const reports = fetchCards.map((item) => {
    console.log(item, "itemssss");
    return (
      {
        id: 1,
        icon: item.percentageDifference < 0 ? "mdi mdi-arrow-down-bold" : "mdi mdi-arrow-up-bold",
        icon2: item.iconClassName,
        title: item.metricDisplayName,
        value: item.value,
        decimal: 0,
        charttype: "radialBar",
        chartheight: 60,
        chartwidth: 45,
        prefix: "",
        suffix: "",
        badgeValue: Math.abs(item.percentageDifference) + "%",
        color: item.percentageDifference < 0 ? "danger" : "success",
        desc: "since last month ",
        series: series1,
        options: options1,
      }
    )
  })

  const [selectedSort, setSelectedSort] = useState("Weekly");




  const handleSelectGraph = (interval) => {
    setSelectedGraph({
      interval,
      intervalCount: 10, // Reset interval count when the interval changes, adjust as needed
    });
    handleGraphData(interval, 10);
  };

  


  // Function to handle item selection
  const handleSortSelect = (num) => {


    if (num == "Weekly") {
      setSelectedObject({ ...selectedObject, interval: "weekly", intervalCount: "10" })
      setSelectedSort("Weekly");
      console.log(selectedObject, "selectedddobjectt");
    }

    if (num == "Daily") {
      setSelectedSort("Daily");
      setSelectedObject({ ...selectedObject, interval: "daily", intervalCount: "10" })
    }

    if (num == "Monthly") {
      setSelectedSort("Monthly");
      setSelectedObject({ ...selectedObject, interval: "monthly", intervalCount: "6" })
    }
  };


  return (

    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
          <Row>
            <MiniWidget reports={reports} />
          </Row>
          <Card>
            <CardBody>
              <div className=" mb-2" style={{ display: "flex", justifyContent: "flex-end" }}>
                <UncontrolledDropdown>
                  <DropdownToggle style={{ cursor: "pointer" }} tag="a" className="text-reset" id="dropdownMenuButton5" caret >
                    <span className="fw-semibold">Sort By:</span> {' '}
                    <span className="text-muted">
                      {selectedSort}<i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem onClick={() => handleSortSelect("Daily")}>Daily</DropdownItem>
                    <DropdownItem onClick={() => handleSortSelect("Weekly")}>Weekly</DropdownItem>
                    <DropdownItem onClick={() => handleSortSelect("Monthly")}>Monthly</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>


              {selectedSort === "Daily" ?
                <div className='table-responsive gmbtable'>
                  <Table lg="12" className="mytable1 table table-bordered  ">
                    <Thead>
                      <Tr>
                        <th class="sticky-col">
                          <p > Bussiness</p>
                          <p><i className="fas fa-users"></i> </p>
                        </th>

                        {masterObject?.data?.data?.map((item, index) => (

                          <Th>{item.day}</Th>

                        )
                        )}

                      </Tr>
                    </Thead>



                    <Tbody>


                      {nameObject?.data?.data.map((item, index) => (
                        <Tr>
                          <Th className="sticky-col">{item.displayName}</Th>

                          {masterObject?.data?.data?.map((dayobj, index) => (

                            <td>

                              {dayobj.metricData.find(metric => metric.metricDisplayName === item.displayName).value}


                            </td>
                          ))}
                        </Tr>

                      ))}

                    </Tbody>


                  </Table>
                </div>
                : null}

              {selectedSort === "Weekly" ?
                <div className='table-responsive gmbtable'>
                  <Table lg="12" className="mytable1 table table-bordered  ">
                    <Thead>
                      <Tr >
                        <th class="sticky-col">
                          <p> Bussiness</p>
                          <p><i className="fas fa-users"></i> </p>
                        </th>


                        {masterObject?.data?.data?.map((item, index) => (

                          <Th>{item.week}</Th>


                        ))}


                      </Tr>
                    </Thead>

                    <Tbody>

                      {nameObject?.data?.data.map((item, index) => ( 
                        <Tr>
                          <Th className="sticky-col">{item.displayName}</Th>

                          {masterObject?.data?.data?.map((weekobj, index) => (

                            <td>

                              {weekobj.metricData.find(metric => metric.metricDisplayName === item.displayName).value}


                            </td>
                          ))}
                        </Tr>

                      ))}


                    </Tbody>


                  </Table>
                </div>
                : null}

              {selectedSort === "Monthly" ?
                <Table lg="12" className="mytable1 table table-bordered  ">
                  <Thead>
                    <Tr>
                      <th class="sticky-col">
                        <p> Bussiness</p>
                        <p><i className="fas fa-users"></i> </p>
                      </th>
                      {/* <Th>Sep-23</Th>
                      <Th>Oct-23</Th>
                      <Th>Nov-23</Th>
                      <Th>Dec-23</Th>
                      <Th>Jan-24</Th>
                      <Th>Feb-24</Th> */}

                      {masterObject?.data?.data?.map((item, index) => (

                        <Th>{item.month}</Th>


                      ))}
                    </Tr>
                  </Thead>    

                  <Tbody>
                    {nameObject?.data?.data.map((item, index) => (   
                      <Tr>
                        <Th className="sticky-col">{item.displayName}</Th>

                        {masterObject?.data?.data?.map((monthobj, index) => (

                          <td>

                            {monthobj.metricData.find(metric => metric.metricDisplayName === item.displayName).value}


                          </td>
                        ))}
                      </Tr>

                    ))}


                  </Tbody>


                </Table>
                : null}







            </CardBody>
          </Card>


          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                <div className=" mb-2" style={{ display: "flex", justifyContent:"space-between"  }}>
                  <div>
                  <CardTitle className="mb-4">GMB@Kial</CardTitle>
                  </div>
                  <div>
                    <UncontrolledDropdown>
                      <DropdownToggle style={{ cursor: "pointer" }} tag="a" className="text-reset" id="dropdownMenuButton5" caret >
                        <span className="fw-semibold">Sort By:</span> {' '}
                        <span className="text-muted">
                        {intervalNames[selectedGraph.interval]}<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>                                                 
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={() => handleSelectGraph("daily")}>Daily</DropdownItem>
                        <DropdownItem onClick={() => handleSelectGraph("weekly")}>Weekly</DropdownItem>
                        <DropdownItem onClick={() => handleSelectGraph("monthly")}>Monthly</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    </div>
                  </div>
                  {fetchGraph!=null && <LineApexChart graphData={fetchGraph} />}
                </CardBody>
              </Card>
            </Col>
          </Row>



        </Container>
      </div>

    </div>
  )
}

export default Googlemybussiness 

