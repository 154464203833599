import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";





function ActionableCustomerReport() {

    // date
    const formatDate = (date, fullMonth = false) => {
        const options = fullMonth
            ? { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
            : { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        return new Date(date).toLocaleString('en-US', options);
    };


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showFullMonth, setShowFullMonth] = useState(false);

    const toggleShowFullMonth = () => {
        setShowFullMonth(!showFullMonth);
    };


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div>
                        <p className="titletit">Actionable Customer Post</p>
                        <h6 className="dateText" onClick={toggleShowFullMonth}>
                            {formatDate(startDate, showFullMonth)} - {formatDate(endDate, showFullMonth)}

                        </h6>
                    </div>

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <h5>Actionable Posts</h5>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )

}

export default ActionableCustomerReport;