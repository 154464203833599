import {
  GET_MENTIONS_TEST,
  GET_MENTIONS_TEST_FAIL,
  GET_MENTIONS_TEST_SUCCESS,
  GET_MENTIONS_TEST_SOCKET,
  GET_MENTIONS_TEST_SOCKET_SUCCESS,
  GET_MENTIONS_TEST_SOCKET_FAIL
} from './actionTypes';

const INIT_STATE = {
  mentions: [],
  mention: {},
  error: {},
};

const mentions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MENTIONS_TEST:
      return {
        ...state,
        params: action.payload,
      };

    case GET_MENTIONS_TEST_SUCCESS:
      return {
        ...state,
        mentions: action.payload.data,
      };

    case GET_MENTIONS_TEST_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default mentions;
