import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Select from "react-select";
import Stack from "@mui/material/Stack";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./Template.scss";

const Template = () => {
  const [templateObject, setTemplateObject] = useState({});
  const [templateIdTobeUpdated, setTemplateIdToBeUpdated] = useState(null);
  const [templateIdToBeDeleted, setTemplateIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [templateForTable, setTemplateForTable] = useState([]);
  const [templateValue, settemplateValue] = useState("");

  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});

  const [templateListForTable, setTemplateListForTable] = useState([]);

  // const [show, setShow] = useState(false);
  // const [open, setOpen] = React.useState(false);
  // const handleCloseModal = () => setShow(false);
  // const handleShowModal = () => setShow(true);
  const [tagInputData, setTagInputData] = useState([]);

  const [valueName, setValueName] = useState("");
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    handleTableListData();
  }, []);

  console.log(templateObject, "****");

  function handleClick(value) {
    let dataValue = templateObject;

    var english = dataValue?.temp_english ? dataValue.temp_english : "";
    var hindi = dataValue?.temp_hindi ? dataValue.temp_hindi : "";
    var malayalam = dataValue?.temp_malayalam ? dataValue.temp_malayalam : "";
    if (valueName === "temp_english") {
      english = english + " " + value?.name;
    } else if (valueName === "temp_hindi") {
      hindi = hindi + " " + value?.name;
    } else if (valueName === "temp_malayalam") {
      malayalam = malayalam + " " + value?.name;
    }

    setTemplateObject({
      ...templateObject,
      temp_english: english,
      temp_hindi: hindi,
      temp_malayalam: malayalam,
    });
  }
  function handleTableData() {
    var url = `${API_URL}variables/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        // let templateData = [];

        // result.map((item, index) => {
        //   item.id = index + 1;
        //   item.name = <label onClick={() => handleClick()}>{item?.name}</label>;
        //   templateData.push(item);
        // });
        setTemplateForTable(result || []);
      });
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function handleTableListData() {
    var url = `${API_URL}FAQ/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let templateListData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateTemplate(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setTemplateIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          item.date = moment(item.date).format("DD-MM-YYYY");

          if (item?.time)
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          else item.time = "";

          item.up_date = moment(item.up_date).format("DD-MM-YYYY");

          if (item?.up_time)
            item.up_time = moment(item.up_time, "HHmmss").format("hh:mm a");
          else item.up_time = "";

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          templateListData.push(item);
        });
        setTemplateListForTable(templateListData);
      });
  }

  const customStyles = {
    lineHeight: "2.8",
  };
  const customStyles1 = {
    height: "50px",
    border: "1px solid #ced4da",
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Template Name",
        field: "temp_name",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Updated Date",
      //   field: "up_date",
      //   sort: "asc",
      //   width: 270,
      // },
      // {
      //   label: "Updated Time",
      //   field: "up_time",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 270,
      },
    ],
    rows: templateListForTable,
  };

  function handleChangeTemplate(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "temp_english") setValueName("temp_english");
    else if (name === "temp_hindi") setValueName("temp_hindi");
    else if (name === "temp_malayalam") setValueName("temp_malayalam");
    else setValueName("");
    settemplateValue(value);
    setTemplateObject({ ...templateObject, [name]: value });
  }

  const handleValidSubmit = () => {
    templateObject.keywords = tagInputData;

    if (templateIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}FAQ/`, templateObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Template updated successfully");
            settemplateValue("");
            formRef.current.reset();
            handleTableListData();
            setTemplateObject({
              temp_english: "",
              temp_hindi: "",
              temp_malayalam: "",
              keywords: [],
            });
            setTagInputData([]);
            setTemplateIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update Template");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}FAQ/`, templateObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Template added Successfully");
            handleTableListData();
            settemplateValue("");
            formRef.current.reset();
            setTemplateObject({
              temp_english: "",
              temp_hindi: "",
              temp_malayalam: "",
              keywords: [],
            });
            setTagInputData([]);
          } else {
            toastr.error("Failed to add Template");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const reset = () => {
  //   formRef.current.reset();
  // };

  let preUpdateTemplate = (item) => {
    setTemplateIdToBeUpdated(item._id);
    settemplateValue(item?.name);

    axios
      .get(`${API_URL}FAQ/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTagInputData(result?.keywords);
        setTemplateObject(result);
      });
  };

  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}FAQ/data?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let addedBy = result.addedby || {};

        result.date = moment(result?.date).format("DD-MM-YYYY");
        if (result?.up_date)
          result.up_date = moment(result?.up_date).format("DD-MM-YYYY");
        else result.up_date = "";

        result.time = moment(result?.time, "hhmmss").format("hh:mm a");
        if (result.up_time)
          result.up_time = moment(result?.up_time, "hhmmss").format("hh:mm a");
        else result.up_time = "";

        let arrayValue = result?.keywords || [];
        result.keywords = "";
        arrayValue &&
          arrayValue.map((el) => {
            if (result.keywords === "") result.keywords = el;
            else result.keywords = result.keywords + "," + el;
          });

        if (addedBy?.lastName)
          result.addedBy = addedBy?.firstName + " " + addedBy?.lastName;
        else result.addedBy = addedBy?.firstName;
        setPopupData(result);
        setPopupView(true);
      });
  };
  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTagInputData([...tagInputData, event.target.value]);
      event.target.value = "";
    }
  };
  const removeTags = (indexToRemove) => {
    setTagInputData([
      ...tagInputData.filter((_, index) => index !== indexToRemove),
    ]);
  };
  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}FAQ` + "/" + templateIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Template deleted successfully");
                  if (
                    templateObject &&
                    templateObject._id === templateIdToBeDeleted
                  ) {
                    settemplateValue("");
                    formRef.current.reset();
                    setTemplateIdToBeUpdated(null);
                    setTemplateObject({
                      temp_english: "",
                      temp_hindi: "",
                      temp_malayalam: "",
                      keywords: [],
                    });
                    setTagInputData([]);
                  }
                  setTemplateIdToBeDeleted(null);
                  handleTableListData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Template");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      {/* Modal Form Start */}
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Template Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Template Name :
                </td>
                <td>{popupData?.temp_name}</td>
                <td style={{ paddingLeft: "25px" }}>Date :</td>
                <td style={{ textAlign: "left" }}>{popupData?.date}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Time :</td>
                <td>{popupData?.time}</td>
                <td style={{ paddingLeft: "25px" }}>Updated Date :</td>
                <td style={{ textAlign: "left" }}>{popupData?.up_date}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Updated Time :</td>
                <td>{popupData?.up_time}</td>
                <td style={{ paddingLeft: "25px" }}>Template English :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "200px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupData?.temp_english}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Template Hindi :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {popupData?.temp_hindi}
                </td>
                <td style={{ paddingLeft: "25px" }}>Template Malayalam :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "200px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupData?.temp_malayalam}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Keywords :</td>
                <td>{popupData?.keywords}</td>
                <td style={{ paddingLeft: "25px" }}>Staff :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupData?.addedBy}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      {/* Modal Form End */}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Template" />
          {/* First card */}
          <Row>
            <Col xl="9">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    // onValidSubmit={(e, v) => {
                    //   handleValidSubmit(e, v);
                    // }}
                  >
                    <Row>
                      <Col md="6">
                        <div className="mb-6">
                          <Label htmlFor="validationCustom02">
                            Template Name
                          </Label>
                          <AvField
                            style={customStyles}
                            name="temp_name"
                            placeholder="Template Name"
                            type="text"
                            errorMessage="Enter Template Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={templateObject?.temp_name}
                            onChange={handleChangeTemplate}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-6">
                          <Label htmlFor="validationCustom02">
                            Template English
                          </Label>

                          <textarea
                            style={customStyles1}
                            name="temp_english"
                            placeholder="Template English"
                            type="text"
                            errorMessage="Enter Template English"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={
                              templateObject?.temp_english
                                ? templateObject?.temp_english
                                : ""
                            }
                            onChange={handleChangeTemplate}
                          />
                        </div>
                      </Col>

                      <Col md="6" style={{ marginTop: "3%" }}>
                        <div className="mb-6">
                          <Label htmlFor="validationCustom02">
                            Template Hindi
                          </Label>

                          <textarea
                            style={customStyles1}
                            name="temp_hindi"
                            placeholder="Template Hindi"
                            type="text"
                            errorMessage="Enter Template Hindi"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={
                              templateObject?.temp_hindi
                                ? templateObject.temp_hindi
                                : ""
                            }
                            onChange={handleChangeTemplate}
                          />
                        </div>
                      </Col>

                      <Col md="6" style={{ marginTop: "3%" }}>
                        <div className="mb-6">
                          <Label htmlFor="validationCustom02">
                            Template Malayalam
                          </Label>

                          <textarea
                            style={customStyles1}
                            name="temp_malayalam"
                            placeholder="Template Malayalam"
                            type="text"
                            errorMessage="Enter Template Malayalam"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={
                              templateObject?.temp_malayalam
                                ? templateObject.temp_malayalam
                                : ""
                            }
                            onChange={handleChangeTemplate}
                          />
                        </div>
                      </Col>

                      <Col md="9" style={{ marginTop: "3%" }}>
                        <Label htmlFor="validationCustom02">Keywords</Label>
                        <div className="tags-input">
                          <ul id="tags">
                            {tagInputData &&
                              tagInputData.map((tag, index) => (
                                <li key={index} className="tag">
                                  <span className="tag-title">{tag}</span>
                                  <span
                                    className="tag-close-icon"
                                    onClick={() => removeTags(index)}
                                  >
                                    x
                                  </span>
                                </li>
                              ))}
                          </ul>
                          <input
                            type="text"
                            onKeyUp={(event) =>
                              event.key === "Enter" ? addTags(event) : null
                            }
                            placeholder="Press enter to add keywords"
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ marginTop: "auto" }}>
                        <div className="mb-2" style={{ paddingTop: "61px" }}>
                          {templateIdTobeUpdated ? (
                            <Button
                              color="primary"
                              // type="submit"
                              style={{ marginRight: "5%" }}
                              onClick={() => handleValidSubmit()}
                            >
                              {"Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              // type="submit"
                              style={{ marginRight: "5%" }}
                              onClick={() => handleValidSubmit()}
                            >
                              {"Submit"}
                            </Button>
                          )}
                          {/* <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              {"  "}
                              Reset
                            </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>

            {/* Second Card */}

            <Col xl="3">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Row>
                        <Col className="col-12">
                          <CardBody style={{ minHeight: "281px" }}>
                            {/* <Label>Variables</Label> */}

                            <table
                              id="product_available_day"
                              className="table table-bordered dataTable"
                            >
                              <tbody>
                                <tr>
                                  {/* <th>#</th> */}
                                  <th>Variables</th>
                                </tr>
                              </tbody>
                              <tbody>
                                {templateForTable &&
                                  templateForTable.map((el, index) => {
                                    return (
                                      <tr>
                                        {/* <td style={{ textAlign: "center" }}>
                                          {index + 1}
                                        </td> */}
                                        <td style={{ textAlign: "left" }}>
                                          <label
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleClick(el)}
                                          >
                                            {el?.name}
                                          </label>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>

                            {/* <MDBDataTable
                                id="templateTableId"
                                responsive
                                
                                bordered
                                data={variableData}
                                searching={false}
                                paging={false}
                                info={true}
                                disableRetreatAfterSorting={true}
                                entries={20}
                              /> */}
                          </CardBody>
                        </Col>
                      </Row>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Row>
                        <Col className="col-12">
                          <CardBody>
                            <MDBDataTable
                              id="faqTableId"
                              responsive
                              bordered
                              data={data}
                              info={true}
                              disableRetreatAfterSorting={true}
                              entries={20}
                            />
                          </CardBody>
                        </Col>
                      </Row>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Template;
