import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import CampDash from "../pages/Dashboard/camp_dashboard";
import ContDash from "../pages/Dashboard/cont_dashboard";
import AnalyDash from "../pages/Dashboard/analy_dashboard";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import VerifyOtp from "../pages/Authentication/otpPage";
import ResetPassword from "../pages/Authentication/resetPassword";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//security
import BlockedIP from "../pages/Security/Blocked IP/BlockedIP";
import UserBlock from "../pages/Security/User Block/UserBlock";
import UserActivityLog from "../pages/Security/User Activity Log/UserActivityLog";
import CronJobActivity from "../pages/Security/Cron Job Activity/CronJobActivity";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

//Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

/*---------------------
|Master Settings
|---------------------*/
import Company from "../pages/MasterSettings/Company";
import Privilage from "../pages/MasterSettings/Privilage/index";
import Rules from "../pages/MasterSettings/Rules";
import Modules from "../pages/MasterSettings/Module/Modules";
import SocialConfiguration from "../pages/MasterSettings/Social_Config/Social_Config";

/*---------------------
|User
|---------------------*/
import AddUser from "../pages/Add User/index";
import StaffUser from "../pages/Add User/Staff/Staff Detail/StaffDetails";

/*---------------------
|Manage
|---------------------*/
import ManageZone from "../pages/Manage/Zone/zone";
import AddBranch from "../pages/Manage/Add Branch/addBranch";
import ManageDesignation from "../pages/Manage/Designation/ManageDesignation";
import ManageDepartment from "../pages/Manage/Department/Department";
import ManageProduct from "../pages/Manage/Products/product";
import ProductCategory from "../pages/Manage/productCategory";
import Template from "../pages/Manage/Template/Template.js";

/*---------------------
|Response
|---------------------*/
import FollowUp from "../pages/Response/FollowUp/followUp";
import NewResponse from "../pages/Response/NewResponse/NewResponse";
import AllResponse from "../pages/Response/AllResponse/allResponse";
import CasesReport from "../pages/Response/orm report/casesReport.js";
import ActionableCustomerReport from "../pages/Response/orm report/actionableCustomerPost.js";

/*---------------------
|Reports
|---------------------*/
import ResponseSummary from "../pages/Reports/responseSummary";
import ProductSummary from "../pages/Reports/productSummary";

import SourceSummary from "../pages/Reports/sourceSummary";
import FollowersSummary from "../pages/Reports/followersSummary";
import SentimentSummary from "../pages/Reports/sentimentSummary";
import VolumeSummary from "../pages/Reports/volumeSummary";
import DashboardSummary from "../pages/Reports/dashboardSummary";
import BrandOriginalPosts from "../pages/Reports/monthwiseSummary/brandOriginalPosts.js";
import FansFollower from "../pages/Reports/monthwiseSummary/fansFollowersCount.js";
import VolumeReport from "../pages/Reports/monthwiseSummary/volumeReport.js";
import BreakupByMedia from "../pages/Reports/monthwiseSummary/breakupByMedia.js";
import ShareOfVoice from "../pages/Reports/monthwiseSummary/shareOfVoice.js";
import UniqueUsersReport from "../pages/Reports/uniqueUsersReport";

/*---------------------
|Social Listening
|---------------------*/
import SocialListening from "../pages/Social Listening/index";
import SocialListening_test from "../pages/Social Listening/index_test";
import TV from "../pages/TV";

/*---------------------
|Connection
|---------------------*/
import Connections from "../pages/Connections";

/*---------------------
|Social config
|---------------------*/
import SocialConfig from "../pages/SocialConfig";
import Googlemybussiness from "../pages/Gmb/Googlemybussiness.js";
import Reviews from "../pages/Social/Reviews/Reviews.js";
import Reviewreply from "../pages/Social/Reviews/Reviewreply.js";
import Messages from "../pages/Social/Messages/Messages.js";
import Newpost from "../pages/Social/Posts/Newpost.js";
import Allpost from "../pages/Social/Posts/Allpost.js";
import Socialmonitor from "../pages/Social/Socialmonitor/Socialmonitor.js";
import MediaPlatformFacebook from "../pages/Reports/MediaPlatformFacebook/index.js";
import MediaPlatformInstagram from "../pages/Reports/MediaPlatformInstagram/index.js";
import MediaPlatformTwitter from "../pages/Reports/MediaPlatformTwitter/index.js";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/content_dashboard", component: ContDash },
  { path: "/camp_dashboard", component: CampDash },
  { path: "/analy_dashboard", component: AnalyDash },

  // //profile
  { path: "/profile", component: UserProfile },

  { path: "/blockedip", component: BlockedIP },
  { path: "/userblock", component: UserBlock },
  { path: "/UserActivityLog", component: UserActivityLog },
  { path: "/CronJobActivity", component: CronJobActivity },

  /*---------------------
  |Master Settings
  |---------------------*/
  { path: "/company", component: Company },
  { path: "/privilage", component: Privilage },
  { path: "/rules", component: Rules },
  { path: "/master_modules", component: Modules },
  { path: "/social_Configuration", component: SocialConfiguration },
  /*---------------------
  |Master Settings
  |---------------------*/
  { path: "/add_user", component: AddUser },
  { path: "/add_user/:staffid", component: StaffUser },
  /*---------------------
  |Manage
  |---------------------*/
  { path: "/manage_Zone", component: ManageZone },
  { path: "/manage_addBranch", component: AddBranch },
  { path: "/manage_designation", component: ManageDesignation },
  { path: "/manage_department", component: ManageDepartment },
  { path: "/manage_Product", component: ManageProduct },
  { path: "/manage_ProductCategory", component: ProductCategory },
  { path: "/manage_template", component: Template },

  // Gmb

  { path: "/google_my_business", component: Googlemybussiness },

  // Reviews
  { path: "/reviews", component: Reviews },
  { path: "/reviews/replyreview", component: Reviewreply },
  { path: "/messages", component: Messages },

  // Posts
  { path: "/newpost", component: Newpost },
  { path: "/allpost", component: Allpost },

  // Social monitor
  { path: "/social_monitoring", component: Socialmonitor },

  /*---------------------
  |Response
  |---------------------*/
  { path: "/response_followup", component: FollowUp },
  { path: "/new_response", component: NewResponse },
  { path: "/all_response", component: AllResponse },
  { path: "/casesReport", component: CasesReport },
  { path: "/actionableReport", component: ActionableCustomerReport },

  /*---------------------
  |Reports
  |---------------------*/
  { path: "/responseSummary", component: ResponseSummary },
  { path: "/productSummary", component: ProductSummary },
  { path: "/sourceSummary", component: SourceSummary },

  { path: "/reports-followers", component: FollowersSummary },
  { path: "/reports-sentiment-report", component: SentimentSummary },
  { path: "/reports-volume-report", component: VolumeSummary },
  { path: "/reports-summary", component: DashboardSummary },
  { path: "/brandOriginalPosts", component: BrandOriginalPosts },
  { path: "/fansFollowersCount", component: FansFollower },
  { path: "/volumeReport", component: VolumeReport },
  { path: "/breakupByMedia", component: BreakupByMedia },
  { path: "/shareOfVoice", component: ShareOfVoice },
  { path: "/reports-unique-users-report", component: UniqueUsersReport },
  {
    path: "/reports-media-platform-facebook",
    component: MediaPlatformFacebook,
  },
  {
    path: "/reports-media-platform-instagram",
    component: MediaPlatformInstagram,
  },
  {
    path: "/reports-media-platform-twitter",
    component: MediaPlatformTwitter,
  },

  /*---------------------
  |Social Listening
  |---------------------*/
  { path: "/social_listening", component: SocialListening },
  { path: "/social_listening_test", component: SocialListening_test },
  { path: "/mentions", component: TV },

  { path: "/social-config", component: SocialConfig },

  /*---------------------
  |Connection
  |---------------------*/
  { path: "/connections", component: Connections },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/verify-otp", component: VerifyOtp },
  { path: "/reset-password", component: ResetPassword },
];

export { userRoutes, authRoutes };
