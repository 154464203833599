import {
  GET_PRIVILAGES,
  GET_PRIVILAGES_FAIL,
  GET_PRIVILAGES_SUCCESS,
  ADD_PRIVILAGE,
  ADD_PRIVILAGE_FAIL,
  ADD_PRIVILAGE_SUCCESS,
  DELETE_PRIVILAGE,
  DELETE_PRIVILAGE_SUCCESS,
  DELETE_PRIVILAGE_FAIL,
  UPDATE_PRIVILAGE,
  UPDATE_PRIVILAGE_SUCCESS,
  UPDATE_PRIVILAGE_FAIL,
  GET_PRIVILAGES_OPTIONS_SUCCESS,
  GET_PRIVILAGES_OPTIONS_FAIL,
  GET_PRIVILAGES_MODULES,
  GET_PRIVILAGES_MODULES_SUCCESS,
  GET_PRIVILAGES_MODULES_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  privilages: [],
  addingPrivilege: false,
  deletingPrivilege: false,
  addPrivilegeResponse: {},
  updatePrivilegeResponse: {},
  deletePrivilegeResponse: {},
  privilegeIdToBeDeleted: "",
  privilege: {},
  privilagesOptions: [],
  privilegeModules: [],
  privilegeModuleError: {},
  error: {},
};

const Privilages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRIVILAGES:
      return {
        ...state,
        params: action.payload,
      };

    case GET_PRIVILAGES_SUCCESS:
      return {
        ...state,
        privilages: action.payload.data,
      };

    case GET_PRIVILAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_PRIVILAGE:
      return {
        ...state,
        addingPrivilege: true,
        privilege: action.payload,
      };

    case ADD_PRIVILAGE_SUCCESS:
      /*let newPrivileges = state.privilages.filter((privilege) => {
          return privilege;
         });*/
      let newPrivileges = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newPrivileges.push(...state.privilages);
      return {
        ...state,
        addingPrivilege: false,
        addPrivilegeResponse: {
          type: "success",
          message: "Privilege added successfully",
        },
        privilages: newPrivileges,
        error: {},
      };

    case ADD_PRIVILAGE_FAIL:
      return {
        ...state,
        addingPrivilege: false,
        addPrivilegeResponse: {
          type: "failure",
          message: "Adding privilege failed",
        },
        error: action.payload,
      };
    case DELETE_PRIVILAGE:
      return {
        ...state,
        deletingPrivilege: true,
        privilegeIdToBeDeleted: action.payload,
      };

    case DELETE_PRIVILAGE_SUCCESS:
      let newPrivileges2 = state.privilages.filter((privilege) => {
        return privilege.privilege_id != state.privilegeIdToBeDeleted;
      });
      return {
        ...state,
        deletingPrivilege: false,
        privilegeIdToBeDeleted: "",
        deletingPrivilege: false,
        deletePrivilegeResponse: {
          type: "success",
          message: "Privilege deleted successfully",
        },
        privilages: newPrivileges2,
      };

    case DELETE_PRIVILAGE_FAIL:
      return {
        ...state,
        deletingPrivilege: false,
        deletePrivilegeResponse: {
          type: "failure",
          message: "Deleting Privilege failed",
        },
        error: action.payload,
      };

    case UPDATE_PRIVILAGE:
      return {
        ...state,
        updatingPrivilege: true,
        privilege: action.payload,
      };

    case UPDATE_PRIVILAGE_SUCCESS:
      let newPrivileges1 = state.privilages.map((item) => {
        if (item.privilege_id == state.privilege.privilege_id) {
          return action.payload.data;
        } else {
          return item;
        }
      });
      // let newprivilegearr = [];
      // let newPrivileges1 = state.privilages.filter((privilege) => {
      //   if (privilege.privilege_id == state.privilege.privilege_id) {
      //     newprivilegearr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
      //   } else {
      //     newprivilegearr.push(privilege);
      //   }
      // });

      return {
        ...state,
        updatingPrivilege: false,
        privilege: "",
        updatingPrivilege: false,
        updatePrivilegeResponse: {
          type: "success",
          message: "Privilege updated successfully",
        },
        privilages: newPrivileges1,
      };

    case UPDATE_PRIVILAGE_FAIL:
      return {
        ...state,
        updatingPrivilege: false,
        updatePrivilegeResponse: {
          type: "failure",
          message: "Updating privilege failed",
        },
        error: action.payload,
      };
    case GET_PRIVILAGES_OPTIONS_SUCCESS:
      return {
        ...state,
        privilagesOptions: action.payload,
      };

    case GET_PRIVILAGES_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // case GET_PRIVILAGES_MODULES:
    //   return {
    //     ...state,
    //     privilegeModules: action.payload,
    //   };

    case GET_PRIVILAGES_MODULES_SUCCESS:
      return {
        ...state,
        privilegeModules: action.payload.data,
      };

    case GET_PRIVILAGES_MODULES_FAIL:
      return {
        ...state,
        privilegeModuleError: action.payload,
      };
    default:
      return state;
  }
};

export default Privilages;
