import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_MENTIONS_TEST,

} from './actionTypes';

import {
  getAllMenstions_testSuccess,
  getAllMenstions_testFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  getAllMenstions_test,
} from '../../helpers/backend_helper';

function* fetchMenstion({localbody_id: localbody_id}) {
  try {
    const response = yield call(getAllMenstions_test,localbody_id);
    yield put(getAllMenstions_testSuccess(response));
  } catch (error) {
    yield put(getAllMenstions_testFail(error));
  }
}

function* mentionSaga() {
  yield takeEvery(GET_MENTIONS_TEST, fetchMenstion);
}

export default mentionSaga;
