import firebase from "firebase/compat/app";
import { getMessaging,getToken,onMessage } from "firebase/messaging";
import { setBrowserToken } from "./helpers/globalFunctions";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_NOTI_APIKEY,
  authDomain: process.env.REACT_APP_NOTI_AUTHDOMAIN,
  projectId: process.env.REACT_APP_NOTI_PROJECTID,
  storageBucket: process.env.REACT_APP_NOTI_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_NOTI_MSG_SENDER,
  appId: process.env.REACT_APP_NOTI_APPID,
  measurementId: process.env.REACT_APP_NOTI_MEASUREMENTID,
};
function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        const app = firebase.initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        getToken(messaging,{vapidKey:process.env.REACT_APP_NOTI_VAPIDKEY})
        .then((currentToken)=>{
            setBrowserToken(currentToken);
        });
        onMessage(messaging, (payload) => {
          const notiifcation = new Notification(payload.notification.title,{
            body:payload.notification.body,
            icon:process.env.REACT_APP_NOTI_ICON
        })
        });
      }
      else{
        console.log('Do not have permission');
      }
    })
}
requestPermission();