import {
  GET_MENTIONS_TEST,
  GET_MENTIONS_TEST_FAIL,
  GET_MENTIONS_TEST_SUCCESS,
} from './actionTypes';

export const getAllMenstions_test = () => ({
  type: GET_MENTIONS_TEST
});

export const getAllMenstions_testSuccess = (menstion) => ({
  type: GET_MENTIONS_TEST_SUCCESS,
  payload: menstion,
});

export const getAllMenstions_testFail = (error) => ({
  type: GET_MENTIONS_TEST_FAIL,
  payload: error,
});