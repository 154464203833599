import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Col, Row } from 'reactstrap'
import { Table, Thead, Tbody, Tr, } from "react-super-responsive-table"
import "./Newpost.scss"
import srvlogo from '../../../assets/fotos/srv11.png'
import google from '../../../assets/fotos/googlelogo.svg'
import instagram from '../../../assets/fotos/instagramlogo.svg'
import twitter from '../../../assets/fotos/twitterXlogo.svg'
import facebook from '../../../assets/fotos/facebooklogo.svg'
import linkedin from '../../../assets/fotos/linkedinlogo.svg'
import youtube from '../../../assets/fotos/youtubelogo.svg'
import post1 from '../../../assets/fotos/Rectangle 32.svg'
import post2 from '../../../assets/fotos/Rectangle 34.svg'
import post3 from '../../../assets/fotos/Rectangle 35.svg'
import gallery from '../../../assets/fotos/gallery2.svg'
import location from '../../../assets/fotos/location.svg'
import hashtag from '../../../assets/fotos/hashtag.svg'
import emoji from '../../../assets/fotos/emojiss.svg'
import share from '../../../assets/fotos/share.svg'

import ficon from '../../../assets/fotos/🦆 icon _Facebook F_.svg'
import iicon from '../../../assets/fotos/🦆 icon _Instagram_.svg'
import picon from '../../../assets/fotos/🦆 icon _Pinterest P_.svg'
import yicon from '../../../assets/fotos/🦆 icon _YouTube_.svg'
import xicon from '../../../assets/fotos/Vector (1).svg'





import { Link } from 'react-router-dom'


function Newpost() {

    const [isChecked, setIsChecked] = useState(false);

    const [isCheckbox, setIsCheckbox] = useState(true);
    const [isCheckbox1, setIsCheckbox1] = useState(true);
    const [isCheckbox2, setIsCheckbox2] = useState(true);
    const [isCheckbox3, setIsCheckbox3] = useState(true);
    const [isCheckbox4, setIsCheckbox4] = useState(true);
    const [isCheckbox5, setIsCheckbox5] = useState(true);


    useEffect(() => {

        const allIndividualCheckboxesChecked = isCheckbox && isCheckbox1 && isCheckbox2 && isCheckbox3 && isCheckbox4 && isCheckbox5;
        setIsChecked(allIndividualCheckboxesChecked);
    }, [isCheckbox, isCheckbox1, isCheckbox2, isCheckbox3, isCheckbox4, isCheckbox5]);




    const handleAllCheckboxChange = (e) => {

        const checked = e.target.checked;
        setIsCheckbox(checked);
        setIsCheckbox1(checked);
        setIsCheckbox2(checked);
        setIsCheckbox3(checked);
        setIsCheckbox4(checked);
        setIsCheckbox5(checked);
    };


    const [isAddNewButtonVisible, setIsAddNewButtonVisible] = useState(false);

    const [fileList, setFileList] = useState([]);

    const handleUploadPhotos = (e) => {
        setFileList([...fileList, ...e.target.files]);
        setIsAddNewButtonVisible(true)
    };

    const handleDeletePhoto = (index) => {
        const newFileList = fileList.filter((_, i) => i !== index);
        setFileList(newFileList);
        if (newFileList.length === 0) {
            setIsAddNewButtonVisible(false);
        }
    };









    const [show1, setShow1] = useState(false);
    // const handleShowModal = () => setShow1(true);
    const handleCloseModal = () => setShow1(false);
    const [publish, setPublish] = useState(1)
    const [ampm, setAmpm] = useState(true)

    function clickpublish(num) {

        setPublish(num)


    }
    useEffect(() => {
        clickpublish(1);
    }, []);


    console.log(publish);

    return (
        <div>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="New Post" />

                    <div className='newpost'>
                        <Row className='socialnewpost'  >
                            <Col lg="8 " className='pb-3  ' >
                                <div className='firstdiv pb-3 newpostcol'>

                                    <span style={{cursor:"pointer"}} onClick={() => setShow1((prev) => !prev)} >
                                        <div className='addnew'>
                                            <span class="fas fa-plus"></span>
                                        </div>
                                    </span>

                                    {isCheckbox ?
                                        <span >
                                            <div className='newpostprofile'>
                                                <img className='profile_img' src={srvlogo} alt="#" />
                                                <span className='profile_img1'><img className='' src={facebook} alt="#" /></span>

                                            </div>
                                        </span>
                                        : null}


                                    {isCheckbox1 ?
                                        <span >
                                            <div className='newpostprofile'>
                                                <img className='profile_img' src={srvlogo} alt="#" />
                                                <span className='profile_img1'><img className='' src={instagram} alt="#" /></span>

                                            </div>
                                        </span>
                                        : null}
                                    {isCheckbox2 ?
                                        <span >
                                            <div className='newpostprofile'>
                                                <img className='profile_img' src={srvlogo} alt="#" />
                                                <span className='profile_img1'><img className='' src={twitter} alt="#" /></span>

                                            </div>
                                        </span>
                                        : null}
                                    {isCheckbox3 ?
                                        <span >
                                            <div className='newpostprofile'>
                                                <img className='profile_img' src={srvlogo} alt="#" />
                                                <span className='profile_img1'><img className='' src={linkedin} alt="#" /></span>

                                            </div>
                                        </span>
                                        : null}
                                    {isCheckbox4 ?
                                        <span >
                                            <div className='newpostprofile'>
                                                <img className='profile_img' src={srvlogo} alt="#" />
                                                <span className='profile_img1'><img className='' src={youtube} alt="#" /></span>

                                            </div>
                                        </span>
                                        : null}
                                    {isCheckbox5 ?
                                        <span >
                                            <div className='newpostprofile'>
                                                <img className='profile_img' src={srvlogo} alt="#" />
                                                <span className='profile_img1'><img className='' src={google} alt="#" /></span>

                                            </div>
                                        </span>
                                        : null}





                                    <div>

                                    </div>





                                </div>


                                {show1 ?





















                                    <div className='flex flex-col dropdownaddnew' >
                                        <div className='channelheader'>
                                            <div><h6>Add Channel</h6></div>
                                            <div><span><button onClick={handleCloseModal} type="button" class="btn-close" aria-label="Close"></button></span></div>

                                        </div>

                                        <div className='selectchannel'>

                                            <div className='channelinput'>
                                                <input
                                                className='form-check-input'
                                                
                                                    onChange={handleAllCheckboxChange}
                                                    checked={isChecked}
                                                    type="checkbox" id="myCheckbox" /></div>

                                            <div className='selectchanneldiv'  >
                                                <label htmlFor="myCheckbox" style={{ marginBottom: "0px" }}><h6>Select All Channels</h6></label></div>




                                        </div>

                                        <div className='channelclicklist'>
                                            <div className='channellistsocial'>
                                                <div className='channelinput'>
                                                    <input className='form-check-input' type="checkbox" checked={isCheckbox} onClick={() => setIsCheckbox(!isCheckbox)} id="myCheckbox1" />
                                                </div>

                                                <div className='channelimg'>

                                                    <span >
                                                        <img className='channellogo' src={facebook} alt="#" />
                                                    </span>
                                                </div>
                                                <label htmlFor="myCheckbox1">
                                                    <div className=''><h7 className="channelfont1">Kannur Interntional Airport ..</h7>
                                                        <p>Facebook page</p>


                                                    </div>
                                                </label>

                                            </div>
                                            <div className='channellistsocial'>
                                                <div className='channelinput'><input className='form-check-input' checked={isCheckbox1} onClick={() => setIsCheckbox1(!isCheckbox1)} type="checkbox" /></div>
                                                <div className='channelimg'>
                                                    <span ><img className='channellogo' src={instagram} alt="#" /></span>
                                                </div>
                                                <div className=''><h7 className="channelfont1">Kannur Interntional Airport..</h7>
                                                    <p>Instagram page</p></div>

                                            </div>

                                            <div className='channellistsocial'>
                                                <div className='channelinput'><input className='form-check-input' checked={isCheckbox2} onClick={() => setIsCheckbox2(!isCheckbox2)} type="checkbox" /></div>
                                                <div className='channelimg'>
                                                    <span ><img className='channellogo' src={twitter} alt="#" /></span>
                                                </div>
                                                <div className=''><h7 className="channelfont1">Kannur Interntional Airport..</h7>
                                                    <p>Twitter page</p></div>

                                            </div>
                                            <div className='channellistsocial'>
                                                <div className='channelinput'><input className='form-check-input' type="checkbox" checked={isCheckbox3} onClick={() => setIsCheckbox3(!isCheckbox3)} /></div>
                                                <div className='channelimg'>
                                                    <span ><img className='channellogo' src={linkedin} alt="#" /></span>
                                                </div>
                                                <div className=''><h7 className="channelfont1">Kannur Interntional Airport...</h7>
                                                    <p>linkedin page</p></div>

                                            </div>
                                            <div className='channellistsocial'>
                                                <div className='channelinput'><input className='form-check-input' type="checkbox" checked={isCheckbox4} onClick={() => setIsCheckbox4(!isCheckbox4)} /></div>
                                                <div className='channelimg'>
                                                    <span ><img className='channellogo' src={youtube} alt="#" /></span>
                                                </div>
                                                <div className=''><h7 className="channelfont1">Kannur Interntional Airport..</h7>
                                                    <p>youtube page</p></div>

                                            </div>
                                            <div className='channellistsocial'>
                                                <div className='channelinput'><input className='form-check-input' type="checkbox" checked={isCheckbox5} onClick={() => setIsCheckbox5(!isCheckbox5)} /></div>
                                                <div className='channelimg'>
                                                    <span ><img className='channellogo' src={google} alt="#" /></span>
                                                </div>
                                                <div className=''><h7 className="channelfont1">Kannur Airport Interntional..</h7>
                                                    <p>Twitter page</p></div>

                                            </div>



                                        </div>

                                    </div>



                                    : null}

                                <div className='pt-3'><textarea style={{ borderColor: "#eeee" }} className='form-control ' rows="7" /></div>

                                <div className='d-flex pt-3 gap-3 flex-wrap postingfoto'>
                                {fileList.map((file, index) => (
                                        <div key={index}>
                                            <img src={URL.createObjectURL(file)} alt={file.name} />
                                            <button type="button" class="btn-close" aria-label="Close" onClick={() => handleDeletePhoto(index)}></button>
                                        </div>
                                    ))}                     
                                                   {/* <div><img src={post2} alt="" /><button type="button" class="btn-close" aria-label="Close"></button> </div> */}
                                    {/* <div><img src={post3} alt="" /><button type="button" class="btn-close" aria-label="Close"></button> </div> */}
                                    
                                    {isAddNewButtonVisible && (<> <input type="file" name="file-input" accept="image/*" id="file-input" onChange={handleUploadPhotos} />
                                        <label onClick={() => console.log('AddNew button clicked')} className='addnewpost' id="file-input-label" for="file-input">
                                            <div className='addnew1'><span class="fas fa-plus" ></span>
                                            </div>

                                        </label></>
                                    )}
                                    
                                    {/* <div className='addnewpost'>
                                        <span className='addnew1'>
                                            <span class="fas fa-plus"></span>
                                        </span>
                                    </div> */}

                                </div>





                            </Col>

                            <Col lg="4" className='newpostcol1'>
                                <div>


                                    <div className='d-flex'><h6>Publishing Options</h6></div>


                                    <div className={`publishnow ${publish === 1 ? "active" : ""}`}
                                    >
                                        <input onClick={() => clickpublish(1)} checked={publish === 1} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" style={{ width: "15px", height: "15px", border: "1px solid #c5bfbf" }} />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Publish Now
                                        </label>
                                    </div>
                                    <div className='publishnow' >
                                        <div className={`${publish === 2 ? "active" : ""}`} >
                                            <input onClick={() => clickpublish(2)} checked={publish === 2} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" style={{ width: "15px", height: "15px", border: "1px solid #c5bfbf" }} />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                Schedule Post
                                            </label>
                                        </div>
                                        <div className='d-flex pt-2 gap-2'>
                                            <div className='publishnowdate'> <input className='form-control' type="date" /></div>
                                            <div className='d-flex publishnowtime gap-1'><input className='form-control' type="number" /><p style={{ paddingTop: "3px" }}>:</p> <input className='form-control' type="number" /> </div>
                                            <div className='ampmbutton'>
                                                <button onClick={() => setAmpm(!ampm)} className={`${ampm === true ? "active1" : ""}`}  >AM</button>
                                                <button onClick={() => setAmpm(!ampm)} className={`${ampm === false ? "active1" : ""}`}>PM</button>

                                            </div>


                                        </div>
                                        <div className='pt-2'> <p>Time Zone: IST </p></div>


                                    </div>
                                </div>
                            </Col>



                        </Row>

                        <div className='pt-3 d-flex flex-wrap justify-content-between  '>

                            <div className='d-flex gap-2'>
                            <div style={{ cursor: "pointer" }} >
                                    <input type="file" name="file-input" accept="image/*" id="file-input" onChange={handleUploadPhotos} />
                                    <label id="file-input-label" for="file-input"
                                    ><img style={{cursor:"pointer"}} src={gallery} alt="" /> </label>
                                </div>                                <div><span><img src={location} alt="" /></span></div>
                                <div><span><img src={emoji} alt="" /></span></div>
                                <div><span><img src={hashtag} alt="" /></span></div>
                                <div><span><img src={share} alt="" /></span></div>
                            </div>

                            <div className='d-flex gap-2 newpostbutton' >
                                <div><button>Save Draft</button></div>
                                <div>{publish === 1 ? (<button className='newbutton1'>Post</button>)
                                    :
                                    (<button className='newbutton1'>Schedule</button>)}</div>
                            </div>

                        </div>


                    </div>
                    {/* newpost end */}
                    <div><h5>Recent Post</h5></div>

                    <div className='newpost '>
                        <div className='table-responsive'>

                            <Table
                                lg="12"
                                id="tech-companies-1"
                                className="table table-responsive table-bordered newposttable  ">


                                <Thead>
                                    <Tr >
                                        <th>Published On</th>
                                        <th>Post Info</th>
                                        <th>Status</th>
                                        <th>Channel</th>
                                        <th>Published By</th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <td style={{ width: "150px" }}>15 Nov 2023 10:23Am</td>
                                        <td style={{ width: "380px" }}>
                                            <div className='d-flex gap-2 '>
                                                <img style={{ width: "50px" }} src={post1} alt="" />
                                                <div><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur voluptatum quae eligendi earum.... </p></div>
                                            </div>
                                        </td>
                                        <td>Published</td>
                                        <td>
                                            <div className='d-flex gap-3'>
                                                <span><img src={ficon} alt="" /></span>
                                                <span><img src={iicon} alt="" /></span>
                                                <span><img src={picon} alt="" /></span>
                                                <span><img src={xicon} alt="" /></span>
                                                <span><img src={yicon} alt="" /></span>
                                            </div>
                                        </td>
                                        <td>srvadmin</td>
                                    </Tr>


                                    <Tr>
                                        <td style={{ width: "150px" }}>15 Nov 2023 10:23Am</td>
                                        <td style={{ width: "380px" }}>
                                            <div className='d-flex gap-2 '>
                                                <img style={{ width: "50px" }} src={post2} alt="" />
                                                <div><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur voluptatum quae eligendi earum.... </p></div>
                                            </div>
                                        </td>
                                        <td>Published</td>
                                        <td>
                                            <div className='d-flex gap-3'>
                                                <span><img src={ficon} alt="" /></span>
                                                <span><img src={iicon} alt="" /></span>
                                                <span><img src={picon} alt="" /></span>
                                                <span><img src={xicon} alt="" /></span>
                                                <span><img src={yicon} alt="" /></span>
                                            </div>
                                        </td>
                                        <td>srvadmin</td>
                                    </Tr>

                                    <Tr>
                                        <td style={{ width: "150px" }}>15 Nov 2023 10:23Am</td>
                                        <td style={{ width: "380px" }}>
                                            <div className='d-flex gap-2 '>
                                                <img style={{ width: "50px" }} src={post3} alt="" />
                                                <div><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur voluptatum quae eligendi earum.... </p></div>
                                            </div>
                                        </td>
                                        <td>Published</td>
                                        <td>
                                            <div className='d-flex gap-3'>
                                                <span><img src={ficon} alt="" /></span>
                                                <span><img src={iicon} alt="" /></span>
                                                <span><img src={picon} alt="" /></span>
                                                <span><img src={xicon} alt="" /></span>
                                                <span><img src={yicon} alt="" /></span>
                                            </div>
                                        </td>
                                        <td>srvadmin</td>
                                    </Tr>
                                </Tbody>

                            </Table>

                        </div>




                    </div>
                </div>
            </div>
        </div>


    )
}

export default Newpost