import React, { useEffect, useState } from "react";

import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function MediaPlatformInstagram() {
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };

    return new Date(date).toLocaleString("en-US", options);
  };

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [showFullMonth, setShowFullMonth] = useState(false);

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth);
  };

  const fromDate = moment(startDate).format("YYYY-MM-DD");
  const toDate = moment(endDate).format("YYYY-MM-DD");

  const [sentimentCount, setSentimentCount] = useState();
  const [sentimentCountPercentage, setSentimentCountPercentage] = useState({
    series: [],
    labels: [],
  });
  const [metrics, setMetrics] = useState();

  function fetchSentimentCount() {
    var url = `${API_URL}report/v2/sentiment/countForSocialMedia?socialMedia=${"Instagram"}&fromDate=${fromDate}&toDate=${toDate}`;

    axios
      .get(url, { headers: { "x-access-token": accessToken } })
      .then((res) => {
        let result = res?.data?.data;
        setSentimentCount(result);
      });
  }

  function fetchSentimentCountPercentage() {
    var url = `${API_URL}report/v2/sentiment/countPercentageForSocialMedia?socialMedia=${"Instagram"}&fromDate=${fromDate}&toDate=${toDate}`;

    axios
      .get(url, { headers: { "x-access-token": accessToken } })
      .then((res) => {
        let result = res?.data?.data;
        setSentimentCountPercentage(result);
      });
  }

  function fetchMetrics() {
    var url = `${API_URL}report/v2/mediaPlatform/instagram/metrics?fromDate=${fromDate}&toDate=${toDate}`;

    axios
      .get(url, { headers: { "x-access-token": accessToken } })
      .then((res) => {
        let result = res?.data?.data;
        setMetrics(result);
      });
  }

  useEffect(() => {
    fetchSentimentCount();
    fetchSentimentCountPercentage();
    fetchMetrics();
  }, []);

  // bar chart
  const series1 = sentimentCount || "";

  const option1 = {
    chart: {
      type: "bar",
      height: 600,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: ["KIAL"],
    },
    colors: ["#98d8a9", "#ff928b", "#ffdf97"],
  };

  // pie chart
  const series2 = sentimentCountPercentage?.series;

  const options2 = {
    labels: sentimentCountPercentage?.labels,
    colors: ["#98d8a9", "#ff928b", "#ffdf97"],
    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Instagram Dashboard</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>

          <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">
                      {metrics?.impressions.toLocaleString()}
                    </span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Impressions
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">
                      {metrics?.profile_views.toLocaleString()}
                    </span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Profile Views
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">
                      {metrics?.reach.toLocaleString()}
                    </span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Reach
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">
                      {metrics?.followers.toLocaleString()}
                    </span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Followers
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">
                      {metrics?.volume.toLocaleString()}
                    </span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Total Volume
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{ rowGap: "20px" }}>
            {/* bar 1*/}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Sentiment Breakup</h5>
                  <ReactApexChart
                    height="200"
                    options={option1}
                    series={series1}
                    type="bar"
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col>

            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Sentiment Percentage</h5>

                  <ReactApexChart
                    type="pie"
                    height="200"
                    className="apex-charts"
                    options={options2}
                    series={series2}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MediaPlatformInstagram;
