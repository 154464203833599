import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import io from "socket.io-client";
// import socketIOClient from "socket.io-client";
// import Select from "react-select";
import { getAllMenstions } from "../../store/actions";
import { apiError } from "../../store/actions";
// import { styled } from "@mui/material/styles";
// import Badge from "@mui/material/Badge";
// import Avatar from "@mui/material/Avatar";
// import Stack from "@mui/material/Stack";
import { getDate, getFirstday } from "../../helpers/globalFunctions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

// import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

// import TwitterIcon from "@mui/icons-material/Twitter";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
// import SendRoundedIcon from "@mui/icons-material/SendRounded";

// import Tooltip from "@mui/material/Tooltip";

import "./index.scss";

// import Select from "react-select";

//Import Breadcrumb
// import twitterMention from "../../assets/images/twitterMention.svg";
// import TwitterDirectMessege from "../../assets/images/TwitterDirectMessege.svg";
import Select from "react-select";
import { get, put } from "../../helpers/api_helper";
const SrvSocial = (props) => {
  const [filterObject, setFilterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [srvsocialObject, setsrvsocialObject] = useState({});
  // const [aadhareNo, setAadhareNo] = useState("");

  const [srvsocialIdToBeIgnored, setsrvsocialIdToBeIgnored] = useState(null);
  // const [srvsocialIdToBeDeleted, setsrvsocialIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  const [srvsocialForTable, setsrvsocialForTable] = useState([]);
  const [userMentionData, setUserMentionData] = useState({});
  // const [selectedSearchDepartment, setSelectedSearchDepartment] =
  //   useState(null);
  // const [showReplyReceipt, setShowReplyReceipt] = useState(false);
  const { mentions } = useSelector((state) => state.mentions);
  // const [mentions, setMentions] = useState([]);
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [show, setShow] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [popupFollowUpData, setpopupFollowUpData] = useState([]);

  // const [selectedSource, setSelectedSource] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // const [sourceOptions, setSourceOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [addFollowUpData, setAddFollowUpData] = useState({});
  // const [selectedStatus, setSelectedStatus] = useState(null);
  // const [selectedCommunication, setSelectedCommunication] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [templateLanguage, setTemplateLanguage] = useState({});
  // const [mediaDetails, setMediaDetails] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [searchDepartment, setSearchDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [sourceType, setSourceType] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [imageArray, setImageArray] = useState([]);
  const formRef = useRef();
  // const formRefFollowUp = useRef();

  useEffect(() => {
    // fetch_source();
    fetch_template();
    fetch_all_SearchDepartment();
    getSourceType();
  }, []);

  useEffect(() => {
    // getAllMentions(filterObject);
    dispatch(getAllMenstions(filterObject));
  }, [filterObject]);

  // const getAllMentions = (fltr) => {
  //   get(
  //     `twitter/getmention?from_date=${fltr?.from_date}&to_date=${fltr?.to_date}&source=${fltr?.source}`
  //   ).then((res) => setMentions(res.data || [{}]));
  // };

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
    }),
  };
  const updateDepartment = (selected, item) => {
    let data = {
      _id: item?._id,
      reference_id: item?.id ? item.id : item.post_id,
      department: selected?.value,
    };
    axios
      .post(`${API_URL}social/assignDepartment`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(getAllMenstions(filterObject));
        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };

  function getSourceType() {
    axios.get(`${API_URL}twitter/source-type`).then((res) => {
      let options = [];
      let data = res?.data?.data;
      options = data?.map((item) => {
        return { label: item?.source_name, value: item?._id };
      });
      setSourceType(options);
    });
  }
  function resetFilter() {
    setFilterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedSource(null);
    setSelectedStatus(null);
  }
  function handleFltrInput(e) {
    let { name, value } = e.target;
    setFilterObject((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  }

  function fetch_all_SearchDepartment() {
    axios
      .get(`${API_URL}department/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var searchdepartmentdata =
          res?.data?.data &&
          res?.data?.data?.map((el) => {
            return {
              label: el?.depart_Name,
              value: el?._id,
            };
          });
        setSearchDepartment(searchdepartmentdata);
      });
  }

  function timeSince(date, now_date) {
    var seconds = Math.floor((now_date - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " yrs";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hrs";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " min";
    }
    return Math.floor(seconds) + " sec";
  }

  const fetch_template = () => {
    axios
      .get(`${API_URL}FAQ/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let template_Data =
          res?.data?.data &&
          res?.data?.data?.map((el) => {
            return {
              label: el?.temp_name,
              value: el?._id,
              temp_english: el?.temp_english,
              temp_hindi: el?.temp_hindi,
              temp_malayalam: el?.temp_malayalam,
            };
          });
        setTemplateOptions([
          {
            options: template_Data,
          },
        ]);
      });
  };

  function handleTableData() {
    let acctypeDuplicate = JSON.parse(JSON.stringify(mentions));
    // acctypeDuplicate = acctypeDuplicate.slice(20, 41);
    let acctypeData = [];
    acctypeDuplicate?.map((item, index) => {
      var replay_cursor = "pointer";
      var hide_cursor = "pointer";
      var ignore_cursor = "pointer";
      var delete_cursor = "pointer";
      var hide_class = "fas fa-eye-slash";
      var hide_label = "Hide";
      var globe_cursor = "pointer";
      var closed_cursor = "pointer";
      if (item?.social_media == "Facebook" && item?.category == "mention") {
        replay_cursor = "not-allowed";
        closed_cursor = "not-allowed";
        hide_cursor = "not-allowed";
        delete_cursor = "not-allowed";
        ignore_cursor = "pointer";
        globe_cursor = "not-allowed";
      } else if (
        item?.social_media == "Facebook" &&
        item?.category == "comment"
      ) {
        globe_cursor = "pointer";
        delete_cursor = "not-allowed";
        ignore_cursor = "pointer";
        if (typeof item?.hide_status == "undefined" || item?.hide_status == 1) {
          hide_class = "fas fa-eye-slash";
          hide_label = "Hide";
        } else {
          hide_class = "fas fa-eye";
          hide_label = "Unhide";
        }
      } else if (
        item?.social_media == "Instagram" &&
        item?.category == "mentions"
      ) {
        globe_cursor = "not-allowed";
      } else if (item?.social_media == "Instagram") {
        hide_cursor = "not-allowed";
        delete_cursor = "not-allowed";
        ignore_cursor = "pointer";
        globe_cursor = "pointer";
      } else {
        hide_cursor = "not-allowed";
        delete_cursor = "not-allowed";
        globe_cursor = "not-allowed";
      }
      item.action = (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Box sx={{ "& button": { m: 1 } }}> */}
            {replay_cursor !== "not-allowed" ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    Replay
                  </Tooltip>
                }
              >
                <i
                  className="fas fa-comment-medical"
                  style={{
                    fontSize: "1em",
                    cursor: replay_cursor,
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    // showReplyForm(item);
                    if (replay_cursor == "pointer") handleClickopenForm(item);
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}
            {hide_cursor !== "not-allowed" ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {hide_label}
                  </Tooltip>
                }
              >
                <i
                  className={hide_class}
                  style={{
                    fontSize: "1em",
                    cursor: hide_cursor,
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    if (hide_cursor == "pointer") {
                      handleHideUnhide(item?.hide_status, item?._id, index);
                    }
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Ignore
                </Tooltip>
              }
            >
              <i
                className="fas fa-ban"
                style={{
                  fontSize: "1em",
                  cursor: ignore_cursor,
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  if (ignore_cursor == "pointer") {
                    setsrvsocialIdToBeIgnored(item?._id);
                    setConfirmDeleteAlert(true);
                  }
                }}
              ></i>
            </OverlayTrigger>

            {globe_cursor !== "not-allowed" && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    Open
                  </Tooltip>
                }
              >
                <i
                  className="fas fa-globe"
                  style={{
                    fontSize: "1em",
                    cursor: globe_cursor,
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    window.open(item?.postlink_url);
                  }}
                ></i>
              </OverlayTrigger>
            )}
            {closed_cursor !== "not-allowed" ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    Close
                  </Tooltip>
                }
              >
                <i
                  className="mdi mdi-close-circle"
                  style={{
                    fontSize: "1.3em",
                    cursor: closed_cursor,
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    if (closed_cursor == "pointer") handleClose(item._id);
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}
          </div>
        </>
      );
      item.id2 = index + 1;

      let imageType = item?.source_type || {};

      if (imageType[0]?.icon) {
        item.socialMedia = (
          // <div style={{ display: "flex", justifyContent: "center" }}>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip
                id="button-tooltip-2"
                style={{ position: "absolute", top: "-30px" }}
              >
                {imageType[0]?.source_name}
              </Tooltip>
            }
          >
            <img
              style={{ cursor: "pointer" }}
              src={`${API_URL}uploads/twitter/${imageType[0]?.icon}`}
              height="30"
              width="50"
              alt={item?.id2}
            />
          </OverlayTrigger>
          // </div>
        );
      } else item.socialMedia = "";

      item.time = moment(item?.time, "HHmmss").format("hh:mm a"); // 24hours
      item.date = moment(item?.date).format("DD-MM-YYYY");
      item.date = (
        <span>
          {item?.date}
          <br></br>
          {item?.time}
        </span>
      );
      let strLength = item?.text.length;
      var message = item?.text;
      if (strLength && strLength > 40) {
        var length = 40;
        message = message.substring(0, length) + "...";
      }
      // item.textMessage = item?.text

      item.textMessage = (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip
                id="button-tooltip-2"
                style={{ position: "absolute", top: "-30px" }}
              >
                {item?.text}
              </Tooltip>
            }
          >
            <div>{message}</div>
          </OverlayTrigger>
        </>
      );
      let selectedDepartments = null;
      if (item?.department_info[0]) {
        item.department = item?.department_info[0]?.depart_Name;
      } else {
        item.department = (
          <div className="mb-3">
            <Select
              //styles={style}
              menuPosition="fixed"
              name="department"
              value={selectedDepartments}
              onChange={(selected) => {
                updateDepartment(selected, item);
              }}
              options={searchDepartment}
              classNamePrefix="select2-selection"
            />
          </div>
        );
      }
      item.tnt = timeSince(
        new Date(moment(item?.createAt).format("YYYY-MM-DD HH:mm:ss")),
        new Date()
      );

      acctypeData.push(item);
    });

    setsrvsocialForTable(acctypeData);
  }

  function handleClose(id) {
    put(`twitter/status?id=${id}`).then((res) => {
      if (res.status) {
        dispatch(getAllMenstions(filterObject));
        toastr.success(res.message);
      }
    });
  }

  const handleClickopenForm = (data) => {
    let sourceName = data?.source_type || {};

    let sourceType;
    if (sourceName[0]?.source_name) sourceType = sourceName[0]?.source_name;
    else sourceType = [];

    if (sourceType === "Twitter Mention") {
      axios
        .get(`${API_URL}twitter/reply/view?id = ` + data?.id, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let result = res?.data?.data[0];

          let arrayFirstIndex = [
            {
              date: result?.date,
              time: result?.time,
              src_icon: result?.src_icon,
              text: result?.text,
              social_status: result?.social_status,
              addedby: result?.addedby,
              type: result?.type ? result?.type : "",
              storedmedia: result?.storedmedia ? result?.storedmedia : [],
              createAt: result?.createAt,
            },
          ];
          result.tnt = timeSince(
            new Date(moment(result?.createAt).format("YYYY-MM-DD HH:mm:ss")),
            new Date()
          );

          let followUp = result?.followup || [];

          let newArray = followUp.concat(arrayFirstIndex);
          newArray &&
            newArray?.map((el, index) => {
              el.id = index + 1;
              el.time = moment(el?.time, "hhmmss").format("hh:mm a");
              el.date = (
                <span>
                  {el?.date}
                  <br></br>
                  {el?.time}
                </span>
              );
              //if (index === 0) {
              if (el?.type === "photo") {
                el.screenshot = (
                  <div
                    className="mb-3 mt-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Row>
                      {el?.storedmedia &&
                        el?.storedmedia?.map((image) => {
                          return (
                            <Col>
                              <img alt="" width="50" height="50" src={image} />
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                );
              } else if (el?.type === "video" || el?.type === "animated_gif") {
                el.screenshot = (
                  <div className="mb-3 mt-2">
                    <Row>
                      {el?.storedmedia &&
                        el?.storedmedia?.map((video) => {
                          return (
                            <Col>
                              <video
                                className="VideoInput_video"
                                width="110"
                                height="100"
                                controls
                                src={video}
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                );
              } else el.screenshot = "";
              //}
              el.social_media = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/twitter/${el?.src_icon}`}
                    height="30"
                    width="50"
                  />
                </div>
              );
            });

          let mention = result?.mention || [];

          let mentionName = result?.author_username
            ? "@" + result?.author_username
            : "";

          mention &&
            mention?.map((el) => {
              if (mentionName === "") mentionName = "@" + el;
              else mentionName = mentionName + " " + "@" + el;
            });

          setPopupData(result);
          setSelectedLanguage({
            label: "English",
            value: 1,
          });
          setAddFollowUpData({
            id: data?.id,
            mentionname: mentionName,
            authName: result?.author_name,
            status: 3,
            source: result?.source_type,
            communication_type: 2,
            author_id: result?.author_id,
          });
          setImageArray([]);
          setpopupFollowUpData(newArray);
          setShow(true);
        });
    } else if (sourceType === "Twitter Message") {
      axios
        .get(`${API_URL}twitter/dm/view?id=` + data?.id, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let result = res?.data?.data[0];

          // let arrayFirstIndex = [
          //   {
          //     date: result?.date,
          //     time: result?.time,
          //     src_icon: result?.src_icon,
          //     text: result?.text,
          //     social_status: "New",
          //     addedby: result?.addedby,
          //     type: result?.type ? result.type : "",
          //     storedmedia: result?.storedmedia ? result.storedmedia : [],
          //     createAt: result?.createAt,
          //   },
          // ];
          let arrayFirstIndex = [];
          result.tnt = timeSince(
            new Date(moment(result.createAt).format("YYYY-MM-DD HH:mm:ss")),
            new Date()
          );

          let followUp = result?.followup || [];

          let newArray = followUp.concat(arrayFirstIndex);
          newArray &&
            newArray?.map((el, index) => {
              el.id = index + 1;
              el.time = moment(el?.time, "hhmmss").format("hh:mm a");
              el.date = (
                <span>
                  {el?.date}
                  <br></br>
                  {el?.time}
                </span>
              );
              el.social_status = el?.category;
              if (index === 0) {
                if (el?.type === "photo") {
                  el.screenshot = (
                    <div
                      className="mb-3 mt-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Row>
                        {el?.storedmedia &&
                          el?.storedmedia?.map((image) => {
                            return (
                              <Col>
                                <img
                                  alt=""
                                  width="50"
                                  height="50"
                                  src={image}
                                />
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  );
                } else if (
                  el?.type === "video" ||
                  el?.type === "animated_gif"
                ) {
                  el.screenshot = (
                    <div className="mb-3 mt-2">
                      <Row>
                        {el?.storedmedia &&
                          el?.storedmedia?.map((video) => {
                            return (
                              <Col>
                                <video
                                  className="VideoInput_video"
                                  width="110"
                                  height="100"
                                  controls
                                  src={video}
                                />
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  );
                } else el.screenshot = "";
              }
              el.social_media = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/twitter/${el?.src_icon}`}
                    height="30"
                    width="50"
                  />
                </div>
              );
            });

          let mention = result?.mention || [];

          let mentionName = result?.author_username
            ? "@" + result?.author_username
            : "";

          mention &&
            mention?.map((el) => {
              if (mentionName === "") mentionName = "@" + el;
              else mentionName = mentionName + " " + "@" + el;
            });

          setPopupData(result);
          setSelectedLanguage({
            label: "English",
            value: 1,
          });
          setAddFollowUpData({
            id: data?.id,
            mentionname: mentionName,
            authName: result?.author_name,
            status: 3,
            source: result?.source_type,
            communication_type: 2,
            author_id: result?.author_id,
          });
          setImageArray([]);
          setpopupFollowUpData(newArray);
          setShow(true);
        });
    } else if (
      data.social_media == "Instagram" ||
      data.social_media == "Facebook"
    ) {
      axios
        .get(
          `${API_URL}twitter/reply_new/view?pid = ` +
          data?.post_id +
          "&id=" +
          data?._id,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          let result = res.data.data[0];
          setSelectedDepartment({
            label: result?.department_name,
            value: result?.department,
          });
          let arrayFirstIndex = [
            {
              _id: result?._id,
              date: result?.date,
              time: result?.time,
              src_icon: result?.src_icon,
              text: result?.text,
              social_status: result?.social_status,
              addedby: result?.addedby,
              type: result?.type ? result.type : "",
              storedmedia: result?.storedmedia ? result?.storedmedia : [],
              createAt: result?.createAt,
            },
          ];
          result.tnt = timeSince(
            new Date(moment(result.createAt).format("YYYY-MM-DD HH:mm:ss")),
            new Date()
          );

          let followUp = result.followup || [];

          var newArray = followUp?.concat(arrayFirstIndex);
          newArray?.sort((a, b) => (a?._id > b?._id ? -1 : 1));
          newArray &&
            newArray?.map((el, index) => {
              el.id = index + 1;
              el.time = moment(el?.time, "hhmmss").format("hh:mm a");
              el.date = (
                <span>
                  {el?.date}
                  <br></br>
                  {el?.time}
                </span>
              );
              //if (index === 0) {
              if (el?.type === "photo") {
                el.screenshot = (
                  <div
                    className="mb-3 mt-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Row>
                      {el?.storedmedia &&
                        el?.storedmedia?.map((image) => {
                          return (
                            <Col>
                              <img alt="" width="50" height="50" src={image} />
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                );
              } else if (el?.type === "video" || el?.type === "animated_gif") {
                el.screenshot = (
                  <div className="mb-3 mt-2">
                    <Row>
                      {el?.storedmedia &&
                        el?.storedmedia?.map((video) => {
                          return (
                            <Col>
                              <video
                                className="VideoInput_video"
                                width="110"
                                height="100"
                                controls
                                src={video}
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                );
              } else el.screenshot = "";
              //}
              el.social_media = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/twitter/${el?.src_icon}`}
                    height="30"
                    width="50"
                  />
                </div>
              );
            });

          let mention = result.mention || [];

          let mentionName = result?.author_username
            ? "@" + result?.author_username
            : "";

          mention &&
            mention?.map((el) => {
              if (mentionName === "") mentionName = "@" + el;
              else mentionName = mentionName + " " + "@" + el;
            });

          setPopupData(result);
          setSelectedLanguage({
            label: "English",
            value: 1,
          });
          var addFollowObject = {
            id: data?._id,
            mentionname: mentionName,
            authName: result?.author_name,
            status: 3,
            source: result?.source_type,
            communication_type: 2,
            author_id: result?.author_id,
          };
          if (data.social_media == "Facebook") {
            addFollowObject.sm = "fb-comment";
          } else if (
            data.social_media == "Instagram" &&
            data.category == "mentions" &&
            data.type == "post"
          ) {
            addFollowObject.sm = "insta-mention-post";
          } else if (
            data.social_media == "Instagram" &&
            data.category == "mentions" &&
            data.type == "comment"
          ) {
            addFollowObject.sm = "insta-mention-cmd";
          } else if (
            data.social_media == "Instagram" &&
            data.category == "comments"
          ) {
            addFollowObject.sm = "insta-cmd";
          }
          setAddFollowUpData(addFollowObject);
          setImageArray([]);
          setpopupFollowUpData(newArray);
          setShow(true);
        });
    }
  };

  const handleHideUnhide = (status, id) => {
    if (typeof status == "undefined") status = 1;
    if (status == 1) {
      status = 0;
      var msg = "Comment is hidden";
    } else {
      status = 1;
      var msg = "The comment is visible";
    }
    axios
      .post(
        `${API_URL}facebook/comment/hide?status=` + status + "&id=" + id,
        {},
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === true) {
          toastr.success(msg);
          dispatch(getAllMenstions(filterObject));
        } else {
          toastr.error("Something went wrong");
        }
      })
      .catch((err) => {
        toastr.error("Something went wrong");
      });
  };

  const handleCloseModal = () => {
    // setSelectedCommunication(null);
    setSelectedLanguage(null);
    setSelectedDepartment(null);
    // setSelectedSource(null);
    // setSelectedStatus(null);
    setSelectedTemplate(null);
    setAddFollowUpData({});
    setImageArray([]);
    setShow(false);
  };

  useEffect(() => {
    // const sockets = io(API_URL, { transports: ["websocket"] });
    // sockets.on("receipt", (msg) => {
    // dispatch(getAllMenstions());
    // });
    // return () => sockets.disconnect();

    const sockets = io(API_URL, {
      reconnect: false,
      transports: ["websocket"],
      allowUpgrades: false,
      pingTimeout: 180000,
      pingInterval: 25000,
    });
    sockets.on("mention", (msg) => {
      dispatch(getAllMenstions(filterObject));
    });
    return () => sockets.disconnect();
  }, []);

  useEffect(() => {
    handleTableData();
  }, [mentions]);

  function MyToolTip({ text, children }) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip
            id="tooltip-top"
            style={{
              position: "absolute",
              top: "-25px",
            }}
          >
            {text}
          </Tooltip>
        }
      >
        <>{children}</>
      </OverlayTrigger>
    );
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "TAT",
        field: "tnt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Source",
        field: "socialMedia",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "author_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Message",
        field: "textMessage",
        sort: "asc",
        width: 100,
      },
      {
        label: "Department",
        field: "department",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Category",
      //   field: "category",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Status",
        field: "social_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: srvsocialForTable,
  };

  const popupFolloupData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "TAT",
      //   field: "tnt",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Source",
        field: "social_media",
        sort: "asc",
        width: 100,
      },
      {
        label: "Comments",
        field: "text",
        sort: "asc",
        width: 100,
      },

      {
        label: "Attachments",
        field: "screenshot",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "social_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "addedby",
        sort: "asc",
        width: 150,
      },
    ],
    rows: popupFollowUpData,
  };

  // const handleValidSubmit = () => {
  //   axios
  //     .post(`${API_URL}twitter/reply`,userMentionData, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         toastr.success("Reply Sent successfully");
  //         // handleTableData();
  //         dispatch(getAllMenstions());
  //         setUserMentionData({});
  //         setShowReplyReceipt(false);
  //         formRef.current.reset();
  //       } else {
  //         toastr.error("Failed to Send Reply");
  //         return;
  //       }
  //     })
  //     .catch((err) => {
  //       toastr.error(err.response.data.message);
  //       return;
  //     });
  // };
  // const handlePopupInputChange = (e) => {
  //   setUserMentionData({
  //     ...userMentionData,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      // case "status":
      //   setSelectedStatus(selected);
      //   setAddFollowUpData({
      //     ...addFollowUpData,
      //     status: selected.value,
      //   });
      //   break;
      // case "source":
      //   setSelectedSource(selected);
      //   setAddFollowUpData({
      //     ...addFollowUpData,
      //     source: selected.value,
      //   });
      //   break;
      // case "communication":
      //   setSelectedCommunication(selected);
      //   setAddFollowUpData({
      //     ...addFollowUpData,
      //     communication_type: selected.value,
      //   });
      //   break;
      case "template":
        setSelectedTemplate(selected);
        setTemplateLanguage({
          temp_english: selected?.temp_english,
          temp_hindi: selected?.temp_hindi,
          temp_malayalam: selected?.temp_malayalam,
          value: selected?.value,
        });
        fetch_comments(selected, selectedLanguage);
        break;
      case "language":
        setSelectedLanguage(selected);
        fetch_comments(templateLanguage, selected);
        break;
      case "department":
        setSelectedDepartment(selected);
        break;
      default:
        break;
    }
  };
  const fetch_comments = (templateLanguage, language) => {
    var comment = "";
    if (language?.label === "English") {
      comment = templateLanguage?.temp_english
        ? templateLanguage.temp_english
        : "";
    } else if (language?.label === "Hindi") {
      comment = templateLanguage?.temp_hindi ? templateLanguage.temp_hindi : "";
    } else if (language?.label === "Malayalam") {
      comment = templateLanguage?.temp_malayalam
        ? templateLanguage.temp_malayalam
        : "";
    } else comment = "";

    if (comment !== "")
      comment = comment.replace("##name##", addFollowUpData?.authName);

    setAddFollowUpData({
      ...addFollowUpData,
      faq_type: language?.value,
      text: comment,
      faq: templateLanguage?.value,
    });
  };
  const handleChangePopupInput = (e) => {
    setAddFollowUpData({
      ...addFollowUpData,
      [e.target.name]: e.target.value,
    });
  };
  // const handleChangePopupCheckbox = (e) =>{
  //   setAddFollowUpData({

  //   })
  // }
  const handleValidSubmitFollowup = () => {
    if (addFollowUpData?.sm == "fb-comment") {
      var url = `${API_URL}facebook/comment/replay`;
    } else if (addFollowUpData?.sm == "insta-mention-post") {
      var url = `${API_URL}instagram/post-replay`;
    } else if (addFollowUpData?.sm == "insta-mention-cmd") {
      var url = `${API_URL}instagram/mention-replay`;
    } else if (addFollowUpData?.sm == "insta-cmd") {
      var url = `${API_URL}instagram/comment-replay`;
    } else {
      var url = `${API_URL}twitter/reply`;
    }
    axios
      .post(url, addFollowUpData, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Follow up added successfully");
          setShow(false);
          // setSelectedCommunication(null);
          setSelectedLanguage(null);
          // setSelectedSource(null);
          // setSelectedStatus(null);
          setSelectedTemplate(null);
          setSelectedDepartment(null);
          setAddFollowUpData({});
          setImageArray([]);
          dispatch(getAllMenstions(filterObject));
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}twitter/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          var filename = response.data.file[0]?.filename;
          if (imageArray && imageArray.length > 3) {
            toastr.error("Image upload limit exeeded");
            return;
          } else {
            let arr = imageArray;
            arr.push(filename);
            setImageArray(arr);
            setAddFollowUpData({
              ...addFollowUpData,
              [`screenshot`]: arr,
            });
          }
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteTwitterImage = (index) => {
    const image = [...imageArray];
    image.splice(index, 1);
    setImageArray(image);
    setAddFollowUpData({
      ...addFollowUpData,
      [`screenshot`]: image,
    });
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          // onConfirm={() => {
          // dispatch(deleteCategory(categoryIdToBeDeleted));
          //   setConfirmDeleteAlert(false);
          // }}
          onConfirm={() => {
            axios
              .delete(`${API_URL}twitter` + "/" + srvsocialIdToBeIgnored, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  dispatch(getAllMenstions(filterObject));
                  toastr.success("Mention Ignored Successfully");

                  if (
                    srvsocialObject &&
                    srvsocialObject?.id === userMentionData
                  ) {
                    formRef.current.reset();

                    setsrvsocialObject({});
                  }
                  dispatch(getAllMenstions(filterObject));
                  setsrvsocialIdToBeIgnored(null);
                } else {
                  toastr.error(res.data.message, "Failed to Ignore Mention");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to Ignore it?
        </SweetAlert>
      ) : null}
      <Modal size="xl" show={show} centered={true} className="modal-fullscreen">
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {/* {popupData?.contact_id}
            {"-"} */}
            {popupData?.contact_name}
          </h5>
          <button
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={() => {
            handleValidSubmitFollowup();
          }}
        >
          <div className="row modal-body">
            <div className="col-xl-3">
              <div
                className="card"
                style={{
                  // width: "fit-content",
                  // width: "351px",
                  height: "max-content",
                  boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                }}
              >
                <div className="card-body" style={{ paddingTop: "0" }}>
                  <Row>
                    <Col xl="12">
                      <Col md="12">
                        <div className="mb-10">
                          <p className="mb-1">
                            TAT :{" "}
                            <span
                              className="font-size-14"
                              style={{ fontWeight: "bold" }}
                            >
                              {popupData?.tnt}
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-3">
                          <Select
                            name="department"
                            placeholder="Assign Department"
                            value={selectedDepartment}
                            options={searchDepartment}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              setSelectedDepartment(selected);
                              updateDepartment(selected, popupData);
                            }}
                          />
                        </div>
                      </Col>
                    </Col>
                  </Row>

                  <h4 className="modal-title mt-0">Contact Details</h4>
                  <div className="mt-2" hidden={!popupData?.custId}>
                    <p className="mb-1">ID:</p>
                    <h5 className="font-size-14">{popupData?.custId}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.acctNo}>
                    <p className="mb-1">Acc No:</p>
                    <h5 className="font-size-14">{popupData?.acctNo}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.cardNo}>
                    <p className="mb-1">Card No:</p>
                    <h5 className="font-size-14">{popupData?.cardNo}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.loanNo}>
                    <p className="mb-1">Loan No:</p>
                    <h5 className="font-size-14">{popupData?.loanNo}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.email}>
                    <p className="mb-1">Email:</p>
                    <h5 className="font-size-14">{popupData?.email}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.mobile}>
                    <p className="mb-1">Mobile:</p>
                    <h5 className="font-size-14">{popupData?.mobile}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.twitterId}>
                    <p className="mb-1">Twitter ID:</p>
                    <h5 className="font-size-14">{popupData?.twitterId}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.fbId}>
                    <p className="mb-1">Facebook ID:</p>
                    <h5 className="font-size-14">{popupData?.fbId}</h5>
                  </div>
                  <div className="mt-2" hidden={!popupData?.instaId}>
                    <p className="mb-1">Instagram ID:</p>
                    <h5 className="font-size-14">{popupData?.instaId}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <div
                className="card"
                style={{
                  height: "max-content",
                  boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                }}
              >
                <div className="card-body" style={{ paddingTop: "0" }}>
                  <Row>
                    <Col xl="12">
                      <CardBody>
                        <Row>
                          {/* <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">Status</Label>
                              <Select
                                name="status"
                                value={selectedStatus}
                                options={[
                                  {
                                    label: "New",
                                    value: 0,
                                  },
                                  {
                                    label: "Pending",
                                    value: 1,
                                  },
                                  {
                                    label: "Closed",
                                    value: 2,
                                  },
                                  {
                                    label: "Responded",
                                    value: 3,
                                  },
                                ]}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "status");
                                }}
                              />
                            </div>
                          </Col> */}
                          {/* <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">Source</Label>
                              <Select
                                name="Source"
                                value={selectedSource}
                                options={sourceOptions}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "source");
                                }}
                              />
                            </div>
                          </Col> */}
                          {/* <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Communication
                              </Label>
                              <Select
                                name="Internal"
                                value={selectedCommunication}
                                options={[
                                  {
                                    label: "In",
                                    value: 1,
                                  },
                                  {
                                    label: "Out",
                                    value: 2,
                                  },
                                  {
                                    label: "Internal",
                                    value: 3,
                                  },
                                ]}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "communication");
                                }}
                              />
                            </div>
                          </Col> */}

                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Template
                              </Label>
                              <Select
                                name="faq_type"
                                value={selectedTemplate}
                                options={templateOptions}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "template");
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Language
                              </Label>
                              <Select
                                name="faq_type"
                                value={selectedLanguage}
                                options={[
                                  {
                                    label: "English",
                                    value: 1,
                                  },
                                  {
                                    label: "Hindi",
                                    value: 2,
                                  },
                                  {
                                    label: "Malayalam",
                                    value: 3,
                                  },
                                ]}
                                classNamePrefix="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "language");
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-10">
                              <Label htmlFor="validationCustom01">
                                Comments
                              </Label>
                              <AvField
                                name="text"
                                placeholder="Comments"
                                type="textarea"
                                errorMessage="Enter Comments"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom02"
                                value={addFollowUpData?.text}
                                onChange={handleChangePopupInput}
                              />
                            </div>
                          </Col>

                          {/* <Col md={12} className="m-2">
                          <div>
                              <label htmlFor="flag" className="mr-2">Convert Response</label>
                              <input className="m-l-2" type="checkbox" name="flag" id="" />
                            </div>
                          </Col> */}

                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Attachments
                              </Label>
                              <AvField
                                name="screenshot"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadImage}
                                rows="1"
                              />
                            </div>
                          </Col>
                          <Col style={{ marginTop: "25px" }}>
                            <Label className="ms-2 me-4">
                              Response&nbsp;
                              <AvInput
                                type="checkbox"
                                onChange={handleChangePopupInput}
                                name="flag"
                                trueValue="0"
                                falseValue="1"
                              />
                            </Label>
                            <Button color="primary" type="submit">
                              Send
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          {imageArray &&
                            imageArray?.map((image, index) => {
                              return (
                                <>
                                  <Col md="3" style={{ paddingRight: "0" }}>
                                    <div className="mb-3">
                                      <img
                                        alt=""
                                        width="150"
                                        height="150"
                                        src={`${API_URL}uploads/twitter/${image}`}
                                      />
                                      <button
                                        type="button"
                                        //className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                        className="upload_image_close"
                                        onClick={() =>
                                          deleteTwitterImage(index)
                                        }
                                      ></button>
                                    </div>
                                  </Col>
                                </>
                              );
                            })}
                        </Row>
                      </CardBody>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="popupFollowupTableId"
                    responsive
                    bordered
                    data={popupFolloupData || []}
                    searching={false}
                    info={false}
                    paging={false}
                    entries={20}
                  />
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Social Listening" />
          <Row>
            <Col xl="12">
              {/* <Modal
                show={showReplyReceipt}
                toggle={() => setShowReplyReceipt(!showReplyReceipt)}
                size="md"
                centered={true}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Reply To Mention</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setShowReplyReceipt(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <AvForm
                  className="needs-validation"
                  ref={formRef}
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}
                >
                  <div className="text-center mt-2">
                    <p>
                      Reply To{"  "}
                      {userMentionData?.name}
                    </p>
                  </div>

                  <div className="modal-body">
                    <div className="mb-3">
                      <textarea
                        name="text"
                        className="form-control"
                        placeholder="Type your reply here"
                        type="textarea"
                        errorMessage="Please Enter Your Reply"
                        validate={{ required: { value: true } }}
                        id="validationCustom04"
                        value={userMentionData?.text}
                        onChange={handlePopupInputChange}
                        style={{ borderColor: "#b6b6b68a" }}
                      />
                    </div>
                    <Row className="row mb-0">
                      <Col className="col-12 text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Reply{"  "}
                          <SendRoundedIcon />
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </Modal> */}
            </Col>
          </Row>
          <Row>
            {/* /////////////////////////////// */}
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="10">
                      <Row>
                        <Col md="3" className="mb-2">
                          <Label>From</Label>
                          <input
                            name="from_date"
                            type="date"
                            className="form-control"
                            onChange={handleFltrInput}
                            validate={{ required: { value: true } }}
                            value={filterObject?.from_date}
                          />
                        </Col>

                        <Col md="3" className="mb-2">
                          <Label>To</Label>
                          <input
                            name="to_date"
                            type="date"
                            className="form-control"
                            onChange={handleFltrInput}
                            validate={{ required: { value: true } }}
                            value={filterObject?.to_date}
                          />
                        </Col>

                        <Col md="3" className="mb-2">
                          <Label>Source</Label>
                          <Select
                            placeholder="Select"
                            // value={filterObject?.source}
                            value={selectedSource}
                            validate={{ required: { value: true } }}
                            onChange={(selected) => {
                              setSelectedSource(selected);
                              setFilterObject((preVal) => {
                                return {
                                  ...preVal,
                                  source: selected.value,
                                };
                              });
                            }}
                            options={sourceType}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="3" className="mb-2">
                          <Label>Status</Label>
                          <Select
                            placeholder="Select"
                            // value={filterObject?.source}
                            value={selectedStatus}
                            onChange={(selected) => {
                              setSelectedStatus(selected);
                              setFilterObject((preVal) => {
                                return {
                                  ...preVal,
                                  status: selected.value,
                                };
                              });
                            }}
                            options={[
                              { label: "New", value: 1 },
                              { label: "Responded", value: 2 },
                              { label: "Closed", value: 3 },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="2" style={{ marginTop: "26px" }}>
                      <div>
                        {/* <Button
                            className="mx-2"
                            color="primary"
                            type="filter"
                            onClick={() => {}}
                          >
                            Search
                          </Button> */}

                        <Button
                          className="mx-2"
                          color="danger"
                          type="reset"
                          onClick={resetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  {/* ////////////////////////////////////// */}

                  <MDBDataTable
                    key={JSON.stringify(data)}
                    id="srvSocialTableID"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    // info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(SrvSocial));

SrvSocial.propTypes = {
  error: PropTypes.any,
  categories: PropTypes.array,
};
// export default SrvSocial;
