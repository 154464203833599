import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./BlockedIP.scss";
// import { getLocalbody } from "../../../helpers/globalFunctions";
import moment from "moment";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import toastr from "toastr";

const DatatableTables = () => {
  // const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  // const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
  }, []);

  const handleTableData = (ip="") => {
    axios
      .get(`${API_URL}security/blocklist?ip=`+ip, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.time = moment(item.user_login_on_time, "HHmmss").format(
              "hh:mm a"
            );
            
            if (item.user_login_status === 0) {
              item.action = (
                <>
                  <LockOpenIcon
                    style={{ color: "red", fontSize: "18px", cursor: "pointer" }}
                    onClick={() => updateStatus(item)}
                  />
                </>
              );
            }
          });
        setDetails(result);
      });
  };
  const updateStatus = (item) => {
    axios
      .put(`${API_URL}security/blocklist?user_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("IP unblocked successfully");
          handleTableData();
        } else {
          toastr.error("Failed");
          return;
        }
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "user_login_on_date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Date & Time",
      //   field: "datetime",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "User",
        field: "user_login_username",
        sort: "asc",
        width: 270,
      },
      {
        label: "IP Address",
        field: "user_login_ip",
        sort: "asc",
        width: 200,
      },
      {
        label: "Attempts",
        field: "attempts",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  };
const handleInputChange = (e) => {
  // let ip = e.target.value;
  handleTableData(e.target.value);
}
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Blocked IP" />

          {/*Text field 1*/}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <label>IP Address</label>
                        <div className="mb-3">
                          <AvField
                            name="ip"
                            placeholder="Search here"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      {/* <Col md="3">
                        <Button color="primary" type="submit">
                          <SearchIcon></SearchIcon> {"  "}
                          Search
                        </Button>
                        {"  "}
                        <Button color="danger" type="submit">
                          <Resete></Resete> {"  "}
                          Reset
                        </Button>
                      </Col> */}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="blockedIpTableId"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
