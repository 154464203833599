import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Bar, Pie, Line } from "react-chartjs-2";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function VolumeSummary() {
  const [volumeCount, setVolumeCount] = useState();
  const [volumeCountTotal, setVolumeCountTotal] = useState();
  const [volumeCountBySocialMedia, setVolumeCountBySocialMedia] = useState();

  function fetchVolumeCount() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/volume/count?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setVolumeCount(result);
      });
  }

  function fetchVolumeCountTotal() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/volume/countTotal?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data;
        setVolumeCountTotal(result);
      });
  }

  function fetchVolumeCountBySocialMedia() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/volume/countByDate?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setVolumeCountBySocialMedia(result);
      });
  }

  useEffect(() => {
    fetchVolumeCount();
    fetchVolumeCountTotal();
    fetchVolumeCountBySocialMedia();
  }, []);

  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };

    return new Date(date).toLocaleString("en-US", options);
  };

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [showFullMonth, setShowFullMonth] = useState(false);

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth);
  };

  // bar chart1
  const options1 = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true, // Set this to false if you don't want the y-axis to always start at zero
        },
        scaleLabel: {
          display: true,
          labelString: "Volume",
        },
      },
    },
  };

  const backgroundColors = [
    "#f44336",
    "#e91e63",
    "#03a9f4",
    "#009688",
    "#052c65",
    "rgb(151,120,209)",
    "#e91e63",
    "#009688",
    "#f1b44c",
    "#50a5f1",
    "rgb(202,248,128)",
    "rgb(255,206,236)",
  ];

  const data1 = {
    labels: ["Kial"],

    datasets: volumeCount?.map((item, index) => ({
      label: item.label,
      data: item.data,
      backgroundColor: backgroundColors[index],
      stack: "Stack 0",
      barPercentage: "0.5",
    })),
  };

  // pie chart1
  const pie1 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "# of Votes",
        data: [volumeCount?.reduce((total, item) => total + item.data[0], 0)],
        backgroundColor: ["#e83e8c"],
        borderColor: ["#e83e8c"],
        borderWidth: 1,
      },
    ],
  };

  // line chart1
  const lineData1 = {
    labels: volumeCountTotal?.labels,
    datasets: [
      {
        label: "Kial",
        data: volumeCountTotal?.data,
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const lineOption1 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Start the y-axis at -500
            // max: 200,
            stepSize: 50, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Volume Summary</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>

          <Row style={{ rowGap: "20px" }}>
            {volumeCount?.map((item) => (
              <Col md="3">
                <Card>
                  <CardBody>
                    <div class="contbox">
                      <span class="mainval">{item.data[0]}</span>
                    </div>
                    <div class="hdbox">
                      <h4 class="title1">
                        <i class={"fab icon-dashboard-2"}></i> {item.label}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            {/* Bar chart1 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Brand Volume by Source</h5>
                  <Bar options={options1} data={data1} />
                </CardBody>
              </Card>
            </Col>

            {/* pie chart 1 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Share of Voice</h5>
                  <div style={{ height: "400", width: "600" }}>
                    <Pie data={pie1} />
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* Line chart1 */}
            <Col md="12">
              <Card>
                <CardBody>
                  <h5>Total Volume</h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData1}
                    options={lineOption1}
                  />
                </CardBody>
              </Card>
            </Col>

            {volumeCountBySocialMedia?.map((item) => (
              <Col md="12">
                <Card>
                  <CardBody>
                    <h5>{item.socialMedia} Volume</h5>
                    <Line
                      width={474}
                      height={100}
                      data={{
                        labels: item.labels,
                        datasets: [
                          {
                            label: "Kial",
                            data: item.data,
                            fill: false,
                            borderColor: "#742774",
                          },
                        ],
                      }}
                      options={{
                        scales: {
                          xAxes: [
                            {
                              barPercentage: 0.1,
                            },
                          ],
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true, // Start the y-axis at -500
                                // max: 80,
                                stepSize: 20, // Set the interval between y-axis ticks
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

export default VolumeSummary;
