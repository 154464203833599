import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

import { useDispatch, useSelector } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Modal from "react-bootstrap/Modal";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./addBranch.scss";
import { Stack } from "react-bootstrap";

const AddBranch = () => {
  const [branchObject, setBranchObject] = useState({});
  const [branchIdTobeUpdated, setBranchIdToBeUpdated] = useState(null);
  const [branchIdToBeDeleted, setBranchIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [branchForTable, setBranchForTable] = useState([]);
  const [branchValue, setBranchValue] = useState("");

  const [branchName, setBranchName] = useState([]);
  const [selectedbranch, setSelectedBranch] = useState(null);

  const [zoneName, setZoneName] = useState([]);
  const [selectedzone, setSelectedZone] = useState(null);

  const [stateName, setStateName] = useState([]);
  const [selectedstate, setSelectedState] = useState(null);

  const [districtName, setDistrictName] = useState([]);
  const [selecteddistrict, setSelectedDistrict] = useState(null);

  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});

  const {
    districts,
    addingBranch,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_all_Banch();
    fetch_all_zone();
    fetch_all_states();
  }, []);

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const [open, setOpen] = React.useState(false);

  const handleChangeDate = (e) => {
    setBranchObject({
      ...branchObject,
      [e.target.name]: e.target.value,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleTableData() {
    var url = `${API_URL}bom_Branch/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let departmentData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateBranch(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setBranchIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          item.date = moment(item.opening_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          // if (item?.opening_date)
          //   item.date = moment(item.opening_date).format("DD-MM-YYYY");
          // else item.date = moment(item.opening_date).format("DD-MM-YYYY");

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }
          let zone_value = item?.zone || {};
          item.zones = zone_value.zone_name;

          let district_value = item?.district || {};
          item.districts = district_value?.district_name;

          let state_value = item?.state || {};
          item.states = state_value?.name;

          // if (item?.time)
          //   item.time = moment(item.time, "HHmmss").format("hh:mm a");
          // else item.time = "";

          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          departmentData.push(item);
        });
        setBranchForTable(departmentData);
      });
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function fetch_all_Banch() {
    axios
      .get(`${API_URL}bom_Branch/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.branch_name,
              value: el._id,
            };
          });
        setBranchName([{ options: Branch_data }]);
      });
  }
  function fetch_all_zone() {
    axios
      .get(`${API_URL}zone/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Zone_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.zone_name,
              value: el._id,
            };
          });
        setZoneName([{ options: Zone_data }]);
      });
  }

  function fetch_all_states() {
    axios
      .get(`${API_URL}states/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var State_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStateName([{ options: State_data }]);
      });
  }

  const fetch_all_districts = (item) => {
    axios
      .get(`${API_URL}districts/list?state_id=` + item.value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var District_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.district_name,
              value: el._id,
            };
          });
        setDistrictName([{ options: District_data }]);
      });
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      // case "branch_name":
      //   setSelectedBranch(selected);
      //   setBranchObject({
      //     ...branchObject,
      //     branch_name: selected.label,
      //   });
      //   break;
      case "zones":
        setSelectedZone(selected);
        setBranchObject({
          ...branchObject,
          zone: selected.value,
        });
        break;
      case "states":
        setSelectedState(selected);
        fetch_all_districts(selected);
        setBranchObject({
          ...branchObject,
          state: selected.value,
        });
        break;
      case "districts":
        setSelectedDistrict(selected);
        setBranchObject({
          ...branchObject,
          district: selected.value,
        });
        break;

      default:
        break;
    }
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Opening",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "branchCode",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "branch_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 150,
      },
      {
        label: "Zone",
        field: "zones",
        sort: "asc",
        width: 150,
      },
      {
        label: "District",
        field: "districts",
        sort: "asc",
        width: 150,
      },

      {
        label: "PIN",
        field: "pin",
        sort: "asc",
        width: 150,
      },
      {
        label: "State",
        field: "states",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "MICR",
      //   field: "micr",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "IFSC",
        field: "ifsc",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: branchForTable,
  };

  let preUpdateBranch = (item) => {
    setBranchIdToBeUpdated(item._id);
    setBranchValue(item?.name);

    axios
      .get(`${API_URL}bom_Branch/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let zone = result?.zone ? result.zone : {};
        let ZoneValue = {
          label: zone?.zone_name,
          value: zone?._id,
        };

        let BranchName = {
          label: result?.branch_name,
          value: result?._id,
        };

        let state = result?.state ? result.state : {};
        let StateValue = {
          label: state?.name,
          value: state?._id,
        };

        let districts = result?.district ? result.district : {};
        let DistrictValue = {
          label: districts?.district_name,
          value: districts?._id,
        };

        setSelectedBranch(BranchName);
        setSelectedDistrict(DistrictValue);
        setSelectedState(StateValue);
        setSelectedZone(ZoneValue);
        setBranchObject(result);
      });
  };

  function handleChangeBranch(e) {
    let name = e.target.name;
    let value = e.target.value;
    setBranchValue(value);
    setBranchObject({ ...branchObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (branchIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}bom_Branch/`, branchObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Branch updated successfully");
            setBranchValue("");
            formRef.current.reset();
            handleTableData();
            setBranchObject({});
            setBranchIdToBeUpdated(null);

            setSelectedZone(null);
            setSelectedState(null);
            setSelectedDistrict(null);
          } else {
            toastr.error("Failed to update Branch");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}bom_Branch/`, branchObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Branch added Successfully");
            handleTableData();
            setBranchValue("");
            formRef.current.reset();
            setBranchObject({});

            setSelectedZone(null);
            setSelectedState(null);
            setSelectedDistrict(null);
          } else {
            toastr.error("Failed to add Branch");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}bom_Branch/data?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let zone_value = result?.zone || {};
        result.zone_value = zone_value.zone_name;

        let state_value = result?.state || {};
        result.state_value = state_value.name;

        let district_value = result?.district || {};
        result.district_value = district_value.district_name;

        result.date = moment(result.date).format("DD-MM-YYYY");

        if (result?.time)
          result.time = moment(result.time, "HHmmss").format("hh:mm a");
        else result.time = "";

        result.up_date = moment(result.up_date).format("DD-MM-YYYY");

        if (result?.up_time)
          result.up_time = moment(result.up_time, "HHmmss").format("hh:mm a");
        else result.up_time = "";

        let addedBy = result.addedby || {};
        result.addedBy = addedBy?.firstName;

        let updatedBy = result.addedby || {};
        result.updatedBy = updatedBy?.firstName;

        setPopupData(result);
        setPopupView(true);
      });
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}bom_Branch` + "/" + branchIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Branch deleted successfully");
                    if (
                      branchObject &&
                      branchObject._id === branchIdToBeDeleted
                    ) {
                      setBranchValue("");
                      formRef.current.reset();
                      setBranchObject({});
                      setBranchIdToBeUpdated(null);

                      setSelectedZone(null);
                      setSelectedState(null);
                      setSelectedDistrict(null);
                    }
                    setBranchIdToBeDeleted(null);
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Branch");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Add Branch" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Stack direction="row" spacing={2}>
                              <Label
                                htmlFor="validationCustom05"
                                style={{ marginTop: "auto" }}
                              >
                                Opening Date
                              </Label>
                              <input
                                className="form-control"
                                type="date"
                                id="income_expense_date"
                                name="opening_date"
                                value={branchObject?.opening_date}
                                onChange={handleChangeDate}
                              />
                            </Stack>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Branch Code
                            </Label>

                            <AvField
                              style={customStyles}
                              name="branchCode"
                              placeholder="Branch Code"
                              type="text"
                              errorMessage="Enter Branch Code"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.branchCode}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Branch Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="branch_name"
                              type="text"
                              errorMessage="Select Branch Name"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              placeholder="Branch Code"
                              value={branchObject?.branch_name}
                              onChange={handleChangeBranch}
                              className="form-control"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">City</Label>

                            <AvField
                              style={customStyles}
                              name="city"
                              placeholder="City"
                              type="text"
                              errorMessage="Enter City"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.city}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Zone</Label>
                            <Select
                              name="zones"
                              errorMessage="Select Zone"
                              value={selectedzone}
                              onChange={(selected) => {
                                handleSelectChange(selected, "zones");
                              }}
                              options={zoneName}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">State</Label>
                            <Select
                              name="states"
                              errorMessage="Select state"
                              value={selectedstate}
                              onChange={(selected) => {
                                handleSelectChange(selected, "states");
                              }}
                              options={stateName}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              State Code
                            </Label>

                            <AvField
                              style={customStyles}
                              name="state_code"
                              placeholder="State Code"
                              type="number"
                              errorMessage="Enter State Code"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.state_code}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">District</Label>
                            <Select
                              name="districts"
                              errorMessage="Select District"
                              value={selecteddistrict}
                              onChange={(selected) => {
                                handleSelectChange(selected, "districts");
                              }}
                              options={districtName}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              District Code
                            </Label>

                            <AvField
                              style={customStyles}
                              name="district_code"
                              placeholder="District Code"
                              type="number"
                              errorMessage="Enter District Code"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.district_code}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Taluk</Label>

                            <AvField
                              style={customStyles}
                              name="Taluka"
                              placeholder="Taluk"
                              type="text"
                              errorMessage="Enter Taluk"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.Taluka}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Pin</Label>

                            <AvField
                              style={customStyles}
                              name="pin"
                              placeholder="Pin"
                              type="number"
                              errorMessage="Enter Pin"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.pin}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">MICR</Label>

                            <AvField
                              style={customStyles}
                              name="micr"
                              placeholder="MICR"
                              type="number"
                              errorMessage="Enter MICR"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.micr}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">IFSC</Label>

                            <AvField
                              style={customStyles}
                              name="ifsc"
                              placeholder="IFSC"
                              //type="number"
                              errorMessage="Enter IFSC"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.ifsc}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              RBI Region
                            </Label>

                            <AvField
                              style={customStyles}
                              name="RBI_region"
                              placeholder="RBI Region"
                              type="text"
                              errorMessage="Enter RBI Region"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.RBI_region}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              AD Category Region
                            </Label>

                            <AvField
                              style={customStyles}
                              name="AD_categ_region"
                              placeholder="AD Category Region"
                              type="text"
                              errorMessage="Enter AD Category Region"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.AD_categ_region}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              AD Category
                            </Label>

                            <AvField
                              style={customStyles}
                              name="AD_categ"
                              placeholder="AD Category"
                              type="text"
                              errorMessage="Enter AD Category"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.AD_categ}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Branch Email
                            </Label>

                            <AvField
                              style={customStyles}
                              name="branch_email"
                              placeholder="Branch Email"
                              type="email"
                              errorMessage="Enter Branch Email"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.branch_email}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Manage Email
                            </Label>

                            <AvField
                              style={customStyles}
                              name="manage_email"
                              placeholder="Manage Email"
                              type="email"
                              errorMessage="Enter Manage Email"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.manage_email}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              CISBI Category
                            </Label>

                            <AvField
                              style={customStyles}
                              name="CISBI_categ"
                              placeholder="CISBI Category"
                              type="text"
                              errorMessage="Enter CISBI Category"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={branchObject?.CISBI_categ}
                              onChange={handleChangeBranch}
                            />
                          </div>
                        </Col>

                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {branchIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingBranch ? true : false}
                              >
                                {addingBranch ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingBranch ? true : false}
                              >
                                {addingBranch ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="addBranchTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
      {/* Modal Form Start */}
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Branch Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Branch Name :
                  </td>
                  <td>{popupData?.branch_name}</td>
                  <td>Branch Code :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.branchCode}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Opening Date :
                  </td>
                  <td>{popupData?.opening_date}</td>
                  <td>City :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.city}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>Pin :</td>
                  <td>{popupData?.pin}</td>
                  <td>Taluka :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.Taluka}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>Zone :</td>
                  <td>{popupData?.zone_value}</td>
                  <td>State Code :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.state_code}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>MICR :</td>
                  <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                    {popupData?.micr}
                  </td>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    RBI Region :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.RBI_region}</td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    AD Category Region :
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.AD_categ_region}
                  </td>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    AD Category :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.AD_categ}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Branch Email :
                  </td>
                  <td>{popupData?.branch_email}</td>
                  <td>Manage Email :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.manage_email}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    CISBI Category :
                  </td>
                  <td>{popupData?.CISBI_categ}</td>
                  <td>IFSC :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.ifsc}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    State :
                  </td>
                  <td>{popupData?.state_value}</td>
                  <td>District :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.district_value}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>Date :</td>
                  <td>{popupData?.date}</td>
                  <td>Time :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.time}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Updated Date :
                  </td>
                  <td>{popupData?.up_date}</td>
                  <td>Updated Time :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.up_time}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Added By :
                  </td>
                  <td>{popupData?.addedBy}</td>
                  <td>Updated By :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.updatedBy}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </AvForm>
      </Modal>
      {/* Modal Form End */}
    </>
  );
};

export default AddBranch;
