import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import "./sentimentSummary.scss";

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function SentimentSummary() {
  const [sentimentCount, setSentimentCount] = useState();
  const [sentimentCountGrouped, setSentimentCountGrouped] = useState();
  const [sentimentCountPercentageGrouped, setSentimentCountPercentageGrouped] =
    useState();

  function fetchSentimentCount() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/sentiment/count?fromDate=${fromDate}&toDate=${ToDate}`;

    axios
      .get(url, { headers: { "x-access-token": accessToken } })
      .then((res) => {
        let result = res?.data?.data;
        setSentimentCount(result);
      });
  }

  function fetchSentimentCountGrouped() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/sentiment/countGrouped?fromDate=${fromDate}&toDate=${ToDate}`;

    axios
      .get(url, { headers: { "x-access-token": accessToken } })
      .then((res) => {
        let result = res?.data?.data;
        setSentimentCountGrouped(result);
      });
  }

  function fetchSentimentCountPercentageGrouped() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/sentiment/countPercentageGrouped?fromDate=${fromDate}&toDate=${ToDate}`;

    axios
      .get(url, { headers: { "x-access-token": accessToken } })
      .then((res) => {
        let result = res?.data;
        setSentimentCountPercentageGrouped(result);
      });
  }

  useEffect(() => {
    fetchSentimentCount();
    fetchSentimentCountGrouped();
    fetchSentimentCountPercentageGrouped();
  }, []);

  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };

    return new Date(date).toLocaleString("en-US", options);
  };

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [showFullMonth, setShowFullMonth] = useState(false);

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth);
  };

  // bar chart1
  const series = sentimentCount || "";

  const option1 = {
    chart: {
      type: "bar",
      height: 600,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: ["Kial"],
    },
    colors: ["#98d8a9", "#ff928b", "#ffdf97"],
  };

  // bar chart2
  const series2 = sentimentCountGrouped?.series || "";

  const option2 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#98d8a9", "#ff928b", "#ffdf97"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: sentimentCountGrouped?.categories,
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Sentiment Report</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>

          <Row style={{ rowGap: "20px" }}>
            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">
                      {sentimentCount?.[0]?.data[0] +
                        sentimentCount?.[1]?.data[0] +
                        sentimentCount?.[2]?.data[0]}
                    </span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">Total</h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{sentimentCount?.[0]?.data[0]}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">Positive</h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{sentimentCount?.[1]?.data[0]}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">Negative</h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{sentimentCount?.[2]?.data[0]}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">Neutral</h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* bar 1*/}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Sentiment Breakup</h5>
                  {/* <div style={{ height: "400", width: "600" }}> */}
                  <ReactApexChart
                    height="260"
                    options={option1}
                    series={series}
                    type="bar"
                    className="apex-charts"
                  />
                  {/* </div> */}
                </CardBody>
              </Card>
            </Col>

            {/* bar 2*/}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Sentiment Breakup Platform Wise</h5>
                  <ReactApexChart
                    options={option2}
                    series={series2}
                    type="bar"
                    className="apex-charts"
                    height="260"
                  />
                </CardBody>
              </Card>
            </Col>

            {/* pie chart1*/}

            {sentimentCountPercentageGrouped?.data?.map((item, index) => {
              return (
                <Col md="4">
                  <Card>
                    <CardBody>
                      <h5>{item.socialMedia}</h5>

                      <ReactApexChart
                        type="pie"
                        height="200"
                        className="apex-charts"
                        options={{
                          labels: item.labels,
                          colors: ["#98d8a9", "#ff928b", "#ffdf97"],
                          legend: {
                            show: !0,
                            position: "right",
                            horizontalAlign: "center",
                            verticalAlign: "middle",
                            floating: !1,
                            fontSize: "14px",
                            offsetX: 0,
                          },
                          responsive: [
                            {
                              breakpoint: 600,
                              options: {
                                chart: {
                                  height: 240,
                                },
                                legend: {
                                  show: !1,
                                },
                              },
                            },
                          ],
                        }}
                        series={item.data}
                      />
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SentimentSummary;
