import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
} from './actionTypes';

export const getAllNotifications = () => ({
  type: GET_NOTIFICATIONS
});

export const getAllNotificationsSuccess = (notification) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notification,
});

export const getAllNotificationsFail = (error) => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
});