import React, { createContext, useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import playstore from "../../../assets/fotos/Group.svg";
import google from "../../../assets/fotos/Group 8.svg";
import apple from "../../../assets/fotos/_Group_3.svg";
import business from "../../../assets/fotos/Group 31.svg";
import bing from "../../../assets/fotos/Group 35.svg";
import comment from "../../../assets/fotos/Group 36.svg";
import star from "../../../assets/fotos/Group 37.svg";

import { Button } from "reactstrap";

import stars from "../../../assets/fotos/Star 1.svg";
import calender from "../../../assets/fotos/Group 44.svg";
import srv1 from "../../../assets/fotos/Rectangle 7.svg";
import srv2 from "../../../assets/fotos/Rectangle 8.svg";
import srv3 from "../../../assets/fotos/Rectangle 9.svg";

import Dropdown from "react-bootstrap/Dropdown";

import Reviewbar from "./Reviewbar";

import "./Reviews.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
// const ReviewsContext = createContext(null);

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function Reviews() {
  useEffect(() => {
    handleTableData();
  }, []);
  const [open, setOpen] = useState(1);

  // const [main, setMain] = useState(1)
  const [reviews, setReviews] = useState([]);

  const reviewsPerPage = 5; // Adjust as needed

  const [currentPage, setCurrentPage] = useState(2);
  // const [itemsPerPage, setItemsPerPage] = useState(5);

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = reviews?.reviews?.slice(indexOfFirstItem, indexOfLastItem);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function openClick(num) {
    setOpen(num);
  }

  function handleTableData() {
    var url = `${API_URL}google/reviews`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        setReviews(result);

        console.log(result);
      });
  }

  const totalReviews = reviews?.reviews?.length || 0;
  const totalPages = Math.ceil(totalReviews / reviewsPerPage);

  const currentReviews = reviews?.reviews?.slice(
    (currentPage - 1) * reviewsPerPage,
    currentPage * reviewsPerPage
  );

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  const handlePaginationChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // setMain(1); // Depending on your logic
  };
  return (
    <>
      <div>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Reviews" />
            <div className="reviews">
              <ul className="platforms">
                <li
                  onClick={() => openClick(1)}
                  className={`${open === 1 ? "active" : ""}`}
                >
                  <div>
                    <span className="fa fa-globe"></span>
                    <span className="mainfont">All</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" />{" "}
                        <span>{reviews.totalReviewCount}</span>
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" />{" "}
                        <span> {reviews.averageRating}</span>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => openClick(2)}
                  className={`${open === 2 ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={google} alt="" />
                    </span>
                    <span className="mainfont">Google Reviews</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" />{" "}
                        <span>{reviews.totalReviewCount}</span>
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" />{" "}
                        <span> {reviews.averageRating}</span>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => openClick(3)}
                  className={`${open === 3 ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={playstore} alt="" />
                    </span>
                    <span className="mainfont">Play Store</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" /> 371
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" /> 4.9
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => openClick(4)}
                  className={`${open === 4 ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={apple} alt="" />
                    </span>
                    <span className="mainfont">App Store</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" /> 371
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" /> 4.9
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => openClick(5)}
                  className={`${open === 5 ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={business} alt="" />
                    </span>
                    <span className="mainfont">Google Business</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" /> 371
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" /> 4.9
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => openClick(6)}
                  className={`${open === 6 ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={bing} alt="" />
                    </span>
                    <span className="mainfont">Bing</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" /> 371
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" /> 4.9
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

              <div className="reviewbar ">
                <Dropdown className="dropdown0">
                  <Dropdown.Toggle
                    className="Dropdown1"
                    style={{ backgroundColor: "white", color: "#1a71b5" }}
                    variant="info"
                    id="dropdown-basic"
                  >
                    <span class="fa fa-angle-down"></span> Recent First
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <div class="input-group" style={{ width: "auto" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search For Reviews."
                  />
                  <Button
                    color="info"
                    type="submit"
                    style={{ backgroundColor: "#1a71b5" }}
                  >
                    Search
                  </Button>
                </div>

                {/* <div className='revnextpage' >

                                    <div class="pagination">
                                        <Link to="">&laquo;</Link>
                                        <Link to="" href="#">1</Link>
                                        <Link to="" class="nextpage2" href="#">2</Link>
                                        <Link to="" href="#" className='nextcontinue'>....</Link>
                                        <Link to="" href="#">20</Link>
                                        <Link to="" href="#">&raquo;</Link>
                                    </div>


                                </div>



                                <div className='revpagecontent'>
                                    page <div>0</div> of  20
                                </div> */}

                <div className="revnextpage">
                  <div className="pagination">
                    <li
                      to="#"
                      onClick={() =>
                        handlePaginationChange(Math.max(currentPage - 1, 1))
                      }
                    >
                      &laquo;
                    </li>
                    {getPageNumbers().map((pageNumber) => (
                      <li
                        to="#"
                        key={pageNumber}
                        onClick={() => handlePaginationChange(pageNumber)}
                        className={`pagination-link ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                      >
                        {pageNumber}
                      </li>
                    ))}
                    <li
                      to="#"
                      onClick={() =>
                        handlePaginationChange(
                          Math.min(currentPage + 1, totalPages)
                        )
                      }
                    >
                      &raquo;
                    </li>
                  </div>
                </div>

                <div className="revpagecontent">
                  Page <div>{currentPage}</div> of {totalPages}
                </div>

                <div className="revpagelist">
                  <div>Show items</div>

                  <Dropdown className="">
                    <Dropdown.Toggle
                      className="Dropdown1"
                      style={{ backgroundColor: "white", color: "#1a71b5" }}
                      variant="info"
                      id="dropdown-basic"
                    >
                      <span class="fa fa-angle-down"></span> 0
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            {/*  */}

            {open == 1 ? (
              <div className="reviewbox">
                {reviews?.reviews &&
                  reviews?.reviews?.map((el) => {
                    console.log(el, "elllll");

                    const starsArray = Array.from(
                      { length: el.rating },
                      (_, index) => <img key={index} src={stars} alt="" />
                    );
                    return (
                      <div className="revlist ">
                        <div className="revhead">
                          <div className="captions">
                            <div className="profile">
                              <span class="fab ">
                                {el.reviewerName[0].toUpperCase()}
                              </span>
                              <i>
                                <img src={google} alt="" />
                              </i>
                            </div>

                            <div className="revname">
                              <div className="revtitle">{el.reviewerName}</div>
                              <div>{starsArray}</div>
                            </div>
                          </div>

                          <div className="date">
                            <span class="fa ">
                              {" "}
                              <img src={calender} alt="" />{" "}
                            </span>
                            {moment(el.createdAt).format("hh:mm A, MMM DD")}

                            <div style={{ marginTop: "10px" }}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="butn1 dropdown1"
                                  style={{
                                    backgroundColor: "white",
                                    color: "#1a71b5",
                                  }}
                                  variant="info"
                                  id="dropdown-basic"
                                >
                                  Positive
                                  <span class="fa fa-angle-down"></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-1">
                                    Neutral
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Positive
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    Negative
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className="revcontent">
                          <div>{el.content}</div>

                          {/* <div className="revimage">
                            <img className="revimages" src={srv1} alt="" />
                            <img className="revimages" src={srv2} alt="" />
                            <img className="revimages" src={srv3} alt="" />
                          </div> */}
                        </div>

                        <div className="revfooter">
                          <div className="revtool">
                            <div>
                              {" "}
                              <span className="far fa-thumbs-up"></span> Like
                            </div>
                            <Link to="/reviews/replyreview">
                              <span className="fas fa-reply"></span> Reply
                            </Link>
                            {/* <div>
                              <span className="far fa-edit"></span> Edit
                            </div> */}
                          </div>

                          <div className="revtool">
                            <div>
                              <span className="fas fa-plus-circle"></span> Lead
                            </div>
                            <div>
                              <span className="fas fa-plus-circle"></span>{" "}
                              Response
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : null}

            {open == 2 ? (
              <div className="reviewbox">
                {reviews?.reviews &&
                  reviews?.reviews?.map((el) => {
                    console.log(el, "elllll");
                    // console.log(currentReviews, "currentpafea");

                    const starsArray = Array.from(
                      { length: el.rating },
                      (_, index) => <img key={index} src={stars} alt="" />
                    );
                    return (
                      <div className="revlist ">
                        <div className="revhead">
                          <div className="captions">
                            <div className="profile">
                              <span class="fab ">
                                {el.reviewerName[0].toUpperCase()}
                              </span>
                              <i>
                                <img src={google} alt="" />
                              </i>
                            </div>

                            <div className="revname">
                              <div className="revtitle">{el.reviewerName}</div>
                              {/* <span>20 reviews 3 photos</span> */}
                              <div>{starsArray}</div>
                            </div>
                          </div>

                          <div className="date">
                            <span class="fa ">
                              {" "}
                              <img src={calender} alt="" />{" "}
                            </span>
                            {moment(el.createdAt).format("hh:mm A, MMM DD")}

                            <div style={{ marginTop: "10px" }}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="butn1 dropdown1"
                                  style={{
                                    backgroundColor: "white",
                                    color: "#1a71b5",
                                  }}
                                  variant="info"
                                  id="dropdown-basic"
                                >
                                  Positive
                                  <span class="fa fa-angle-down"></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-1">
                                    Neutral
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Positive
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    Negative
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className="revcontent">
                          <div>{el.content}</div>

                          {/* <div className="revimage">
                            <img className="revimages" src={srv1} alt="" />
                            <img className="revimages" src={srv2} alt="" />
                            <img className="revimages" src={srv3} alt="" />
                          </div> */}
                        </div>

                        <div className="revfooter">
                          <div className="revtool">
                            <div>
                              {" "}
                              <span className="far fa-thumbs-up"></span> Like
                            </div>
                            <Link to="/reviews/replyreview">
                              <span className="fas fa-reply"></span> Reply
                            </Link>
                            {/* <div>
                              <span className="far fa-edit"></span> Edit
                            </div> */}
                          </div>

                          <div className="revtool">
                            <div>
                              <span className="fas fa-plus-circle"></span> Lead
                            </div>
                            <div>
                              <span className="fas fa-plus-circle"></span>{" "}
                              Response
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <div className='revlist'>
                           <div className='revhead'>
                               <div className='captions'>
                                   <div className='profile'>
                                       <span class="fab ">V</span>
                                       <i ><img src={google} alt="" /></i>
                                   </div>

                                   <div className='revname'>

                                       <div className='revtitle'>Virat Kohli</div>
                                       <span>20 reviews  3 photos</span>
                                       <div><img src={stars} alt="" /></div>
                                   </div>

                               </div>

                               <div className='date'>
                                   <span class="fa "> <img src={calender} alt="" /></span>
                                   30/04/2020, 05:56

                                   <div style={{ marginTop: "10px" }}>
                                       <Dropdown  >
                                           <Dropdown.Toggle className='butn1 dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                               Positive
                                               <span class="fa fa-angle-down"></span>
                                           </Dropdown.Toggle>

                                           <Dropdown.Menu>
                                               <Dropdown.Item href="#/action-1">Neutral</Dropdown.Item>
                                               <Dropdown.Item href="#/action-2">Positive</Dropdown.Item>
                                               <Dropdown.Item href="#/action-3">Negative</Dropdown.Item>
                                           </Dropdown.Menu>
                                       </Dropdown>

                                   </div>

                               </div>



                           </div>
                           <div className='revcontent'>

                               <div>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique minima dolores tempora culpa facere officiis impedit quibusdam necessitatibus perspiciatis eveniet quasi alias totam quam odio recusandae, nam esse sint nihil. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem illum, vitae, repudiandae odio fugit aliquid aperiam quisquam fugiat officia ea fuga. Magni reprehenderit maiores ut voluptatem perspiciatis veritatis blanditiis. Sapiente.</div>

                               <div className='revimage'>
                                   <img className='revimages' src={srv1} alt="" />
                                   <img className='revimages' src={srv2} alt="" />
                                   <img className='revimages' src={srv3} alt="" />

                               </div>
                           </div>

                           <div className='revfooter'>
                               <div className='revtool'>

                                   <div> <span className='far fa-thumbs-up'></span> Like</div>
                                   <Link to="/reviews/replyreview"><span className='fas fa-reply'></span> Reply</Link>
                                   <div><span className='far fa-edit'></span> Edit</div>

                               </div>

                               <div className='revtool'>
                                   <div><span className='fas fa-plus-circle'></span> Lead</div>
                                   <div><span className='fas fa-plus-circle'></span> Response</div>
                               </div>
                           </div>
                       </div> */}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
// export { ReviewsContext }
export default Reviews;
