import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_NOTIFICATIONS,

} from './actionTypes';

import {
  getAllNotificationsSuccess,
  getAllNotificationsFail,
} from './actions';

//Include Both Helper File with needed methods
import {
  getAllNotifications,
} from '../../helpers/backend_helper';

function* fetchNotification({localbody_id: localbody_id}) {
  try {
    const response = yield call(getAllNotifications,localbody_id);
    yield put(getAllNotificationsSuccess(response));
  } catch (error) {
    yield put(getAllNotificationsFail(error));
  }
}

function* notificationSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotification);
}

export default notificationSaga;
