import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { useHistory } from "react-router-dom";
//Import Image

const Dashboard = () => {
  const history = useHistory();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [master, setMaster] = useState({
    total_count: 0,
    new: 0,
    pending_count: 0,
    closed_count: 0,
    responded: 0,
    cust_info: 0,
    pending_more: 0,
    dept_change: 0,
  });
  // const [series, setSeries] = useState([]);
  const [graphData, setGraphData] = useState({
    series: [],
    categories: [],
  });
  const year = moment().format("YYYY");

  function fetchData() {
    axios
      .get(`${API_URL}dashboard/count`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setMaster(res.data[0]);
        }
      });
  }

  function fetchGraph() {
    axios
      .get(`${API_URL}dashboard/graph`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        // setSeries(res.data);
        setGraphData(res?.data);
      });
  }

  useEffect(() => {
    fetchData();
    fetchGraph();
  }, []);

  const series = graphData?.series;

  const options = {
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    // series: series,
    colors: ["#5b73e8", "#f1b44c", "#98d8a9"],
    xaxis: {
      type: "yearmonth",
      // categories: [year + "-01", year + "-02", year + "-03", year + "-04", year + "-05", year + "-06", year + "-07", year + "-08", year + "-09", year + "-10", year + "-11", year + "-12"],
      categories: graphData?.categories,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "yyyy-mm",
      },
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kial Social" breadcrumbItem="Dashboard" />
          <Row>
            <Col
              md={6}
              xl={2}
              key={1}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/all_response");
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i className={"fas fa-envelope icon-dashboard-1"}></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.total_count}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"TOTAL"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={2}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/all_response?status=new");
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i
                          className={"fas fa-comment-dots icon-dashboard-1"}
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.new}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"NEW"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={2}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/all_response?status=pending");
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i
                          className={
                            "fas fa-exclamation-triangle icon-dashboard-1"
                          }
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.pending_count}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"PENDING"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={2}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/all_response?status=responded");
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i className={"fas fa-reply-all icon-dashboard-1"}></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.responded}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">RESPONDED</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={2}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/all_response?status=closed");
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i className={"fas fa-check icon-dashboard-1"}></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.closed_count}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">CLOSED</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={2}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/all_response?status=dept-changed");
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i
                          className={"fas fa-share-square icon-dashboard-1"}
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.dept_change}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">DEPT. CHANGED</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div class="row">
            <div class="col-12 col">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Social Listening</h4>
              </div>
            </div>
          </div>
          <Row>
            <Col xl={12}>
              <Card>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height="350"
                  className="apex-charts"
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
