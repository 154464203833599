import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Bar } from "react-chartjs-2";





function BreakUpByMedia() {

    // date
    const formatDate = (date, fullMonth = false) => {
        const options = fullMonth
            ? { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
            : { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        return new Date(date).toLocaleString('en-US', options);
    };


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showFullMonth, setShowFullMonth] = useState(false);

    const toggleShowFullMonth = () => {
        setShowFullMonth(!showFullMonth);
    };

    const option1 = {
        plugins: {
            title: {
                display: true,
                text: 'Chart.js Bar Chart - Stacked',
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    beginAtZero: true, // Set this to false if you don't want the y-axis to always start at zero
                },
            },
        },
    };

    const data1 = {
        labels: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
        datasets: [
            {
                label: 'Twitter',
                data: [72, 75, 81, 62, 67, 50],
                backgroundColor: '#f44336',
                stack: 'Stack 0',
                barPercentage: 0.2
            },
            {
                label: 'Facebook',
                data: [28, 25, 19, 23, 12, 15],
                backgroundColor: '#9c27b0',
                stack: 'Stack 0',
                barPercentage: 0.2
            },
            {
                label: 'Youtube',
                data: [0, 0, 0, 10, 11, 14],
                backgroundColor: '#009688',
                stack: 'Stack 0',
                barPercentage: 0.2
            },
            {
                label: 'Instagram',
                data: [0, 0, 0, 0, 6, 14],
                backgroundColor: '#8bc34a',
                stack: 'Stack 0',
                barPercentage: 0.2
            },
            {
                label: 'LinkedIn',
                data: [0, 0, 0, 6, 3, 7],
                backgroundColor: '#ff9800',
                stack: 'Stack 0',
                barPercentage: 0.2
            },
            {
                label: 'News',
                data: [0, 0, 0, 0, 0, 0],
                backgroundColor: '#e91e63',
                stack: 'Stack 0',
                barPercentage: 0.2
            },
            {
                label: 'Blogs',
                backgroundColor: '#052c65',
                stack: 'Stack 0',
            },
            {
                label: 'FB Others',
                backgroundColor: '#50a5f1',
                stack: 'Stack 0'
            },
            {
                label: 'Pinterest',
                backgroundColor: '#f1b44c',
                stack: 'Stack 0'
            },
        ],
    };


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">

                    <div>
                        <p className="titletit">Monthly Breakup By Media Report</p>
                        <h6 className="dateText" onClick={toggleShowFullMonth}>
                            {formatDate(startDate, showFullMonth)} - {formatDate(endDate, showFullMonth)}

                        </h6>
                    </div>

                    <Row>

                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Breakup By Media(Current selected brand only)</h5>
                                    <Bar width={472} height={200} options={option1} data={data1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )

}

export default BreakUpByMedia;