import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
// import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
// import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
// import "./Social_Config.scss";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { get, post } from "../../helpers/api_helper";
import moment from "moment";

const SocialConfiguration = (props) => {
  const [reload, setReload] = useState(false);
  const [tableData, setTableData] = useState({});
  const [isActive, setIsActive] = useState(false);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    get("token/fb-valid").then((res) => {
      setIsActive(res.status);
      setTableData(res.data);
    });
  }, [reload]);

  const regenerateToken = () => {
    post("token/fb-regenerate").then((res) => {
      console.log(res);
      if (res.status) {
        toastr.success(res.message);
        setReload(!reload);
      }
    });
  };

  console.log(tableData);

  const facbookData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Added By",
        field: "name",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
    ],
    rows: [
      {
        id: 1,
        date: moment(tableData?.facebook?.date).format("DD-MM-YYYY"),
        time: tableData?.facebook?.time,
        name:
          tableData?.facebook?.addedby?.firstName +
          " " +
          tableData?.facebook?.addedby?.lastName,
        status: (
          <span
            style={{ cursor: "pointer" }}
            class={`badge font-size-12 ms-2 ${
              isActive ? "bg-success" : "bg-danger"
            }`}
            onClick={() => {
              !isActive && regenerateToken();
            }}
          >
            {isActive ? "Active" : "Regenerate"}
          </span>
        ),
      },
    ],
  };
  const instaData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Added By",
        field: "name",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
    ],
    rows: [
      {
        id: 1,
        date: moment(tableData?.instagram?.date).format("DD-MM-YYYY"),
        time: tableData?.instagram?.time,
        name:
          tableData?.instagram?.addedby?.firstName +
          " " +
          tableData?.instagram?.addedby?.lastName,
        status: (
          <span
            style={{ cursor: "pointer" }}
            class={`badge font-size-12 ms-2 ${
              isActive ? "bg-success" : "bg-danger"
            }`}
            onClick={() => {
              !isActive && regenerateToken();
            }}
          >
            {isActive ? "Active" : "Regenerate"}
          </span>
        ),
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Social Configuration" />
          {/* Facebook screen */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {/* <AvForm
                    ref={formFacebookRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                    }}
                  > */}
                  <Row>
                    <b style={{ padding: "5px" }}>
                      <i
                        class="uil-facebook"
                        style={{ padding: "5px", fontSize: "16px" }}
                      ></i>
                      Facebook Settings
                    </b>
                    {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">App ID</Label>

                          <AvField
                            name="appId"
                            value={facebookObject?.appId}
                            placeholder="App ID"
                            type="text"
                            errorMessage="Enter App ID"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">App Secret</Label>

                          <AvField
                            name="appSecret"
                            value={facebookObject?.appSecret}
                            placeholder="App Secret"
                            type="text"
                            errorMessage="Enter App Secret"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Token</Label>

                          <AvField
                            name="token"
                            value={facebookObject?.token}
                            placeholder="Token"
                            type="text"
                            errorMessage="Enter Token"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {facebookObject?._id ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col> */}
                  </Row>
                  {/* </AvForm> */}
                  <MDBDataTable
                    id="fbConfigTb"
                    responsive
                    bordered
                    paging={false}
                    data={facbookData}
                    searching={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={20}
                    displayEntries={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Instagram Settings */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <b style={{ padding: "5px" }}>
                      <i
                        class="uil-instagram"
                        style={{ padding: "5px", fontSize: "16px" }}
                      ></i>
                      Instagram Settings
                    </b>
                  </Row>
                  <MDBDataTable
                    id="fbConfigTb"
                    responsive
                    bordered
                    paging={false}
                    data={instaData}
                    searching={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={20}
                    displayEntries={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Google Analytics */}
          {/* <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formGoogleAnalyticsRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmitGoogleAnalyticsConfig();
                    }}
                  >
                    <Row>

                      <b style={{ padding: "5px" }}>
                        <i
                          class="uil-google"
                          style={{ padding: "5px", fontSize: "16px" }}
                        ></i>
                        Google Analytics
                      </b>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Client ID</Label>

                          <AvField
                            name="clientId"
                            value={googleAnalyticsObject?.clientId}
                            placeholder="Client ID"
                            type="text"
                            errorMessage="Enter Client ID"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom01"
                            onChange={handleGoogleAnalyticsInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">View ID</Label>

                          <AvField
                            name="viewId"
                            value={googleAnalyticsObject?.viewId}
                            placeholder="View ID"
                            type="text"
                            errorMessage="Enter View ID"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleGoogleAnalyticsInput}
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {googleAnalyticsObject?._id ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="fbConfigTb"
                    responsive
                    bordered
                    data={googleData}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SocialConfiguration;
