import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Line, Bar } from "react-chartjs-2";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
function FollowersSummary() {
  const [fetchLike, setFetchLike] = useState();
  const [fetchFbFanPage, setFetchFbFanPage] = useState();
  const [instagramFollowerCount, setInstagramFollowerCount] = useState();
  const [instagramFollowerCountIncrement, setInstagramFollowerCountIncrement] =
    useState();
  const [twitterFollowerCount, setTwitterFollowerCount] = useState();
  const [twitterFollowerCountIncrement, setTwitterFollowerCountIncrement] =
    useState();

  function fetchLikeCountData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/facebook/page/likes?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setFetchLike(result);
      });
  }

  function fetchFbPageData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/facebook/page/likesIncrement?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setFetchFbFanPage(result);
      });
  }

  function fetchInstagramFollowersData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/instagram/account/followers?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setInstagramFollowerCount(result);
      });
  }

  function fetchInstagramFollowersIncrementData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/instagram/account/followersIncrement?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setInstagramFollowerCountIncrement(result);
      });
  }

  function fetchTwitterFollowersData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/twitter/account/followers?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setTwitterFollowerCount(result);
      });
  }

  function fetchTwitterFollowersIncrementData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/twitter/account/followersIncrement?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setTwitterFollowerCountIncrement(result);
      });
  }

  useEffect(() => {
    fetchLikeCountData();
    fetchFbPageData();
    fetchInstagramFollowersData();
    fetchInstagramFollowersIncrementData();
    fetchTwitterFollowersData();
    fetchTwitterFollowersIncrementData();
  }, []);

  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };

    return new Date(date).toLocaleString("en-US", options);
  };

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [showFullMonth, setShowFullMonth] = useState(false);

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth);
  };

  // bar chart1
  const data1 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Likes Increment",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [fetchFbFanPage?.increment],
      },
    ],
  };

  const option1 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: -420, // Set the minimum value for the y-axis
            // max: -370,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Likes Increment",
          },
        },
      ],
    },
  };

  // bar chart2
  const barData = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Likes Change%",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [fetchFbFanPage?.incrementPercentage],
      },
    ],
  };

  const barOption = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: -10, // Set the minimum value for the y-axis
            // max: 50,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Likes Change%",
          },
        },
      ],
    },
  };

  // Line chart
  const lineData = {
    labels: fetchLike?.labels,
    datasets: [
      {
        label: "Kial",
        data: fetchLike?.data,
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const lineOption1 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 3340300, // Set the minimum value for the y-axis
            // max: 3340800,
            stepSize: 100, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  };

  // bar chart5
  const data5 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Followers Increment",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [twitterFollowerCountIncrement?.increment],
      },
    ],
  };

  const option5 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 20, // Set the minimum value for the y-axis
            // max: 80,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Followers Increment",
          },
        },
      ],
    },
  };

  // bar chart6
  const data6 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Followers Increment%",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [twitterFollowerCountIncrement?.incrementPercentage],
      },
    ],
  };

  const option6 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Start the y-axis at 0
            // max: 60,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Followers Increment%",
          },
        },
      ],
    },
  };

  // Line chart3
  const lineData3 = {
    labels: twitterFollowerCount?.labels,
    datasets: [
      {
        label: "Kial",
        data: twitterFollowerCount?.data,
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const lineOption3 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 119350, // Set the minimum value for the y-axis
            // max: 119410,
            stepSize: 10, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  };

  // bar chart7
  const data7 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Followers Increment",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [instagramFollowerCountIncrement?.increment],
      },
    ],
  };

  const option7 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 20, // Set the minimum value for the y-axis
            // max: 80,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Followers Increment",
          },
        },
      ],
    },
  };

  // bar chart8
  const data8 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Followers Increment%",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [instagramFollowerCountIncrement?.incrementPercentage],
      },
    ],
  };

  const option8 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Start the y-axis at 0
            // max: 60,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Followers Increment%",
          },
        },
      ],
    },
  };

  // Line chart4
  const lineData4 = {
    labels: instagramFollowerCount?.labels,
    datasets: [
      {
        label: "Kial",
        data: instagramFollowerCount?.data,
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const lineOption4 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 143800, // Set the minimum value for the y-axis
            // max: 144600,
            stepSize: 200, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Fans Report</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>

          <Row style={{ rowGap: "20px" }}>
            {/* bar chart1 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>FB Fan Page Likes Increment</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data1}
                    options={option1}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart2 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>FB Fan Page Likes Increment Percent</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={barData}
                    options={barOption}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* line chart1 */}
            <Col md="12">
              <Card>
                <CardBody>
                  <h5>Likes Count Daily Trend</h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData}
                    options={lineOption1}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart5 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Twitter Followers Increment</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data5}
                    options={option5}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart6 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Twitter Followers Increment Percent</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data6}
                    options={option6}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* line chart3 */}
            <Col md="12">
              <Card>
                <CardBody>
                  <h5>Followers Count Daily Trend</h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData3}
                    options={lineOption3}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart7 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Instagram Followers Increment</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data7}
                    options={option7}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart8 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Instagram Followers Increment Percent</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data8}
                    options={option8}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* line chart4 */}
            <Col md="12">
              <Card>
                <CardBody>
                  <h5>Instagram Followers Daily </h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData4}
                    options={lineOption4}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FollowersSummary;
